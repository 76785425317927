<template>
  <div>
    <Header />
    <v-container style="max-width: 100%">
      <v-row>
        <v-col cols="12">
          <v-card-text>
            <h2>EAN 13 Barcode Generator</h2>
            <p>Gebe einen Barcode in das Feld ein und drücke ENTER. Der Barcode wird in unserem gängigen Etikettenformat ausgegegeben.</p><br>
            <v-text-field
              v-model="barcode"
              label="Barcode"
              outlined
              @keydown.enter="createBarcode('EAN13')"
            ></v-text-field>
            <h2>FNSKU Barcode Generator</h2>
            <p>Gebe eine FNSKU in das Feld ein und drücke ENTER. Der Barcode wird in unserem gängigen Etikettenformat ausgegegeben.</p><br>
            <v-text-field
              v-model="barcode"
              label="Barcode"
              outlined
              @keydown.enter="createBarcode('CODE128')"
            ></v-text-field>
          </v-card-text>
        </v-col>
        <v-col cols="12" v-if="error">
          <v-alert type="error">{{ error }}</v-alert>
        </v-col>
      </v-row>
      
    </v-container>

     
  </div>
</template>

<script>
import Header from "./header";
import service from "@/services/ProductionService";


import fileDownload from "js-file-download";

export default {
  name: "dashboard",
  components: {
    Header,
  },
  data: () => ({
    loading: false,
    barcode: null,
  }),
  create() {},
  async mounted() {
  },
  methods: {
  
     async createBarcode(format) {
      this.loading = true;

      try {
        let response = (await service.createBarcode(this.barcode, format)).data;


        let file = atob(response);
        const byteNumbers = new Array(file.length);
        for (let i = 0; i < file.length; i++) {
          byteNumbers[i] = file.charCodeAt(i);
        }
        let fileArray = new Uint8Array(byteNumbers);
        let blobfile = new Blob([fileArray], { type: "buffer" });

        fileDownload(blobfile, "Barcode_" +this.barcode+ ".pdf", "application/pdf");

      } catch (err) {
        console.error(err);
        this.error = "Es konnte kein Barcode erzeugt werden!";
      } finally {
        this.loading = false;
        this.barcode = null;
       
      }
    }  
  }
}
</script>

<style scoped>
.v-card,
.v-btn {
  margin-bottom: 10px;
}
</style>