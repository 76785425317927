
<template>
  <div>
    <Header />
    <v-container style="max-width: 100%">

      <v-row>
        <v-col cols="12" >
          <v-card>
            <v-card-title>
              Zuletzt gepackte Produkte</v-card-title>
            <v-card-text>
              <h3>Ausgewählter Drucker: <a @click="showPrinterSelectionDialog = true">{{ getPrinter() }}</a></h3>
              <v-data-table :headers="tableHeaders" :items="packlistItems" :items-per-page="150" class="elevation-1">
                <template v-slot:item.date="{ item }">
                  {{ item.date | moment("DD.MM.yyyy HH:mm") }}
                </template>

                <template v-slot:item.packEnd="{ item }">
                  {{ item.packEnd | moment("DD.MM.yyyy HH:mm") }}
                </template>

                <template v-slot:item.line1="{ item }">
                  <div v-html="getOrderTranslation(item.orderId, item.sku)" ></div>
                </template>

                <template v-slot:item.print="{ item }">
                  <v-btn icon @click="print(item.orderId)">
                    <v-icon>printer</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="showPrinterSelectionDialog" scrollable max-width="400px">
      <v-card>
        <v-card-title>Wähle Deinen Drucker aus</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-radio-group v-model="printer" column>
            <v-radio v-for="p in printers" :key="p.url" :label="p.name" :value="p.url"></v-radio>
          </v-radio-group>

          <!--<v-switch v-model="autoPrint" inset label="Automatischer Etikettendruck"></v-switch>-->
          
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="showPrinterSelectionDialog = false">
            Abbrechen
          </v-btn>
          <v-btn color="blue darken-1" text @click="savePrinter(); showPrinterSelectionDialog = false;">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <LoadingDialog />
  </div>
</template>
  
<script>
import Header from "./header";
import packlistService from "@/services/PacklistService";
import AuthService from "@/services/AuthService";
import LoadingDialog from "./production/components/LoadingDialog";

import EventBus from "@/event-bus";
import moment from "moment";

export default {
  name: "laserHistory",
  components: {
    Header,
    LoadingDialog
  },
  data: () => ({
    timer: null,
    packlistItems: [],
    loading: false,
    printers: [],
    orders: [],
    showPrinterSelectionDialog: false,
    tableHeaders: [
      { text: "ID", value: "id" },
      { text: "Bestellnummer", value: "orderId" },
      { text: "Bestelldatun", value: "date" },
      { text: "sku", value: "sku" },
      { text: "Line 1", value: "line1" },
      { text: "Gepackt am", value: "packEnd" },
      { text: "Gepackt von", value: "user" },
      { text: "Etikett drucken", value: "print" },
    ],

  }),
  create() { },
  async mounted() {
    this.username = (await AuthService.getUser()).data.user;
    this.timer = setInterval(this.refresh(), 60000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    async refresh() {
      try {
        EventBus.$emit("LOADING_START");
        this.loadPrinters();
        this.packlistItems = (await packlistService.getPackHistory()).data;
        for(let orderId of this.packlistItems.map(p => p.orderId)) {
          if(!this.orders.map(x => x.orderId).includes(orderId)) {
            
            let order = await this.loadOrder(orderId);
            this.orders.push(order);
            
            this.packlistItems.filter(x=> x.orderId == orderId && !x.line1)[0].line1 = true;
            
          }
          console.log(this.orders);
        }
        EventBus.$emit("LOADING_END");
      } catch (err) { console.error(err); }
    },
    async loadPrinters() {
      this.printers = (await packlistService.getPrinters()).data;
    },

    getOrderTranslation(orderId, sku) {
      if(this.orders && this.orders.length > 0) {
        let order = this.orders.filter(x => x.orderId == orderId);

        if(order && order[0]) {
          let line1 = "";
          for(let p of order[0].products.filter(x => x.sku == sku)) {
            if(p.translated) {
              line1 += p.translated.line1 ? p.translated.line1 : p.translated.line2;
            }
            if(order[0].products.filter(x => x.sku == sku).length > 1 && order[0].products.filter(x => x.sku == sku).indexOf(p) != order[0].products.filter(x => x.sku == sku).length -1) line1 += " oder "
          }
          if(line1.indexOf("http") == 0) return '<img src="' +line1+ '" height="40px" />';
          return line1;
        }
        return "?";
      }
       return "";
    },
  
    async loadOrder(orderId) {
      if (orderId) {

        let item = (
          await packlistService.getPacklistItem(orderId)
        ).data;

        return item;
      }
      return null;
    },
    
    savePrinter() {
      this.$cookies.set("printer", this.printer);
    },

    getPrinter() {
      return this.$cookies.get("printer") ? this.$cookies.get("printer").split("printers/")[1] :  "Keiner";
    },

    async print(orderId) {

      let printerCookie = this.$cookies.get("printer");
      if (!printerCookie) {
        this.loadPrinters();
        //no printer selected
        this.showPrinterSelectionDialog = true;
      } else {
        EventBus.$emit("LOADING_START");
        await packlistService.print(orderId, printerCookie);
        EventBus.$emit("LOADING_END");
      }
    },
  },
};
</script>
  
<style scoped>

</style>