import Api from '@/services/Api'

export default {

  getPacklists() {
    return Api().get("v2/packlist/");
  },
  getPacklistItems(name) {
    return Api().get(`v2/packlist/${name}`);
  },
  resetPacklist(name) {
    return Api().post(`v2/packlist/reset/${encodeURIComponent(name)}`);
  },
  getPacklistItem(orderId) {
    return Api().get(`v2/packlist/items/${encodeURIComponent(orderId)}`);
  },
  getMultiCartOrders() {
    return Api().get(`v2/multicart`);
  },
  findPacklistItem(managedId) {
    return Api().get(`v2/packlist/find/${encodeURIComponent(managedId)}`);
  },
  trackStart(packlistItemId) {
    return Api().post(`v2/packlist/tracking/start/${packlistItemId}`);
  },
  trackEnd(packlistItemId) {
    return Api().post(`v2/packlist/tracking/end/${packlistItemId}`);
  },
  applyTrash(orderId, items, resetOnly = false) {
    return Api().post(`v2/packlist/trash/${encodeURIComponent(orderId)}?resetOnly=${resetOnly}`, items);
  },
  getPrinters() {
    return Api().get(`v2/packlist/printers/active`);
  },
  getLaserHistory() {
    return Api().get(`v2/laserhistory`);
  },
  getPackHistory() {
    return Api().get(`v2/packhistory`);
  },
  requestMultiCartSlot(orderId, payload){
    return Api().post(`v2/multicart/${encodeURIComponent(orderId)}`, payload);
  },
  putMultiCartItems(orderId, payload){
    return Api().post(`v2/multicart/${encodeURIComponent(orderId)}/place`, payload);
  },

  print(orderId, printerUrl, under3cm) {
    return Api().post(`v2/packlist/print`, {
      orderId: orderId,
      printerUrl: printerUrl,
      under3cm: under3cm
    });
  },
  getPackStats() {
    return Api().get(`v2/packStats`);
  },


  sendFeedback(orderId, note) {
    return Api().post(`v2/feedback`, {orderId: orderId, note: note});
  },

  getMultiCartShelfStatus() {
    return Api().get('warehouse/multicart/status');
  },

  removeMultiCartArticle(hashcode) {
    return Api().delete(`warehouse/multicart/article/${hashcode}`);
  },

  getOrder(orderId) {
    return Api().get(`mongo/order/${encodeURIComponent(orderId)}`);
  },

}