
<template>
  <div>
    <Header />
    <v-alert :value="error" type="error">{{ error }}</v-alert>
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-alert :value="true" :type="countdown.state">
              Zeit bis zur Abholung:
              <strong>{{ countdown.time }}</strong>
            </v-alert>
        </v-col>
        <v-spacer></v-spacer>
        <v-col><v-btn class="primary" @click="fireEvent('ADD_ORDER')">Bestellung hinzufügen</v-btn></v-col>
      </v-row>
<!-- <img src="@/assets/prime.png" height="32px" /> -->
      <v-row>
        <v-col cols="12">
          <v-card raised style="border-radius: 4px">
            <v-card-title><span>Bestellungen die <strong> Heute </strong> noch raus müssen</span></v-card-title>

            <v-select v-model="filterType" class="ma-2" solo :items="filterTypes()" label="Fertigungsart"></v-select>

            <div class="gridContainer" style="overflow-y: scroll;">

              <v-card style="text-align:center; padding: 0px; width: 200px; height: 234px; display: inline-block; margin: 10px;" v-for="i in filterItems(true)" :key="i.name" @click="open(i.name)">
                <v-img :src="i.url" width="100%" contain height="100px"></v-img>

                <span style="font-size: 14px; font-weight: bold; text-align: center; display:block; margin-top: 10px">{{i.name}}</span>
                <span style="font-size: 18px; font-weight: bold; text-align: center; display:block; margin: 10px">{{i.orders}}</span>

                <v-row style="border-top: 1px solid #ccc;">
                  <v-col cols="12" v-if="i.type">
                    <span style="font-size: 11px; font-weight: bold; text-align: center; display:block;">{{i.type}}</span>
                    <span style="font-size: 10px; font-weight: normal; text-align: center; display:block;">Fertigungsart</span>
                  </v-col>
                </v-row>

                

                <img v-if="i.prime" class="primeBanner" src="@/assets/prime_banner.svg" />
              </v-card>

            </div>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card raised style="border-radius: 4px">
            <v-card-title><span>Bestellungen die nicht Heute raus müssen</span></v-card-title>

            <div class="gridContainer" style="overflow-y: scroll;">

              <v-card style="text-align:center; padding: 0px; width: 200px; height: 234px; display: inline-block; margin: 10px;" v-for="i in filterItems(false)" :key="i.name" @click="open(i.name)">
                <v-img :src="i.url" width="100%" contain height="100px"></v-img>

                <span style="font-size: 14px; font-weight: bold; text-align: center; display:block; margin-top: 10px">{{i.name}}</span>
                <span style="font-size: 18px; font-weight: bold; text-align: center; display:block; margin: 10px">{{i.orders}}</span>

                <v-row style="border-top: 1px solid #ccc;">
                  <v-col cols="6" style="border-right: 1px solid #ccc;" v-if="i.laserTime">
                    <span style="font-size: 11px; font-weight: bold; text-align: center; display:block;">{{ i.laserTime | timeFormatMin }}</span>
                    <span style="font-size: 10px; font-weight: normal; text-align: center; display:block;">Laserzeit</span>
                  </v-col>
                  <v-col :cols=" i.laserTime ? '6' : '12'">
                    <span style="font-size: 11px; font-weight: bold; text-align: center; display:block;">{{ i.packTime | timeFormatMin }}</span>
                    <span style="font-size: 10px; font-weight: normal; text-align: center; display:block;">Packzeit</span>
                  </v-col>
                </v-row>

                

                <img v-if="i.prime" class="primeBanner" src="@/assets/prime_banner.svg" />
              </v-card>

            </div>
          </v-card>
        </v-col>
       
      </v-row>
      
    </v-container>
   
    <ManuelInsertOrderDialog />
  </div>
</template>

<script>
import Header from "../header";
import productionService from "@/services/ProductionService";
import dashboardService from "@/services/DashboardService";

import router from "@/router";
import ManuelInsertOrderDialog from "./components/ManuelInsertOrderDialog.vue";

import EventBus from "@/event-bus";

import moment from "moment";

export default {
  name: "production",
  components: {
    Header,
    ManuelInsertOrderDialog,
  },
  data: () => ({
    loading: true,
    items: [],
    timer: null,
    timer2: null,
    filterType: null,
    error: null,
    packlist: {
      dialog: false,
      success: false,
      name: "",
    },
    countdown: {
      time: "",
      state: "info",
      laserTime: 0,
      packTime: 0,
    },
  }),
  create() {},
  async mounted() {
    this.refresh();

    this.timer = setInterval(this.countdownCutOff, 1000);
    this.timer2 = setInterval(this.refresh, 10000);
  },

  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.timer2);
  },

  computed: {},

  filters: {
    timeFormat: function (value) {
      if (!value) return "0s";
      return Math.ceil(value).toString() + "s";
    },
    timeFormatMin: function (value) {
      if (!value) return "0 min";
      return moment
        .utc(moment.duration(value, "seconds").asMilliseconds())
        .format("HH:mm:ss");
    },
  },
  methods: {

    filterTypes() {
      let types = this.items.map(x => x.type).filter((value, index, self) => self.indexOf(value) === index);
      return types;
    },  

    async refresh() {
      let items = (await productionService.getV2()).data;

      let orders = (await dashboardService.get()).data;

      for(let order of orders.openOrders) {
        for(let p of order.products) {
          let table = p.translated ? p.translated.table : null;
          
          let isPrime = order.shipping.type == "PRIME"

          let date = order.shipping.lastShippmentDate;
          if (date == null) date = order.shipping.shippmentDate;

          let shipDate = new Date(date);
          shipDate.setHours(0, 0, 0, 0);

          let isToday = shipDate.getTime() <= new Date().getTime() 

          let tables = items.filter(x => x.name == table)
          if(tables && tables.length > 0) {
            if(isPrime) tables[0].prime = true 
            if(isToday) tables[0].now = true 
          }
        }
      }

      this.items = items;

    },

    filterItems(now) {
      if(this.items) {
        if(this.filterType) {
          return this.items.filter(x=> x.type == this.filterType && (now ? x.now : !x.now));
        } else {
          return this.items.filter(x=>  now ? x.now : !x.now)
        
        }
      }
      return [];
    },

    countdownCutOff() {

      let now = new Date();
      let cutOff = new Date().setHours(15, 30, 0, 0);

      if (cutOff < now && cutOff + 1000 * 60 * 10 > now) {
        this.countdown.time = "Jetzt";
        this.countdown.state = "warning";
      } else if (cutOff < now) {
        this.countdown.time = "Erst morgen wieder!";
        this.countdown.state = "info";
      } else {
        let sec_num = Math.round((cutOff - now) / 1000);
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - hours * 3600) / 60);
        var seconds = sec_num - hours * 3600 - minutes * 60;

        if (hours < 10) {
          hours = "0" + hours;
        }
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        if (seconds < 10) {
          seconds = "0" + seconds;
        }
        this.countdown.time = hours + ":" + minutes + ":" + seconds;
        this.countdown.state = "error";

        
      }
    },

    async loadItem(i) {
      let itemData = (await productionService.getItem(i)).data;

      this.items.push({
        name: i,
        count: itemData.length,
        laserTime: laserTime,
        packTime: packTime,
      });

      if (itemData.length > 0) {
        if (this.isPrime(i)) {
          this.primeItems.push({
            name: i,
            count: itemData.length,
            laserTime: laserTime * itemData.length,
            packTime: packTime * itemData.length,
          });
        } else {
          if (i == "info" || i == "tracking") return;
          this.offPrimeItems.push({
            name: i,
            count: itemData.length,
            laserTime: laserTime * itemData.length,
            packTime: packTime * itemData.length,
          });
        }
      }
    },
    open(name) {
      if (name == "photozippo") {
        router.push({ path: "/production/photozippo/details" });
      } else if (name == "babycube") {
        router.push({ path: "/production/babycube/details" });
      } else if (name == "date_keychain") {
        router.push({ path: "/production/datekeychain/details" });
      } else if (name == "victorinox") {
        router.push({ path: "/production/victorinox/details" });
      } else if (name == "keychain_photo") {
        router.push({ path: "/production/keychain_photo/details" });
      } else if (name == "photokeychain_amz") {
        router.push({ path: "/production/keychain_picture/details" });
      } else if (name == "photozippo_amz") {
        router.push({ path: "/production/photozippo_amz/details" });
      } else {
        router.push({ path: "/production/" + name });
      }
    },

    customSort(items, index, isDescending) {
      items.sort((a, b) => {
        console.log(items);
        if (index === "count") {
          return b.count - a.count;
        }
      });

      return items;
    },
    fireEvent(tag, payload) {
      EventBus.$emit(tag, payload);
    },
  },
};
</script>

<style scoped>
  .primeBanner {
    width: 35%;
    height: auto;
    position: absolute;
    right: 0px;
    top: 0px;
  }
</style>