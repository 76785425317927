<template>
    <div >
      <Header />
    
    <v-container>
        <v-text-field
            v-model="orderId"
            label="Bestellnummer"
            outlined
            @keyup.enter="fetchOrderData"></v-text-field>
            <v-card class="mt-4" v-if="!order">
      <v-card-title>
        Offene Bestellungen
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="openOrders"
        :search="search"
        :loading="loadingOpenOrders"
        :items-per-page="-1"
        class="elevation-1"
      >
        <template v-slot:item.grandtotal="{ item }">
          {{ formatPrice(item.grandtotal) }}
        </template>
        <template v-slot:item.create_timestamp="{ item }">
          {{ formatDate(item.create_timestamp) }}
        </template>
        <template v-slot:item.acsImported="{ item }">
          <v-chip :color="getAcsStatusColor(item.acsImported)" dark>{{ item.acsImported ? "ACS Importiert" : "Nicht importiert" }}</v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn small @click="loadOrderDetails(item.receipt_id)">
            Details
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
      <v-card v-if="order">
        <v-card-title>
          <v-icon @click="reset" large class="mr-2">arrow_back</v-icon>
          <v-spacer></v-spacer>
          <v-chip :color="getAcsStatusColor(order.acsImported)" dark>{{ order.acsImported ? "ACS Importiert" : "Nicht importiert" }}</v-chip>
          <v-chip :color="getStatusColor(order.status)" dark>{{ order.status }}</v-chip>
        </v-card-title>
  
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <h3>Kundeninformationen</h3>
              <v-form>
                <v-text-field
                  v-model="editableCustomerInfo.firstName"
                  label="Vorname"
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="editableCustomerInfo.lastName"
                  label="Nachname"
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="editableCustomerInfo.street"
                  label="Straße"
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="editableCustomerInfo.houseNumber"
                  label="Hausnummer"
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="editableCustomerInfo.additionalInfo"
                  label="Zusätzliche Informationen"
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="editableCustomerInfo.zipCode"
                  label="PLZ"
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="editableCustomerInfo.city"
                  label="Stadt"
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="editableCustomerInfo.country"
                  label="Land"
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="editableCustomerInfo.email"
                  label="E-Mail"
                  outlined
                  dense
                ></v-text-field>
              </v-form>
            </v-col>
  
            <v-col cols="12" md="6">
              <h3>Bestellinformationen</h3>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Bestellnummer</v-list-item-title>
                    <v-list-item-subtitle>{{ order.receipt_id }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Bestelldatum</v-list-item-title>
                    <v-list-item-subtitle>{{ formatDate(order.create_timestamp) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Zahlungsmethode</v-list-item-title>
                    <v-list-item-subtitle>{{ order.payment_method }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
  
          <v-divider class="my-4"></v-divider>
          <h3>Bestellte Artikel</h3>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Artikel</th>
                <th>Menge</th>
                <th>Preis</th>
                <th>Gesamt</th>
                <th>SKU</th>
                <th>Status</th>
                <th>Personalisierung</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in order.transactions" :key="item.transaction_id">
                <td>{{ item.sku }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ getDiscountedPrice(item) }}</td>
                <td>{{ formatPrice(calculateTotal(item)) }}</td>
                <td>{{ getInternalSKU(item.sku) }}</td>
                <td>
                  <v-chip
                    :color="productExists(item.sku) ? 'green' : 'red'"
                    text-color="white"
                    small
                  >
                    {{ productExists(item.sku) ? 'Vorhanden' : 'Nicht vorhanden' }}
                  </v-chip>
                </td>
                <td>
                  <v-btn small @click="openPersonalizationDialog(item)">
                    Bearbeiten
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
  
          <v-divider class="my-4"></v-divider>
  
          <v-row>
            <v-col cols="12" md="6">
              <h3>Versandinformationen</h3>
              <v-list dense v-if="order.shipments && order.shipments.length">
                <v-list-item v-for="shipment in order.shipments" :key="shipment.receipt_shipping_id">
                  <v-list-item-content>
                    <v-list-item-title>{{ shipment.carrier_name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      Trackingnummer: {{ shipment.tracking_code }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      Versanddatum: {{ formatDate(shipment.shipment_notification_timestamp) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <p v-else>Keine Versandinformationen verfügbar</p>
            </v-col>
  
            <v-col cols="12" md="6">
              <h3>Zahlungsübersicht</h3>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Zwischensumme</v-list-item-title>
                    <v-list-item-subtitle>{{ formatPrice(order.subtotal) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Versandkosten</v-list-item-title>
                    <v-list-item-subtitle>{{ formatPrice(order.total_shipping_cost) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Rabatt</v-list-item-title>
                    <v-list-item-subtitle>-{{ formatPrice(order.discount_amt) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Gesamtsumme</v-list-item-title>
                    <v-list-item-subtitle>{{ formatPrice(order.grandtotal) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
  
     <!-- Personalisierungs-Dialog -->
<v-dialog v-model="personalizationDialog" max-width="800px">
  <v-card>
    <v-card-title>
      Personalisierung bearbeiten
      <v-spacer></v-spacer>
      <v-btn icon @click="personalizationDialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-textarea
              v-model="currentPersonalization"
              label="Aktuelle Personalisierung"
              readonly
              outlined
              rows="4"
            ></v-textarea>
          <div v-if="svgOptions">
            <h4>Verfügbare Optionen:</h4>
            <v-list dense>
              <v-list-item v-for="(option, index) in svgOptions" :key="index">
                <v-list-item-content>
                  <v-list-item-title>{{ option.label }}</v-list-item-title>
                  <v-list-item-subtitle>{{ option.field }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-col>
        <v-col cols="6">
          <v-list dense>
            <v-list-item v-for="(line, index) in personalizationLines" :key="index">
              <v-list-item-content>
                <v-row>
                  <v-col cols="5">
                    <v-text-field
                      v-model="line.label"
                      :label="`Label ${index + 1}`"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="7">
                    <v-text-field
                      v-model="line.value"
                      :label="`Wert ${index + 1}`"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon small @click="removePersonalizationLine(index)">
                  <v-icon small>delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-btn small color="primary" class="mt-2" @click="addPersonalizationLine">
            Zeile hinzufügen
          </v-btn>
          <v-btn @click="showSVGStudioPreview()">SVG Studio Vorschau</v-btn>

          <v-img v-if="preview" :src="preview" contain></v-img>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="savePersonalization(false)">
        Speichern
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
  
      <!-- JSON Output -->
      <v-card class="mt-4" v-if="order">
        <v-card-title>JSON Output</v-card-title>
        <v-card-text>
          <v-textarea v-if="manipulatedJSON" v-model="manipulatedJSON" label="JSON"></v-textarea>

          <v-btn @click="manipulatedJSON = JSON.stringify(generatedJSON, null, 2)">Bearbeiten</v-btn>
          <v-btn v-if="manipulatedJSON" @click="manipulatedJSON = null">Abbrechen</v-btn>
          <pre>{{ JSON.stringify(generatedJSON, null, 2) }}</pre>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="importOrder" :loading="importing">
            Importieren
          </v-btn>
          <v-btn color="primary" @click="reset" :loading="importing">
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
  
      <!-- Snackbar für Benachrichtigungen -->
      <v-snackbar v-model="snackbar" :color="snackbarColor" top>
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            Schließen
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Header from "../header";

  export default {
    name: 'OrderDetailsPage',
    components: {
      Header,
    },

    data() {
      return {
        orderId: null,
        order: null,
        personalizationDialog: false,
        currentPersonalization: '',
        personalizationLines: [],
        svgOptions: null,
        currentItem: null,
        generatedJSON: null,
        manipulatedJSON: null,
        importing: false,
        snackbar: false,
        snackbarText: '',
        stockData: [],
        snackbarColor: 'success',
        editableCustomerInfo: {
          firstName: '',
          lastName: '',
          street: '',
          houseNumber: '',
          additionalInfo: '',
          zipCode: '',
          city: '',
          country: '',
          email: ''
        },
        openOrders: [],
        loadingOpenOrders: false,
        search: '',
        headers: [
          { text: 'Bestellnummer', value: 'receipt_id' },
          { text: 'Kunde', value: 'name' },
          { text: 'Gesamtpreis', value: 'grandtotal' },
          { text: 'Bestelldatum', value: 'create_timestamp' },
          { text: 'Status', value: 'acsImported' },
          { text: 'Aktionen', value: 'actions', sortable: false }
        ]
      };
    },
    methods: {
      formatDate(timestamp) {
        return new Date(timestamp * 1000).toLocaleString();
      },
      formatPrice(priceObj) {
        return new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: priceObj.currency_code
        }).format(priceObj.amount / priceObj.divisor);
      },
      calculateTotal(item) {
        return {
          amount: this.generateGrossPriceWithDiscount(item.price.amount) * item.quantity,
          divisor: 1,
          currency_code: item.price.currency_code
        };
      },
      getStatusColor(status) {
        switch (status.toLowerCase()) {
          case 'completed':
            return 'green';
          case 'processing':
            return 'orange';
          case 'cancelled':
            return 'red';
          default:
            return 'grey';
        }
      },
      getAcsStatusColor(status) {
        if (status) {
          return 'green';
        } else {
          return 'red';
        }
      },
      openPersonalizationDialog(item, open = true) {
        this.currentItem = item;
        this.currentPersonalization = this.getCurrentPersonalizationString(item);
        this.personalizationLines = this.getPersonalizationLines(item);
        this.personalizationDialog = open;
        this.loadSVGOptions(this.getInternalSKU(item.sku));
        this.preview = null;
      },

      getPersonalizationText(item) {
        const personalization = item.variations.find(v => v.property_id === 54);
        return personalization ? personalization.formatted_value : '';
      },

      getCurrentPersonalizationString(item) {
        return item.variations
          .map(variation => `${variation.formatted_name}: ${variation.formatted_value}`)
          .join('\n');
      },

      getPersonalizationLines(item) {
        let lines = [];

        item.variations.forEach(variation => {
          if (variation.formatted_name.toLowerCase() === "motiv") {
            lines.push({
              label: `motiv1`,
              value: variation.formatted_value
            });
          } else if (variation.formatted_name.toLowerCase() === "personalization") {
            const personalizations = variation.formatted_value.split('\n');
            personalizations.forEach((line, index) => {
              lines.push({
                label: `line${index + 1}`,
                value: line.trim()
              });
            });
          }
        });

        // Stellen Sie sicher, dass mindestens eine Zeile vorhanden ist
        if (lines.length === 0) {
          lines.push({ label: 'line1', value: '' });
        }

        return lines;
      },

      addPersonalizationLine() {
        const newLabel = this.personalizationLines.some(line => line.label.startsWith('motiv'))
          ? `motiv${this.personalizationLines.filter(line => line.label.startsWith('motiv')).length + 1}`
          : `line${this.personalizationLines.filter(line => line.label.startsWith('line')).length + 1}`;

        this.personalizationLines.push({
          label: newLabel,
          value: ''
        });
      },

      removePersonalizationLine(index) {
        this.personalizationLines.splice(index, 1);
      },


      savePersonalization(close = false) {
        if (this.currentItem) {
          this.currentItem.customPersonalization = this.personalizationLines;
          // Aktualisieren Sie auch den currentPersonalization String
          this.currentPersonalization = this.personalizationLines
            .map(line => `${line.label}: ${line.value}`)
            .join('\n');
        }
        this.personalizationDialog = close;
        this.generateJSON();
      },

      generateGrossPriceWithDiscount(price,divisor) {
        let itemCount = this.order.transactions.length;
        let discount = this.order.discount_amt.amount / itemCount;
        return ((price) - discount) / divisor;
      },

      getDiscountedPrice(item) {
        return this.generateGrossPriceWithDiscount(item.price.amount,item.price.divisor);
      },

      generateJSON() {
        if (!this.order) return;

        this.generatedJSON = {
          reference: `${this.order.receipt_id}`,
          createDate: new Date().toISOString(),
          shipping: { type: "Standard" },
          firstname: this.editableCustomerInfo.firstName,
          lastname: this.editableCustomerInfo.lastName,
          street: this.editableCustomerInfo.street,
          housenumber: this.editableCustomerInfo.houseNumber,
          additional: this.editableCustomerInfo.additionalInfo,
          email: this.editableCustomerInfo.email,
          zip: this.editableCustomerInfo.zipCode,
          city: this.editableCustomerInfo.city,
          country: this.editableCustomerInfo.country,
          shippingCost: this.order.total_shipping_cost.amount / this.order.total_shipping_cost.divisor,
          orderItems: this.order.transactions.map(item => {
            const stockItem = this.stockData.find(stock => stock.externalSKU === item.sku);
            const baseItem = {
              id: item.transaction_id.toString(),
              sku: stockItem ? stockItem.sku : item.sku,
              qty: item.quantity,
              grossPrice: item.price.amount / item.price.divisor,
              discount: (this.order.discount_amt.amount / this.order.transactions.length) / item.price.divisor,
            };

            // Fügen Sie die benutzerdefinierten Personalisierungsfelder hinzu
            if (item.customPersonalization) {
              item.customPersonalization.forEach(field => {
                baseItem[field.label] = field.value;
              });
            } else {
              // Falls keine benutzerdefinierten Personalisierungen vorhanden sind, 
              // verwenden Sie die ursprünglichen Variationen
              item.variations.forEach(variation => {
                if (variation.formatted_name.toLowerCase() === "motiv") {
                  baseItem.motiv1 = variation.formatted_value;
                } else if (variation.formatted_name.toLowerCase() === "personalization") {
                  variation.formatted_value.split('\n').forEach((line, index) => {
                    baseItem[`line${index + 1}`] = line.trim();
                  });
                }
              });
            }

            return baseItem;
          }),
        };
      },

      async initBasicTranslation() {
        for (let transaction of this.order.transactions) {
          this.openPersonalizationDialog(transaction, false);
          this.savePersonalization(false);
        }

      },
      async fetchOrderData() {
        try {
          const [orderResponse, stockResponse] = await Promise.all([
            axios.get(`https://etsy.acs.accelery.de/api/order?orderId=${this.orderId}`),
            axios.get('https://etsy.acs.accelery.de/api/stock')
          ]);

          this.order = orderResponse.data;
          this.stockData = stockResponse.data;

          this.initializeEditableCustomerInfo();
          this.initBasicTranslation();


          this.generateJSON();
        } catch (error) {
          console.error('Fehler beim Abrufen der Daten:', error);
          this.showSnackbar('Fehler beim Laden der Daten', 'error');
        }
      },

      getInternalSKU(externalSKU) {
        const stockItem = this.stockData.find(item => item.externalSKU === externalSKU);
        return stockItem ? stockItem.sku : 'Nicht gefunden';
      },

      productExists(externalSKU) {
        return this.stockData.some(item => item.externalSKU === externalSKU);
      },

      initializeEditableCustomerInfo() {
        const nameParts = this.order.name.split(' ');
        this.editableCustomerInfo = {
          firstName: nameParts[0] || '',
          lastName: nameParts.slice(1).join(' ') || '',
          street: this.order.first_line || '',
          houseNumber: '',  // Extrahieren Sie dies aus first_line, wenn möglich
          additionalInfo: this.order.second_line || '',
          zipCode: this.order.zip || '',
          city: this.order.city || '',
          country: this.order.country_iso || '',
          email: this.order.buyer_email || ''
        };
      },

      async reset() {
        this.order = null;
        this.generatedJSON = null;
        this.currentItem = null;
        this.svgOptions = null;
        await this.fetchOpenOrders();
      },


      async importOrder() {
        this.importing = true;
        try {
          await axios.post('https://etsy.acs.accelery.de/api/place', this.manipulatedJSON ? JSON.parse(this.manipulatedJSON) : this.generatedJSON);
          this.showSnackbar('Bestellung erfolgreich importiert', 'success');

          await this.reset();


        } catch (error) {
          console.error('Fehler beim Importieren der Bestellung:', error);
          this.showSnackbar('Fehler beim Importieren der Bestellung', 'error');
        } finally {
          this.importing = false;
        }
      },
      showSnackbar(text, color = 'success') {
        this.snackbarText = text;
        this.snackbarColor = color;
        this.snackbar = true;
      },

      loadSVGOptions(sku) {
        let url = `https://svg.accelery.host/public/product/${sku}`
        axios.get(url)
          .then(response => {
            let data = (response.data);
            this.$nextTick(() => {
              this.svgOptions = data.options;
            });
          })
          .catch(error => {
            this.svgOptions = null;
          });
      },


      showSVGStudioPreview() {

        this.savePersonalization(true);

        const sku = this.getInternalSKU(this.currentItem.sku);
        let options = {}
        for (let line of this.currentItem.customPersonalization) {
          options[line.label] = line.value;
        }


        this.loading = true;
        //axios request post  https://svg.accelery.host/public/product/${SKU}/preview returns json with preview base64 img
        let url = `https://svg.accelery.host/public/product/${sku}/preview`;
        this.showSnackbar('Vorschau wird geladen', 'info');
        axios.post(url, options)
          .then(response => {
            let data = (response.data);
            this.$nextTick(() => {
              this.preview = data.preview;
            });
          })
          .catch(error => {
            this.showSnackbar('Fehler beim Laden der Vorschau', 'error');
          });
      },

      //Open Orders
      async fetchOpenOrders() {
        this.loadingOpenOrders = true;
        try {
          const response = await axios.get('https://etsy.acs.accelery.de/api/orders');
          this.openOrders = response.data;
        } catch (error) {
          console.error('Fehler beim Abrufen der offenen Bestellungen:', error);
          this.showSnackbar('Fehler beim Laden der offenen Bestellungen', 'error');
        } finally {
          this.loadingOpenOrders = false;
        }
      },

      formatPrice(price) {
        return new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: price.currency_code
        }).format(price.amount / price.divisor);
      },

      formatDate(timestamp) {
        return new Date(timestamp * 1000).toLocaleString('de-DE');
      },

      loadOrderDetails(orderId) {
        this.orderId = orderId;
        this.fetchOrderData();
      }

    },
    mounted() {
      this.fetchOpenOrders();
    },
    watch: {
      editableCustomerInfo: {
        handler() {
          this.generateJSON();
        },
        deep: true
      }
    }
  };
</script>