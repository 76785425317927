<template>
  <div>
    <Header />
    <v-container>
      <v-row>
        <v-col sm="6" md="3" lg="2" v-for="sku in skus" :key="sku">
          <v-card>
            <v-card-title style="font-size: 13px; font-weight: bold">{{ sku.name }}: <br><v-chip
            color="orange"
            label
            >
            {{ sku.count }} Stück
            </v-chip>

            </v-card-title>
              <v-card-text>
                <v-img max-height="150px" contain :src="'http://backoffice.accelery.de/api/files/sku?sku=' + sku.name" ></v-img>
               </v-card-text>
          </v-card>
        </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
          <v-card class="elevation-5">
            <v-card-text primary-title>
              <h1 class="display-1">
                <v-icon @click="$router.go(-1)" large class="mr-2"
                  >arrow_back</v-icon
                >Bestellungen
              </h1>
              <v-spacer></v-spacer>
              <v-btn
                class="secondary"
                small
                @click="fireEvent('CREATE_PACKLIST', $route.params.name)"
                >Packliste anlegen</v-btn
              >

              <v-data-table
                hide-default-footer
                :must-sort="true"
                :loading="loading"
                :items-per-page="-1"
                :headers="tableHeaders"
                :items="items"
                :item-class="itemClass"
              >
                <template v-slot:body="{ items, headers }">
                  <tbody>
                    <tr
                      v-for="(item, idx) in items"
                      :key="idx"
                      :class="itemClass(item)"
                    >
                      <td v-for="(header, key) in headers" :key="key">
                        <v-edit-dialog
                          :return-value.sync="item[header.value]"
                          v-if="editBlacklist.indexOf(header.value) == -1"
                          @save="save(item)"
                          @cancel="cancel"
                          @open="open"
                          @close="close"
                          large
                        >
                          {{ item[header.value] }}
                          <template v-slot:input>
                            <v-text-field
                              v-model="item[header.value]"
                              label="Bearbeiten"
                              single-line
                            ></v-text-field>
                          </template>
</v-edit-dialog>

<div v-else-if="header.value == 'id'">
  {{ items.indexOf(item) }}
</div>

<div v-else-if="header.value == 'reproduction'">
  <v-btn v-if="item.reproduction == true ||
          item.directShip == true
          " primary @click="fireEvent('DHLSHIPPING', item.orderId)">Versenden</v-btn>
</div>

<div v-else-if="header.value == 'reusable'">
  <v-btn v-if="item.reusable" icon @click="fireEvent('REUSE_ITEM', item.reusable)">
    Verwenden <v-icon>check</v-icon></v-btn>
</div>

<div v-else-if="header.value == 'orderItems'">
  <v-btn v-if="item.orderItems" @click="fireEvent('SHOW_CART', item.orderItems)">
    WARENKORB
  </v-btn>
</div>

<div v-else>
  {{ item[header.value] }}
</div>
</td>
<td>
  <v-icon small @click="fireEvent('DELETE_ITEM', item)">
    delete
  </v-icon>
</td>
</tr>
</tbody>
</template>
</v-data-table>
</v-card-text>
</v-card>
</v-col>
</v-row>
</v-container>

<DHLDialog />
<ReuseDialog />
<DeleteDialog />
<LoadingDialog />
<PacklistDialog />
<ShippingDialog />
<CartDialog />
</div>
</template>

<script>
import Header from "../header";
import service from "@/services/ProductionService";
import router from "@/router";

import DHLDialog from "./components/DHLDialog.vue";
import ReuseDialog from "./components/ReuseDialog.vue";
import DeleteDialog from "./components/DeleteDialog.vue";
import LoadingDialog from "./components/LoadingDialog.vue";
import PacklistDialog from "./components/PacklistDialog.vue";
import ShippingDialog from "./components/ShippingDialog.vue";
import CartDialog from "./components/CartDialog.vue";

import EventBus from "@/event-bus";

export default {
  name: "productionItem",
  components: {
    Header,
    DHLDialog,
    ReuseDialog,
    DeleteDialog,
    LoadingDialog,
    PacklistDialog,
    ShippingDialog,
    CartDialog,
  },
  data: () => ({
    loading: true,
    tableAttributes: [],
    items: [],
    editBlacklist: [
      "id",
      "orderId",
      "reproduction",
      "reusable",
      "sku",
      "delete",
      "orderItems",
    ],
    tableHeaders: [],
    skus: [],

    reusableItems: [],
  }),
  create() { },
  async mounted() {
    EventBus.$on("REFRESH", () => {
      this.refresh();
    });

    this.refresh();
  },
  methods: {
    fireEvent(tag, payload) {
      EventBus.$emit(tag, payload);
    },

    itemClass(item) {
      if (item.orderItems) {
        return "multiOrder";
      } else if (item.reproduction) {
        return "reproduction";
      } else if (item.reusable) {
        return "reusable";
      }
    },

    async refresh() {
      //Show Loading Dialog
      this.loading = true;
      EventBus.$emit("LOADING_START");

      this.tableAttributes = null;
      this.items = [];
      this.tableHeaders = [];
      this.reusableItems = [];
      this.skus = [];
      try {
        let data = (await service.getItem(this.$route.params.name)).data;
        this.tableAttributes = Object.keys(data[0]).filter(
          (x) => x != "reproduction" && x != "orderItems"
        );

        //Build Table Headers
        for (let key of this.tableAttributes) {
          this.tableHeaders.push({
            text: key,
            value: key,
            align: "left",
            sortable: false,
          });
        }

        this.tableHeaders.push({
          text: "Warenkorb",
          value: "orderItems",
          align: "left",
          sortable: false,
        });

        //Add Static Header for DIRECT SHIPPMENT
        this.tableHeaders.push({
          text: "Direkt versenden",
          value: "reproduction",
          align: "left",
          sortable: false,
        });

        //Get reusable Items of SKU
        for (let sku of new Set(data.map((x) => x.sku))) {
          let alreadyProducedItems = (await service.getReusableItems(sku)).data;

          for (let i of alreadyProducedItems) {
            this.reusableItems.push(i);
          }
        }

        //If reusable Items avaialble -> Check if we have matches
        if (this.reusableItems.length > 0) {
          this.matchReusableItems(data);
        }

        this.items = data;

        //Build SKU Overview
        for (let item of this.items) {
          let sku = item.sku;
          if (!sku) continue;

          let isAdded = false;
          for (let s of this.skus) {
            if (s.name == sku) {
              isAdded = true;
              s.count++;
            }
          }

          if (!isAdded) this.skus.push({ name: sku, count: 1 });
        }

        this.loading = false;
        EventBus.$emit("LOADING_END");
      } catch (err) {
        console.error(err);

        if (err.response.status == 401) {
          router.replace("/auth/login");
        } else {
          console.error(err);
        }
      }
    },

    matchReusableItems(data) {
      this.tableHeaders.push({
        text: "Bereits Graviert",
        value: "reusable",
        align: "left",
        sortable: false,
      });

      for (let item of data) {
        for (let rItem of this.reusableItems) {
          if (
            (item["name"] == rItem.name ||
              item["line1"] == rItem.name ||
              item["text"] == rItem.name) &&
            item["font"] == rItem["font"]
          ) {
            //WE HAVE A MATCH
            console.log("We have a match");
            item.reusable = rItem;
          }
        }
      }
    },

    async save(item) {
      this.loading = true;
      EventBus.$emit("LOADING_START");
      try {
        await service.updateEntry(this.$route.params.name, item);
      } catch (e) {
        alert(
          "Der Server kann den Vorgang nicht abschließen. Bitte versuche es erneut."
        );
      } finally {
        await this.refresh();
      }
    },
  },
  breforeDestroy() { },
};
</script>

<style>
.reproduction {
  background-color: cyan;
}

.reusable {
  background-color: magenta;
  color: white;
}

.multiOrder {
  background-color: lime;
  color: white;
}
</style>
