<template>
  <div>
    <Header />
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="d-flex align-center">
          <h1 class="mr-4">Warenkorbregal Verwaltung</h1>
          <v-btn
            color="primary"
            class="mr-2"
            @click="showPrinterSelectionDialog = true"
          >
            <v-icon left>mdi-printer</v-icon>
            Drucker wählen
          </v-btn>
          <v-btn
            :loading="loadingAllOrders"
            :disabled="loadingAllOrders"
            color="primary"
            @click="loadAllOrders"
          >
            <v-icon left>mdi-refresh</v-icon>
            Alle Daten laden
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchQuery"
            label="Suche in Artikelattributen"
            prepend-icon="mdi-magnify"
            clearable
            class="mx-4"
            style="max-width: 300px"
          ></v-text-field>
        </v-col>
      </v-row>
      
      <!-- Fortschrittsbalken -->
      <v-row v-if="loadingAllOrders">
        <v-col cols="12">
          <v-progress-linear
            :value="loadProgress"
            :color="progressColor"
            height="25"
            striped
          >
            <template v-slot:default>
              <strong>{{ Math.ceil(loadProgress) }}% ({{ loadedOrdersCount }}/{{ totalOrders }})</strong>
            </template>
          </v-progress-linear>
        </v-col>
      </v-row>
      
      <!-- Regal Visualisierung -->
      <v-row v-for="column in ['A', 'B', 'C', 'D', 'E']" :key="column" class="shelf-row" dense>
        <v-col cols="1" class="column-label">
          {{ column }}
        </v-col>
        <v-col cols="11">
          <v-row dense>
            <!-- Linke Spalte: Slots 1-8 -->
            <v-col cols="6">
              <v-row dense>
                <v-col v-for="slot in 8" :key="slot" cols="1.5">
                  <v-card 
                    :class="getSlotClass(getSlotContent(column + slot))"
                    class="slot-card"
                    @click="showSlotDetails(column + slot)"
                  >
                    <v-card-title class="slot-title">{{ column }}{{ slot }}</v-card-title>
                    <v-card-text v-if="getSlotContent(column + slot)">
                      <div class="order-count">{{ getSlotContent(column + slot).articles.length }} Artikel</div>
                      <div class="order-age">{{ getSlotAge(getSlotContent(column + slot)) }}</div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            
            <!-- Rechte Spalte: Slots 9-16 -->
            <v-col cols="6">
              <v-row dense>
                <v-col v-for="slot in 8" :key="slot + 8" cols="1.5">
                  <v-card 
                    :class="getSlotClass(getSlotContent(column + (slot + 8)))"
                    class="slot-card"
                    @click="showSlotDetails(column + (slot + 8))"
                  >
                    <v-card-title class="slot-title">{{ column }}{{ slot + 8 }}</v-card-title>
                    <v-card-text v-if="getSlotContent(column + (slot + 8))">
                      <div class="order-count">{{ getSlotContent(column + (slot + 8)).articles.length }} Artikel</div>
                      <div class="order-age">{{ getSlotAge(getSlotContent(column + (slot + 8))) }}</div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Drucker-Auswahl Dialog -->
      <v-dialog v-model="showPrinterSelectionDialog" max-width="400px">
        <v-card>
          <v-card-title>Wähle Deinen Drucker aus</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 300px">
            <v-radio-group v-model="printer" column>
              <v-radio
                v-for="p in printers"
                :key="p.url"
                :label="p.name"
                :value="p.url"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="savePrinter(); showPrinterSelectionDialog = false"
            >
              Speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <!-- Details Dialog -->
    <v-dialog v-model="detailDialog" max-width="1400">
      <v-card v-if="selectedSlot" class="detail-dialog">
        <v-card-title class="headline">
          Details für Slot {{ selectedSlot.slot }}
          <v-spacer></v-spacer>
          <v-btn icon @click="detailDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="min-height: 80vh">
          <v-overlay :value="isLoading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          
          <v-expansion-panels>
            <v-expansion-panel
              v-for="order in selectedSlot.orders"
              :key="order.orderId"
            >
              <v-expansion-panel-header>
                <div class="d-flex align-center" style="width: 100%">
                  <div>
                    <strong>Auftrag {{ order.orderId }}</strong>
                    <span class="ml-2 grey--text">
                      ({{ order.articles.length }} Artikel)
                    </span>
                    <v-chip 
                      x-small 
                      class="ml-2"
                      :color="getOrderReadyStatus(orderArticles[order.orderId]?.products).color"
                    >
                      {{ getOrderReadyStatus(orderArticles[order.orderId]?.products).text }}
                    </v-chip>
                  </div>
                  <v-spacer></v-spacer>
                  <div class="loading-status mr-3">
                    <v-progress-circular
                      v-if="loadingStates[order.orderId]"
                      indeterminate
                      size="20"
                      width="2"
                      color="primary"
                    ></v-progress-circular>
                    <v-icon 
                      v-else-if="loadedOrders[order.orderId]" 
                      small 
                      color="success"
                    >
                      mdi-check-circle
                    </v-icon>
                    <v-icon 
                      v-else-if="loadedOrders[order.orderId] === false" 
                      small 
                      color="error"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </div>
                  <v-icon color="error" v-if="isOrderOld(order)">
                    mdi-alert
                  </v-icon>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card outlined class="mb-3">
                  <v-card-text>
                    <h3>Auftragsinformationen</h3>
                    <v-row>
                      <v-col cols="6">
                        <strong>Referenznummer:</strong> {{ orderArticles[order.orderId]?.reference || 'Keine' }}<br>
                        <strong>Status:</strong> 
                        <v-chip small :color="getOrderStatusColor(orderArticles[order.orderId]?.state)">
                          {{ orderArticles[order.orderId]?.state || 'Unbekannt' }}
                        </v-chip><br>
                        <strong>Erstellt am:</strong> {{ formatDate(orderArticles[order.orderId]?.createDate) }}<br>
                        <strong>Alter:</strong> {{ getArticleAge(order) }}
                      </v-col>
                      <v-col cols="6">
                        <div v-if="orderArticles[order.orderId]?.shipping">
                          <strong>Versandart:</strong> {{ orderArticles[order.orderId].shipping.type }}<br>
                          <strong>Spätestes Versanddatum:</strong> 
                          {{ formatDate(orderArticles[order.orderId].shipping.lastShippmentDate) }}
                        </div>
                        <v-btn
                          v-if="isOrderComplete(orderArticles[order.orderId]?.products)"
                          color="success"
                          class="mt-4"
                          :loading="printing"
                          @click="printAndPack(selectedSlot.slot, order.orderId)"
                        >
                          <v-icon left>mdi-printer-check</v-icon>
                          Drucken & Packen
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <v-card outlined v-if="orderArticles[order.orderId]">
                  <v-card-text>
                    <h3>Artikelübersicht</h3>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>SKU</th>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Attribute</th>
                            <th>Aktion</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in orderArticles[order.orderId]?.products" :key="item.sku">
                            <td>{{ item.sku }}</td>
                            <td>{{ item.name || getProductName(item) }}</td>
                            <td>
                              <v-chip x-small :color="getArticleStatus(item).color">
                                {{ getArticleStatus(item).text }}
                              </v-chip>
                            </td>
                            <td>
                              <div v-if="item.attributes" class="attributes-list">
                                <div v-for="(value, key) in item.attributes" :key="key" class="attribute-item">
                                  {{ key }}: <div v-if="value &&value.toString().indexOf('http') > -1"><v-img :src="value" height="200px" width="200px" style="background-color: grey" contain></v-img></div><div v-else>{{ value }}</div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <v-btn 
                                v-if="item.shelf"
                                color="error" 
                                x-small
                                @click="removeArticle(item.shelf.hashcode)"
                              >
                                Entfernen
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Header from '../header';
import moment from 'moment';
import PacklistService from '@/services/PacklistService';
import AuthService from '@/services/AuthService';

export default {
  name: 'MultiCartShelfManager',
  components: {
    Header
  },
  data: () => ({
    slots: {},
    detailDialog: false,
    selectedSlot: null,
    refreshInterval: null,
    orderArticles: {},
    isLoading: false,
    loadingStates: {},
    loadedOrders: {},
    loadingAllOrders: false,
    loadProgress: 0,
    totalOrders: 0,
    loadedOrdersCount: 0,
    showPrinterSelectionDialog: false,
    printers: null,
    printer: null,
    printing: false,
    searchQuery: '',
    searchResults: new Set(),
  }),

  async created() {
    // Prüfe ob User Admin ist
    const user = (await AuthService.getUser()).data;
    if (!user || user.role !== 'admin') {
      this.$router.push('/');
      return;
    }
    
    await this.loadData();
    this.refreshInterval = setInterval(this.loadData, 30000); // Alle 30 Sekunden aktualisieren
    await this.loadPrinters();
  },

  beforeDestroy() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  },

  methods: {
    async loadData() {
      try {
        const response = await PacklistService.getMultiCartShelfStatus();
        this.slots = response.data;
      } catch (error) {
        console.error('Fehler beim Laden der Daten:', error);
      }
    },

    getSlotContent(slotId) {
      return this.slots[slotId] || null;
    },

    searchInSlot(slot, query) {
      if (!slot || !slot.articles || !query) return false;
      
      for (const article of slot.articles) {
        const orderDetails = this.orderArticles[article.orderId];
        if (!orderDetails?.products) continue;

        for (const product of orderDetails.products) {
          if (!product.attributes) continue;

          // Durchsuche alle Attribute
          for (const [key, value] of Object.entries(product.attributes)) {
            if (value && value.toString().toLowerCase().includes(query.toLowerCase())) {
              return true;
            }
          }
        }
      }
      return false;
    },

    getSlotClass(slot) {
      if (!slot || !slot.articles || slot.articles.length === 0) return 'empty-slot';
      
      const slotId = slot.articles[0]?.shelf?.slot;
      // Wenn es ein Suchergebnis ist
      if (this.searchQuery && this.searchInSlot(slot, this.searchQuery)) {
        return 'search-result-slot';
      }
      
      // Rest der bestehenden Logik
      const orderGroups = {};
      slot.articles.forEach(article => {
        if (!orderGroups[article.orderId]) {
          orderGroups[article.orderId] = {
            articles: [],
            orderArticles: this.orderArticles[article.orderId]?.products || []
          };
        }
        orderGroups[article.orderId].articles.push(article);
      });

      const hasCompleteOrder = Object.values(orderGroups).some(group => {
        const allArticlesInShelf = group.orderArticles.every(article => article.shelf);
        return allArticlesInShelf && group.orderArticles.length > 0;
      });

      if (hasCompleteOrder) return 'complete-slot';
      
      const oldestArticle = this.getOldestArticle(slot);
      if (!oldestArticle) return 'empty-slot';

      const hours = moment().diff(moment(oldestArticle.timestamp), 'hours');
      
      if (hours > 36) return 'critical-slot';
      if (hours > 24) return 'warning-slot';
      return 'active-slot';
    },

    getOldestArticle(slot) {
      if (!slot || !slot.articles || slot.articles.length === 0) return null;
      return slot.articles.reduce((oldest, current) => {
        return moment(current.timestamp).isBefore(moment(oldest.timestamp)) ? current : oldest;
      }, slot.articles[0]);
    },

    getSlotAge(slot) {
      if (!slot || !slot.articles || slot.articles.length === 0) return '';
      const oldestArticle = this.getOldestArticle(slot);
      return moment(oldestArticle.timestamp).fromNow();
    },

    getStatusColor(state) {
      const colors = {
        'pending': 'grey',
        'in_progress': 'blue',
        'completed': 'success',
        'error': 'error',
        'waiting': 'orange'
      };
      return colors[state] || 'grey';
    },

    async loadOrderArticles(orderId) {
      try {
        const response = await PacklistService.getPacklistItem(orderId);
        if(response.data){
            this.$set(this.orderArticles, orderId, response.data);
        }
      } catch (error) {
        console.error('Fehler beim Laden der Auftragsdaten:', error);
      }
    },

    async loadOrderData(orderId) {
      this.$set(this.loadingStates, orderId, true);
      try {
        await this.loadOrderArticles(orderId);
        this.$set(this.loadedOrders, orderId, true);
      } catch (error) {
        console.error('Fehler beim Laden der Bestelldaten:', error);
        this.$set(this.loadedOrders, orderId, false);
      } finally {
        this.$set(this.loadingStates, orderId, false);
      }
    },

    formatDate(date) {
      return moment(date).format('DD.MM.YYYY HH:mm:ss');
    },

    getArticleAge(article) {
      return moment(article.timestamp).fromNow();
    },

    isOrderOld(article) {
      const hours = moment().diff(moment(article.timestamp), 'hours');
      return hours > 36;
    },

    async removeArticle(hashcode) {
      try {
        await PacklistService.removeMultiCartArticle(hashcode);
        await this.loadData();
        if (this.selectedSlot) {
          this.selectedSlot.articles = this.selectedSlot.articles.filter(
            a => a.hashcode !== hashcode
          );
        }
      } catch (error) {
        console.error('Fehler beim Entfernen des Artikels:', error);
      }
    },

    getOrderStatusColor(state) {
      const colors = {
        'UNSHIPPED': 'warning',
        'SHIPPED': 'success',
        'CANCELLED': 'error',
        'PENDING': 'grey',
        'IN_PROGRESS': 'info'
      };
      return colors[state] || 'grey';
    },

    hasCustomText(item) {
      return item.translated?.line1 && !item.translated.line1.includes('customily.com');
    },

    getCustomText(item) {
      if (!item.translated) return '';
      const texts = [];
      for (let i = 1; i <= 5; i++) {
        if (item.translated[`line${i}`] && !item.translated[`line${i}`].includes('customily.com')) {
          texts.push(item.translated[`line${i}`]);
        }
      }
      return texts.join(' ');
    },

    getProductName(item) {
      if (!item?.sku) return '';
      
      // Entfernt technische Präfixe und formatiert den SKU-Namen
      const name = item.sku
        .replace(/^LYTO_/, '')
        .replace(/_/g, ' ')
        .toLowerCase()
        .replace(/\b\w/g, l => l.toUpperCase());
      return name;
    },

    findArticlePosition(item) {
      for (const [slotId, slotContent] of Object.entries(this.slots)) {
        const foundArticle = slotContent.articles?.find(a => 
          a.orderId === item.orderId && a.sku === item.sku
        );
        if (foundArticle) return slotId;
      }
      return null;
    },

    getLocationStatusColor(position) {
      return position ? 'success' : 'grey';
    },

    getLocationStatus(position) {
      return position ? `Slot ${position}` : 'Ausstehend';
    },

    getArticleStatus(item) {
      // Wenn der Artikel einen Shelf-Status hat
      if (item.shelf) {
        return {
          text: `Im Regal (${item.shelf.slot})`,
          color: 'success'
        };
      }

      // Status basierend auf dem item.status
      switch(item.status) {
        case 'ready':
          return { text: 'Bereit', color: 'info' };
        case 'missing':
          return { text: 'Fehlend', color: 'error' };
        default:
          return { text: 'Ausstehend', color: 'warning' };
      }
    },

    async showSlotDetails(slotId) {
      this.loadingStates = {};
      this.loadedOrders = {};
      
      const slotContent = this.getSlotContent(slotId);
      
      // Gruppiere Artikel nach OrderId
      const groupedArticles = {};
      slotContent?.articles.forEach(article => {
        if (!groupedArticles[article.orderId]) {
          groupedArticles[article.orderId] = {
            orderId: article.orderId,
            articles: [],
            timestamp: article.timestamp
          };
        }
        groupedArticles[article.orderId].articles.push(article);
      });

      // Sortiere nach Timestamp (älteste zuerst)
      const sortedOrders = Object.values(groupedArticles).sort((a, b) => 
        moment(a.timestamp).diff(moment(b.timestamp))
      );

      this.selectedSlot = {
        slot: slotId,
        orders: sortedOrders
      };
      
      this.detailDialog = true;

      // Lade Details für jede Order
      sortedOrders.forEach(order => {
        if (!this.orderArticles[order.orderId]) {
          this.loadOrderData(order.orderId);
        } else {
          this.$set(this.loadedOrders, order.orderId, true);
        }
      });
    },

    getOrderReadyStatus(articles) {
      if (!articles || articles.length === 0) return { text: 'Keine Artikel', color: 'grey' };
      
      const allReady = articles.every(article => article.status === 'ready');
      const allInShelf = articles.every(article => article.shelf);
      const anyMissing = articles.some(article => article.status === 'missing');
      
      if (allInShelf) return { text: 'Alles im Regal', color: 'success' };
      if (allReady) return { text: 'Alle bereit', color: 'info' };
      if (anyMissing) return { text: 'Fehlende Artikel', color: 'error' };
      return { text: 'In Bearbeitung', color: 'warning' };
    },

    getCachedOrder(orderId) {
      const cached = localStorage.getItem(`order_${orderId}`);
      if (!cached) return null;
      
      const { data, timestamp } = JSON.parse(cached);
      // Prüfe ob Cache älter als 5 Minuten ist
      if (Date.now() - timestamp > 5 * 60 * 1000) {
        localStorage.removeItem(`order_${orderId}`);
        return null;
      }
      
      return data;
    },

    setCachedOrder(orderId, data) {
      localStorage.setItem(`order_${orderId}`, JSON.stringify({
        data,
        timestamp: Date.now()
      }));
    },

    async loadAllOrders() {
      this.loadingAllOrders = true;
      this.loadProgress = 0;
      this.loadedOrdersCount = 0;
      
      // Sammle alle einzigartigen OrderIds
      const orderIds = new Set();
      Object.values(this.slots).forEach(slot => {
        slot.articles.forEach(article => {
          orderIds.add(article.orderId);
        });
      });
      
      this.totalOrders = orderIds.size;
      
      // Lade alle Orders parallel mit max 5 gleichzeitig
      const chunks = Array.from(orderIds).reduce((acc, curr, i) => {
        const chunkIndex = Math.floor(i / 5);
        if (!acc[chunkIndex]) acc[chunkIndex] = [];
        acc[chunkIndex].push(curr);
        return acc;
      }, []);
      
      for (const chunk of chunks) {
        await Promise.all(chunk.map(async orderId => {
          try {
            const cached = this.getCachedOrder(orderId);
            if (cached) {
              this.$set(this.orderArticles, orderId, cached);
              this.$set(this.loadedOrders, orderId, true);
            } else {
              const response = await PacklistService.getPacklistItem(orderId);
              if (response.data) {
                this.$set(this.orderArticles, orderId, response.data);
                this.$set(this.loadedOrders, orderId, true);
                this.setCachedOrder(orderId, response.data);
              }
            }
          } catch (error) {
            console.error(`Fehler beim Laden der Order ${orderId}:`, error);
            this.$set(this.loadedOrders, orderId, false);
          }
          this.loadedOrdersCount++;
          this.loadProgress = (this.loadedOrdersCount / this.totalOrders) * 100;
        }));
      }
      
      this.loadingAllOrders = false;
    },

    async loadPrinters() {
      try {
        const response = await PacklistService.getPrinters();
        this.printers = response.data;
        this.printer = this.$cookies.get("printer");
      } catch (error) {
        console.error('Fehler beim Laden der Drucker:', error);
      }
    },

    savePrinter() {
      this.$cookies.set("printer", this.printer);
    },

    async printAndPack(slotId, specificOrderId = null) {
      const printerUrl = this.$cookies.get("printer");
      if (!printerUrl) {
        this.showPrinterSelectionDialog = true;
        return;
      }

      try {
        this.printing = true;
        const slot = this.getSlotContent(slotId);
        if (!slot) return;

        // Gruppiere nach Orders
        const orderGroups = {};
        slot.articles.forEach(article => {
          if (!orderGroups[article.orderId]) {
            orderGroups[article.orderId] = [];
          }
          orderGroups[article.orderId].push(article);
        });

        // Verarbeite entweder eine spezifische Order oder die erste komplette Order
        const processOrder = async (orderId, articles) => {
          const orderDetails = this.orderArticles[orderId];
          if (!orderDetails) return false;

          const allArticlesInShelf = orderDetails.products.every(article => article.status === 'ready');
          if (allArticlesInShelf) {
            let packlistItemId = (await PacklistService.findPacklistItem(orderId)).data.id;
            await PacklistService.trackStart(packlistItemId);
            await PacklistService.print(orderId, printerUrl, false);
            await PacklistService.trackEnd(packlistItemId);
            
            for (const article of articles) {
              if (article.shelf?.hashcode) {
                await PacklistService.removeMultiCartArticle(article.shelf.hashcode);
              }
            }
            return true;
          }
          return false;
        };

        if (specificOrderId) {
          // Verarbeite nur die spezifische Order
          await processOrder(specificOrderId, orderGroups[specificOrderId]);
        } else {
          // Finde und verarbeite die erste komplette Order
          for (const [orderId, articles] of Object.entries(orderGroups)) {
            if (await processOrder(orderId, articles)) {
              break;
            }
          }
        }

        await this.loadData();
        if (this.detailDialog) {
          await this.showSlotDetails(slotId); // Aktualisiere die Detailansicht
        }
      } catch (error) {
        console.error('Fehler beim Drucken und Packen:', error);
      } finally {
        this.printing = false;
      }
    },

    isOrderComplete(products) {
      if (!products || products.length === 0) return false;
      return products.every(article => article.status === 'ready');
    }
  },

  computed: {
    progressColor() {
      if (this.loadProgress < 30) return 'error';
      if (this.loadProgress < 70) return 'warning';
      return 'success';
    },
  },

  watch: {
    searchQuery(newQuery) {
      this.searchResults.clear();
      if (newQuery) {
        Object.entries(this.slots).forEach(([slotId, slot]) => {
          if (this.searchInSlot(slot, newQuery)) {
            this.searchResults.add(slotId);
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.shelf-row {
  margin-bottom: 12px;
  background: #f8f9fa;
  border-radius: 8px;
  padding: 8px;
}

.column-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  font-weight: bold;
  color: #2c3e50;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  height: 120px;
}

.slot-card {
  height: 120px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  margin: 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.slot-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

.slot-title {
  font-size: 18px;
  padding: 8px;
  font-weight: bold;
  background: rgba(255,255,255,0.9);
  border-radius: 8px 8px 0 0;
}

.order-count {
  font-size: 16px;
  font-weight: 600;
  margin-top: auto;
}

.order-age {
  font-size: 13px;
  color: #666;
  margin-bottom: 8px;
}

.empty-slot {
  background-color: #f8f9fa;
  border: 2px dashed #dee2e6;
}

.active-slot {
  background-color: #e3f2fd;
  border: 2px solid #90caf9;
}

.warning-slot {
  background-color: #fff3e0;
  border: 2px solid #ffb74d;
}

.critical-slot {
  background-color: #ffebee;
  border: 2px solid #ef5350;
}

.complete-slot {
  background: linear-gradient(135deg, #4CAF50, #81c784);
  border: none;
  color: white;
}

.complete-slot .slot-title,
.complete-slot .order-count,
.complete-slot .order-age {
  color: white;
  background: rgba(0,0,0,0.1);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
  }
}

.complete-slot {
  animation: pulse 2s infinite;
}

/* Verringere den Abstand zwischen den Cards */
.v-row {
  margin: 0 -2px;
}

.v-col {
  padding: 2px;
}

/* Dialog Styles */
.detail-dialog {
  border-radius: 12px;
}

.headline {
  font-size: 24px !important;
  padding: 20px 24px !important;
  background: #f8f9fa;
  border-radius: 12px 12px 0 0;
}

::v-deep .v-expansion-panel {
  margin-bottom: 8px;
  border-radius: 8px !important;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1) !important;
}

::v-deep .v-expansion-panel-header {
  font-size: 16px;
  padding: 16px 24px;
  background: #f8f9fa;
}

::v-deep .v-expansion-panel-content {
  font-size: 14px;
  padding: 20px;
}

::v-deep .v-data-table {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

::v-deep .v-data-table th {
  font-size: 15px;
  font-weight: bold;
  background: #f8f9fa !important;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.attributes-list {
  font-size: 1.25rem;
  color: rgba(0, 0, 0, 0.87);
}

.attribute-item {
  margin-bottom: 8px;
  padding: 8px;
  background: #f8f9fa;
  border-radius: 4px;
}

.search-result-slot {
  background: linear-gradient(135deg, #9c27b0, #ba68c8);
  border: none;
  color: white;
  animation: searchPulse 2s infinite;
}

.search-result-slot .slot-title,
.search-result-slot .order-count,
.search-result-slot .order-age {
  color: white;
  background: rgba(0,0,0,0.1);
}

@keyframes searchPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(156, 39, 176, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(156, 39, 176, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(156, 39, 176, 0);
  }
}
</style> 