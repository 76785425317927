<template>
  <div>
    <Header />
    <v-container style="max-width: 100%">
      <v-row v-if="error">
        <v-col cols="12">
          <v-alert type="error">{{ error }}</v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          
          <v-card>
            <v-card-title>Aktive Prime Artikel</v-card-title>
            <v-card-text>
             
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          
          <v-card>
            <v-card-title>Temporär Off-Prime Artikel</v-card-title>
            <v-card-text>
             
            </v-card-text>
          </v-card>
        </v-col>
      
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Header from "../header";
import service from "@/services/ProductionService";

import EventBus from "@/event-bus";

export default {
  name: "dashboard",
  components: {
    Header,
  },
  data: () => ({
    loading: false,
  }),
  create() {},
  async mounted() {
    this.reload();
  },
  methods: {
    async reload() {
      this.loading = true;


      this.loading = false;
    },

    openAddDialog() {
      EventBus.$emit("ADD_ARTOWRK");
    }
  },
};
</script>

<style scoped>
.v-card,
.v-btn {
  margin-bottom: 10px;
}
</style>