
<template>
  <div>
    <Header />
    <v-container grid-list-xs style="max-width: 100%">

      <v-row>
        <v-col cols="12">
           <v-switch v-model="filterInactiveStock" label="Inaktiver Warenbestand ausblenden"></v-switch>

        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col v-for="item in filterItems()" :key="item.id" cols="2">
          <v-container style="border: 1px solid #00000055">
            <v-row height="50px">
              <v-col cols="5">
                <v-img :src="item.image" contain height="80px"></v-img>
              </v-col>
              <v-col cols="7" style="border-left: 1px solid #00000055">
                <span
                  class="subtitle-1"
                  style="overflow: hidden; word-wrap: break-word; text-overflow: ellipsis;}"
                  >{{ item.sku }}</span
                >
              </v-col>
            </v-row>
            <v-row height="50px" style="border-top: 1px solid #00000055">
              <v-col
                cols="4"
                class="stockKPI"
                :style="getStockDurationColor(item['7days'], item.netStock, item.orderDelay)"
                >{{ item.phyStock || 0 }} <br />
                <span class="label">Phy</span>
              </v-col>
              <v-col cols="4" class="stockKPI"
              :style="getStockDurationColor(item['7days'], item.netStock, item.orderDelay)"
                >{{ item.netStock || 0 }} <br />
                <span class="label">Net</span>
              </v-col>
              <v-col cols="4" class="stockKPI"
              :style="getStockDurationColor(item['7days'], item.netStock, item.orderDelay)"
                >{{ item["7days"] || 0 }} <br />
                <span class="label">7Tage Verk.</span>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Header from "./header";
import service from "@/services/ProductionService";
import router from "@/router";

const colorAlert = [211, 47, 47];

var percentColors = [
  { pct: 0.0, color: { r: 211, g: 47, b: 47 } },
  { pct: 0.5, color: { r: 255, g: 235, b: 59 } },
  { pct: 1.0, color: { r: 76, g: 175, b: 80 } },
];

export default {
  name: "dashboard",
  components: {
    Header,
  },
  data: () => ({
    timer: null,
    loading: true,
    filterInactiveStock: true,
    items: [],
  }),
  create() {},
  async mounted() {
    this.refresh();
    this.timer = setInterval(this.refresh, 1000 * 10); //five minues refreshments
  },
  methods: {

    filterItems() {
      if(this.filterInactiveStock) {
        return this.items.filter(x => x.netStock != 0 && x.phyStock != 0) //Dead Stock
      } else {
        return this.items;
      }
    },


    getStockDurationColor(sevenDayOrders, val, orderDelay) {

      if((!sevenDayOrders || sevenDayOrders == 0) && (!val || val == 0)) return "background: rgb(0,0,0)"; 
      
      let avgOrdersPerDay = sevenDayOrders / 7;
      let max = (sevenDayOrders * 4) + (avgOrdersPerDay * orderDelay);
      let pct = val / max;

      if(isFinite(pct) == false || pct > 1 ) pct = 1

      for (var i = 1; i < percentColors.length - 1; i++) {
        if (pct < percentColors[i].pct) {
          break;
        }
      }
      var lower = percentColors[i - 1];
      var upper = percentColors[i];
      var range = upper.pct - lower.pct;
      var rangePct = (pct - lower.pct) / range;
      var pctLower = 1 - rangePct;
      var pctUpper = rangePct;
      var color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
      };
      return "background: rgb(" + [color.r, color.g, color.b].join(",") + ")";
      // or output as hex if preferred
    },

    async refresh() {
      this.loading = true;
      try {
        let data = (await service.getWarehouse()).data;
        this.items = data
          .filter((x) => x.sku)
          .map( x => {
            if(!isFinite(x['7days']))  x['7days'] = 0;



            let avgOrdersPerDay = x['7days'] / 7;
            console.log( "SKU: " + x.sku + " 7days: " + x['7days'] + " avg: " + avgOrdersPerDay + " orderDelay: " + x.orderDelay);


            x.pct = x.netStock / ((x['7days'] * 4) + x.orderDelay * avgOrdersPerDay )
            if(isFinite(x.pct) == false || x.pct > 1 ) x.pct = 1
            return x;
          })
          .sort((a, b) => (a.pct < b.pct ? -1 : 1))
          .sort( (a,b) =>  ((a['7days'] == 0 ? -1 : 1)));

        console.log(this.items);
        this.loading = false;
      } catch (err) {
        if (err.response.status == 401) {
          router.replace("/auth/login");
        }
      }
    },
  },
  breforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style>
.row {
  margin: 0px !important;
}
.stockKPI {
  text-align: center;
  background: #424242;

  color: white;
  font-weight: bold;
}

.label {
  font-size: 0.5em;
}
</style>
