import Api from '@/services/Api'

export default {
  getInventoryByDate(date) {
    return Api().get('inventory?date=' + date.toString())
  },

  getHistoryByVariationId(variationId) {
    return Api().get('inventory/history/' + variationId)
  }
}