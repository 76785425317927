<template>
  <div>
    <Header />
    <v-alert :value="error" type="error">{{ error }}</v-alert>
    <v-container>
      <v-row>
        <v-col cols="12"
          ><h1 style="text-align: center">Fehlproduktionen</h1></v-col
        >
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <!-- SEARCH BAR-->
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Suche"
                single-line
                @keyup.enter="doSearch"
                hide-details></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- Data table with search results, fields (sku, name, varId, itemId, isMain, isActive)-->

            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="items"
                :items-per-page="50"
                class="elevation-1"
              >
              <!-- template image -->
                <template v-slot:item.image="{ item }">
                  <v-img :src="item.image" max-width="100px" max-height="100px"></v-img>
                </template>
                <!-- template sku -->
                <template v-slot:item.sku="{ item }">
                  {{ item.sku != null ? Array.isArray(item.sku) ? item.sku.join(", ") : item.sku : "" }}
                </template>
                <!-- template name -->
                <template v-slot:item.name="{ item }">
                  {{ item.name }}
                </template>
                <!-- template varId -->
                <template v-slot:item.varId="{ item }">
                  {{ item.varId }}
                </template>
                <!-- template itemId -->
                <template v-slot:item.itemId="{ item }">
                  {{ item.itemId }}
                </template>
                <!-- template isMain -->
                <template v-slot:item.isMain="{ item }">
                  <v-checkbox
                    v-model="item.isMain"
                    :disabled="true"
                    color="primary"></v-checkbox>
                </template>
                <!-- template isActive -->
                <template v-slot:item.isActive="{ item }">
                  <!-- v-checkbox disabled -->
                  <v-checkbox
                    v-model="item.isActive"
                    :disabled="true"
                    color="primary"></v-checkbox>
                </template>

                <template v-slot:item.stock="{item}">
                  {{ item.stock != null ? item.stock.phy : "" }}
                </template>

                <!-- template button -->
                <template v-slot:item.action="{ item }">
                  <v-btn
                    color="primary"
                    @click="selectedItem = item; selectedItemDialog = true"
                    >Fehlproduktion anlegen</v-btn
                  >
                </template>
              </v-data-table>
              </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-card height="100%" min-height="1000px">
            <v-card-title>Angelegte Fehlproduktionen</v-card-title>

            <v-data-table
              :headers="historyTableHeader"
              :items="history"
              :items-per-page="50"
              class="elevation-1"
            >
              <template v-slot:item.date="{ item }">
                {{ item.date | moment("DD.MM.yyyy HH:mm") }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog max-width="300" v-model="loading" v-if="loading" persistent>
      <v-card>
        <v-card-title class="headline">Einen Moment bitte</v-card-title>
        <v-card-text>
          <center>
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </center>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog max-width="900" v-if="selectedItem" v-model="selectedItemDialog">
      <v-card v-if="selectedItem">
        <v-card-title class="headline">Fehlproduktion Buchen?</v-card-title>

        <v-card-text>
          <h4>{{ selectedItem.name }}</h4>
          <br />

          <v-select
            v-model="damagedItem.reason"
            outlined
            :items="reasons"
          ></v-select>

          <v-text-field
            outlined
            number
            placeholder="Anzahl"
            v-model="damagedItem.qty"
          ></v-text-field>

          <v-text-field
            outlined
            v-model="damagedItem.ownReason"
            v-if="damagedItem.reason == 'Eigener Grund'"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="success" @click="selectedItemDialog = false" text
            >Abbrechen</v-btn
          >

          <v-btn color="success" text @click="createDamagedItem()"
            >Anlegen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="400">
      <v-card color="success" dark>
        <v-card-title class="headline">Packliste wurde erstellt!</v-card-title>

        <v-card-text>Deine Packliste wurde erstellt</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text>Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Header from "./header";

import service from "../../services/ProductionService.js";

export default {
  name: "damaged",
  components: {
    Header,
  },
  data: () => ({
    loading: true,
    percent: 0,
    items: [],
    search: null,
    damagedItem: {
      sku: "",
      reason: "",
      qty: 1,
    },
    selectedItem: null,
    selectedItemDialog: false,
    history: [],
    historyTableHeader: [
      { text: "Datum", value: "date" },
      { text: "sku", value: "sku" },
      { text: "reason", value: "reason" },
    ],

    headers: [
      { text: "Bild", value: "image" },
      { text: "SKU", value: "sku" },
      { text: "Name", value: "name" },
      { text: "Aktiv", value: "isActive" },
      { text: "Hauptvariante", value: "isMain" },
      { text: "varId", value: "variationId" },
      { text: "itemId", value: "itemId" },
      { text: "Bestand", value: "stock" },
      { text: "Aktion", value: "action" },
    ],
      

    reasons: [
      "Eigener Grund",
      "Schräggravur",
      "Importierungsfehler",
      "Doppelt graviert",
      "Schablone nicht genau",
      "Nicht gravierte Zeichen",
      "falsches Produkt verwendet",
      "Stornierung nach Gravur",
      "Gravieren undeutlich",
      "Produkt beschädigt",
      "Muster",
      "Achse nicht eingestellt / hat sich verfahren",
      "Cobot spannt nicht richtig",
      "Zwei Gravuren auf einem Produkt",
      "Fehler bei manueller Gravur",
      "Rechts- / Linksgravur falsch",
      "Ersatzteil wurde benötigt"
    ],

    error: null,
  }),
  create() {},
  async mounted() {
    this.refreshHistory();
  },

  computed: {},

  methods: {
   

    async refreshHistory() {
      this.loading = true;
      this.history = (await service.getDamagedHistory2()).data.reverse();
      this.loading = false;
    },

    async doSearch() {
      this.loading = true;
      this.items = (await service.searchWarehouseStock(this.search)).data;
      this.loading = false;
    },

    async createDamagedItem() {
      this.loading = true;
      

      if (this.damagedItem.reason == "Eigener Grund") {
        this.damagedItem.reason = this.damagedItem.ownReason;
      }
      try {

        let payload = this.selectedItem;
        
        payload.qty = this.damagedItem.qty;
        payload.reason = this.damagedItem.reason;
        payload.sku = this.selectedItem.sku != null ? this.selectedItem.sku.toString() + " / VARID: " +  this.selectedItem.variationId : "VARID: " +  this.selectedItem.variationId

        let response = await service.createDamagedItem2(payload);
      } catch(error) {
        this.error = error.response.data;
        this.loading = false;
        return;
      }
      
      await this.refreshHistory();

      this.loading = false;
      this.damagedItem = { qty: 1 };
      this.selectedItem = {};
      this.selectedItemDialog = false;
    },
  },
};
</script>

<style>
</style>