import axios from 'axios'

const apiKey = "2AE19CFC0C224B1D6585A4A899A565C6DE93BD5EFD97E7ECB0D797019822CD36";

export default () => {
    return axios.create({
      baseURL: `http://backoffice.accelery.host/api/`,
      headers: {
        "X-Api-Key": apiKey
      },
      timeout: 1000 * 190,
      withCredentials: true,
    })
  }