<template>
  <div>
    <Header />
    
    <!-- Error/Success Alerts -->
    <v-alert v-model="alerts.error" type="error" dismissible>
      {{ alerts.error }}
    </v-alert>
    <v-alert v-model="alerts.success" type="success" dismissible>
      {{ alerts.success }}
    </v-alert>

    <v-container fluid>
      <!-- Header -->
      <v-row>
        <v-col cols="12">
          <h1>Importer Dashboard</h1>
        </v-col>
      </v-row>

      <!-- Importer Status Cards -->
      <v-row>
        <v-col cols="12" md="6" v-for="importer in importers" :key="importer.value">
          <v-card class="mb-4">
            <v-card-title class="primary white--text">
              {{ importer.text }}
              <v-spacer></v-spacer>
              <v-btn icon color="white" @click="refreshImporter(importer.value)">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </v-card-title>
            
            <!-- Status Grid -->
            <v-card-text v-if="healthData[importer.value]">
              <v-row>
                <!-- System Status -->
                <v-col cols="6" md="3">
                  <v-card flat>
                    <v-card-text class="text-center">
                      <v-icon :color="healthData[importer.value].status === 'ready' ? 'success' : 'error'" x-large>
                        {{ healthData[importer.value].status === 'ready' ? 'mdi-check-circle' : 'mdi-alert' }}
                      </v-icon>
                      <div class="caption">System Status</div>
                      <div class="subtitle-1">{{ healthData[importer.value].status === 'ready' ? 'Bereit' : 'Nicht bereit' }}</div>
                    </v-card-text>
                  </v-card>
                </v-col>

                <!-- Verarbeitete Bestellungen -->
                <v-col cols="6" md="3">
                  <v-card flat>
                    <v-card-text class="text-center">
                      <div class="display-1">{{ healthData[importer.value].metrics?.ordersProcessed || 0 }}</div>
                      <div class="caption">Verarbeitete Bestellungen</div>
                    </v-card-text>
                  </v-card>
                </v-col>

                <!-- Fehler -->
                <v-col cols="6" md="3">
                  <v-card flat>
                    <v-card-text class="text-center">
                      <div class="display-1" :class="{'error--text': healthData[importer.value].metrics?.errors > 0}">
                        {{ healthData[importer.value].metrics?.errors || 0 }}
                      </div>
                      <div class="caption">Fehler</div>
                    </v-card-text>
                  </v-card>
                </v-col>

                <!-- Verarbeitungszeit -->
                <v-col cols="6" md="3">
                  <v-card flat>
                    <v-card-text class="text-center">
                      <div class="subtitle-1">{{ formatTime(healthData[importer.value].metrics?.avgProcessingTime) }}</div>
                      <div class="caption">Ø Verarbeitungszeit</div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <!-- Verbindungsstatus -->
              <v-divider class="my-4"></v-divider>
              <div class="d-flex flex-wrap">
                <v-chip
                  v-for="(status, service) in healthData[importer.value].connections"
                  :key="service"
                  :color="status ? 'success' : 'error'"
                  class="ma-1"
                  small
                >
                  <v-icon left small>{{ status ? 'mdi-check' : 'mdi-close' }}</v-icon>
                  {{ service }}
                </v-chip>
              </div>

              <!-- Letzte Aktivitäten -->
              <v-divider class="my-4"></v-divider>
              <div class="caption grey--text">Letzte Aktivitäten</div>
              <v-timeline dense class="mt-2">
                <v-timeline-item
                  v-for="order in recentOrders[importer.value]"
                  :key="order.orderId"
                  :color="order.imported ? 'success' : 'grey'"
                  small
                >
                  <div class="d-flex justify-space-between">
                    <div>
                      <strong>{{ order.orderId }}</strong>
                      <div class="caption">{{ order.imported == undefined ? 'Importiert' : (order.imported ? 'Importiert' : 'Ausstehend') }}</div>
                    </div>
                    <div class="caption">{{ formatDate(order.createDate || order.date) }}</div>
                  </div>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>

            <!-- Loading State -->
            <v-card-text v-else class="text-center py-5">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Header from "../header";
import ImporterService from "../../../services/ImporterService";
import { format, parseISO } from 'date-fns';
import { de } from 'date-fns/locale';

export default {
  name: "ImporterMonitor",
  
  components: {
    Header
  },

  data: () => ({
    importers: [
      { text: 'Muckelmeister', value: 'muckelmeister' },
      { text: 'ACS', value: 'acs' }
    ],
    healthData: {},
    ordersData: {},
    healthCheckIntervals: {},
    orderCheckIntervals: {},
    alerts: {
      error: null,
      success: null
    },
    UPDATE_INTERVAL: 60000,
    HEALTH_CHECK_INTERVAL: 30000
  }),

  computed: {
    recentOrders() {
      const recent = {};
      for (const importer of this.importers) {
        recent[importer.value] = (this.ordersData[importer.value] || [])
          .sort((a, b) => {
            // Hier wird das Datum direkt als ISO String geparsed
            const dateA = new Date(a.createDate || a.date);
            const dateB = new Date(b.createDate || b.date);
            return dateB.getTime() - dateA.getTime();
          })
          .slice(0, 50);
      }
      return recent;
    }
  },

  async mounted() {
    await this.initializeAll();
    this.startAllChecks();
  },

  beforeDestroy() {
    this.stopAllChecks();
  },

  methods: {
    async initializeAll() {
      for (const importer of this.importers) {
        await this.initializeImporter(importer.value);
      }
    },

    startAllChecks() {
      this.importers.forEach(importer => {
        this.healthCheckIntervals[importer.value] = setInterval(() => {
          this.checkHealth(importer.value);
        }, this.HEALTH_CHECK_INTERVAL);

        this.orderCheckIntervals[importer.value] = setInterval(() => {
          this.updateOrders(importer.value);
        }, this.UPDATE_INTERVAL);
      });
    },

    stopAllChecks() {
      Object.values(this.healthCheckIntervals).forEach(clearInterval);
      Object.values(this.orderCheckIntervals).forEach(clearInterval);
    },

    async updateOrders(importerName) {
      try {
        const orders = await ImporterService.getOrders(importerName);
        this.$set(this.ordersData, importerName, orders.data);
      } catch (error) {
        console.error(`Failed to update orders for ${importerName}:`, error);
      }
    },

    parseSafeDate(dateString) {
      if (!dateString) return new Date(0);
      
      try {
        // Direkte Konvertierung des ISO Strings zu Date
        return new Date(dateString);
      } catch (error) {
        console.warn('Invalid date:', dateString);
        return new Date(0);
      }
    },

    formatDate(date) {
      try {
        if (!date) return '-';
        
        const parsedDate = new Date(date);
        
        if (isNaN(parsedDate.getTime())) {
          console.warn('Invalid date:', date);
          return '-';
        }

        return format(parsedDate, 'dd.MM.yyyy HH:mm', { locale: de });
      } catch (error) {
        console.error('Date formatting error:', error, date);
        return '-';
      }
    },

    formatTime(ms) {
      if (!ms) return '0ms';
      return `${Math.round(ms)}ms`;
    },

    showError(message) {
      this.alerts.error = message;
      setTimeout(() => {
        this.alerts.error = null;
      }, 5000);
    },

    showSuccess(message) {
      this.alerts.success = message;
      setTimeout(() => {
        this.alerts.success = null;
      }, 3000);
    },

    async refreshImporter(importerName) {
      try {
        await Promise.all([
          this.checkHealth(importerName),
          this.updateOrders(importerName)
        ]);
        this.showSuccess(`${importerName} erfolgreich aktualisiert`);
      } catch (error) {
        this.showError(`Fehler beim Aktualisieren von ${importerName}: ${error.message}`);
      }
    },

    async initializeImporter(importerName) {
        try {
            const [health, orders] = await Promise.all([
                ImporterService.getHealth(importerName),
                ImporterService.getOrders(importerName)
            ]);
            
            this.$set(this.healthData, importerName, health.data);
            this.$set(this.ordersData, importerName, orders.data);
            
            this.log(`${importerName} Importer initialisiert`);
        } catch (error) {
            console.error(`Fehler beim Initialisieren von ${importerName}:`, error);
            this.showError(`Fehler beim Laden von ${importerName}: ${error.message}`);
        }
    },

    async checkHealth(importerName) {
        try {
            const response = await ImporterService.getHealth(importerName);
            this.$set(this.healthData, importerName, response.data);
        } catch (error) {
            console.error(`Health check failed for ${importerName}:`, error);
        }
    },

    log(message) {
        console.log(`[ImporterMonitor] ${message}`);
    }
  }
};
</script>

<style scoped>
.v-card {
  transition: all 0.3s ease-in-out;
}

.v-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.v-timeline {
  max-height: 300px;
  overflow-y: auto;
}

.date-display {
  font-family: monospace;
  white-space: nowrap;
}
</style>