<template>
  <div>
    <Header />
    <v-container style="max-width: 100%">
      <v-row>
        <v-col cols="12">
          <v-card-text>
            <h2>DHL Paketübergabeliste Erzeugen</h2>
            <p>
              Gebe ein Start und Endzeitpunkt ein, in welchem Zeitraum die
              Etiketten gedruckt wurden.
            </p>
            <br />

            <v-row>
              <v-col cols="6">
                <v-menu
                  ref="fromMenu"
                  v-model="fromMenu"
                  :close-on-content-click="false"
                  :return-value.sync="fromDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fromDate"
                      label="Von- Datum"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="fromDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="fromMenu = false">
                      Abbrechen
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.fromMenu.save(fromDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>

                <v-menu
                  ref="fromTimeMenu"
                  v-model="fromTimeMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="fromTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fromTime"
                      label="Von- Uhrzeit"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="fromTimeMenu"
                    v-model="fromTime"
                    format="24hr"
                    full-width
                    @click:minute="$refs.fromTimeMenu.save(fromTime)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu
                  ref="toMenu"
                  v-model="toMenu"
                  :close-on-content-click="false"
                  :return-value.sync="toDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="toDate"
                      label="Bis- Datum"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="toDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="toMenu = false">
                      Abbrechen
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.toMenu.save(toDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <v-menu
                  ref="toTimeMenu"
                  v-model="toTimeMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="toTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="toTime"
                      label="Bis- Uhrzeit"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="toTimeMenu"
                    v-model="toTime"
                    full-width
                    format="24hr"
                    @click:minute="$refs.toTimeMenu.save(toTime)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            color="primary"
            elevation="2"
            large
            x-large
            @click="createPDF()"
            >Paketübergabeliste erzeugen</v-btn
          >

          <center v-if="loading">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </center>
        </v-col>
        <v-col cols="12" v-if="error">
          <v-alert type="error">{{ error }}</v-alert>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Header from "./header";
import service from "@/services/ProductionService";

import fileDownload from "js-file-download";

export default {
  name: "dashboard",
  components: {
    Header,
  },
  data: () => ({
    loading: false,
    fromDate: new Date(Date.now() - 24 * 60 * 60 * 1000)
      .toISOString()
      .substr(0, 10),
    toDate: new Date(Date.now()).toISOString().substr(0, 10),
    fromTime: null,
    toTime: null,
    fromMenu: false,
    toMenu: false,
    toTimeMenu: false,
    fromTimeMenu: false,
  }),
  create() {},
  async mounted() {},
  methods: {
    async createPDF() {
      this.loading = true;

      let from = new Date(this.fromDate + " " + this.fromTime);
      let to = new Date(this.toDate + " " + this.toTime);
      try {
        let response = (await service.createPackageList(from, to)).data;

        let file = atob(response);
        const byteNumbers = new Array(file.length);
        for (let i = 0; i < file.length; i++) {
          byteNumbers[i] = file.charCodeAt(i);
        }
        let fileArray = new Uint8Array(byteNumbers);
        let blobfile = new Blob([fileArray], { type: "buffer" });

        fileDownload(
          blobfile,
          "Paketübergabe-liste" + new Date().toString() + ".pdf",
          "application/pdf"
        );
      } catch (err) {
        console.error(err);
        this.error = "Es konnte keine Liste erzeugt werden!";
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.v-card,
.v-btn {
  margin-bottom: 10px;
}
</style>