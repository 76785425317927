<template>
  <div>
    <Header />
    <v-alert :value="error" type="error">{{ error }}</v-alert>
    <v-container>
      <v-row>
        <v-col cols="12"><h1 style="text-align: center">Retouren</h1></v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-card>
            <v-card-title>Retoure anlegen</v-card-title>
            <v-card-text>
              <form>
                <v-select
                  v-model="retoureItem.type"
                  :items="types"
                  label="Typ"
                  required
                  outlined
                ></v-select>
                <v-select
                  v-model="retoureItem.carrier"
                  :items="carrier"
                  label="Versandart"
                  required
                  outlined
                ></v-select>
                <v-text-field
                  v-model="retoureItem.orderId"
                  label="Bestellnummer"
                  required
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="retoureItem.sku"
                  label="SKU"
                  required
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="retoureItem.RMA"
                  label="RMA Nummer"
                  v-if="retoureItem.type == 'Retoure'"
                  required
                  outlined
                ></v-text-field>

                <v-select
                  v-model="retoureItem.reason"
                  :items="reasons"
                  label="Grund"
                  required
                  outlined
                ></v-select>

                <v-btn
                  x-large
                  color="success"
                  dark
                  width="100%"
                  @click="createRetoure"
                  :loading="loading"
                >
                  <v-icon class="mr-5">save</v-icon>
                  Speichern</v-btn
                >
              </form>
            </v-card-text>
          </v-card>
          <v-card class="mt-5">
            <v-card-title>Paket suchen</v-card-title>
            <v-card-text>
              <v-text-field
                v-model="searchTerm"
                label="z.B. Vorname Nachname"
                required
                outlined
                @keydown.enter="search"
              ></v-text-field>

              <v-row>
                <v-col
                  cols="12"
                  v-for="order of searchResults"
                  :key="order._id"
                >
                  <v-card @click="selectSearchItem(order)">
                    <v-card-text>
                      <span>
                        <strong
                          >{{ order.customer.firstName }}
                          {{ order.customer.lastName }} </strong
                        ><br />
                        {{ order.customer.adress }} <br />
                        {{ order.customer.zip }}
                        {{ order.customer.city }}
                      </span>
                      <p v-for="product of order.products">
                        <br />
                        <strong>{{ product.sku }}</strong>
                        <div v-if="product && product.attributes">
                        {{ product.attributes.Text }}
                        {{ product.attributes["Text über dem Motiv"] }}
                        {{ product.attributes.Wunschname }}
                        {{ product.attributes.Wunschgravur }}
                        {{ product.attributes.Wunschtext }}
                        </div>
                      </p>

                      <p>
                        <i>{{ order.orderId }}</i>
                      </p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-text-field
            v-model="searchRetoure"
            append-icon="mdi-magnify"
            label="Suche"
            single-line
            hide-details
          ></v-text-field>
          <v-data-table
            :headers="historyTableHeader"
            :items="history"
            :items-per-page="150"
            :search="searchRetoure"
            class="elevation-1"
          >
            <template v-slot:item.date="{ item }">
              {{ item.date | moment("DD.MM.yyyy HH:mm") }}
            </template>
            <template v-slot:item.dhl="{ item }">
              <v-btn
                color="#FFCC00"
                @click="loadDHLDialog(item.orderId)"
                style="color: #d40511; font-weight: bold"
                >DHL</v-btn
              >
            </template>
            <template v-slot:item.trash="{ item }">
              <v-btn
                icon
                v-if="item.trash != true"
                @click="loadTrashDialog(item.orderId)"
                ><v-icon>delete</v-icon></v-btn
              >
              <v-icon v-if="item.trash == true">check</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row>
        <v-dialog v-model="dhlDialog" persistent max-width="500">
          <v-card :loading="loading">
            <v-card-title class="headline"> DHL Label erzeugen </v-card-title>
            <v-card-text>
              <v-form ref="form">
                <v-text-field v-model="dhlData.name1" label="Name1" />
                <v-text-field v-model="dhlData.name2" label="Name2" />
                <v-text-field v-model="dhlData.street" label="Strasse" />
                <v-text-field
                  v-model="dhlData.streetNumber"
                  label="Hausnummer"
                />
                <v-text-field
                  v-model="dhlData.addition"
                  label="Zusätzliche Infos"
                />
                <v-text-field v-model="dhlData.zip" label="PLZ" />
                <v-text-field v-model="dhlData.city" label="Stadt" />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="dhlDialog = false">
                Abbrechen
              </v-btn>
              <v-btn color="green darken-1" text @click="createDHLLabel()">
                Erzeugen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row>
        <v-dialog v-model="trashDialog" persistent max-width="800">
          <v-card :loading="loading">
            <v-card-title class="headline"> Retoure Entsorgen </v-card-title>
            <v-card-text>
              <p>
                Wähle die Artikel aus, welche in das Entsorgungslager gebucht
                werden sollen. Diese Artikel werden einmal pro Jahr entsorgt.
                Für die ordnungsgemäße Entsorgung wird ein Warenbestand im
                Entsorgungslager in PlentyMarkets gepflegt.
              </p>

              <h2>Inhalt der Bestellung</h2>

              <v-list subheader two-line flat>
                <v-subheader>{{ trashData.orderId }}</v-subheader>

                <v-list-item-group multiple>
                  <v-list-item
                    v-for="item of trashData.orderItems"
                    :key="item.id"
                  >
                    <v-list-item-action>
                      <v-checkbox
                        v-model="item.trash"
                        color="primary"
                      ></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                      <v-list-item-subtitle
                        >Menge: {{ item.qty }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="trashDialog = false">
                Abbrechen
              </v-btn>
              <v-btn color="green darken-1" text @click="applyTrash()">
                Entsorgen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-dialog v-model="loading" hide-overlay persistent width="300">
        <v-card color="success" dark>
          <v-card-text>
            Daten werden geladen
            <v-progress-linear
              v-model="percent"
              :indeterminate="percent == null"
              :query="true"
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import Header from "./header";
import productImages from "../../../articleImages.js";
import service from "../../services/ProductionService.js";
import fileDownload from "js-file-download";

export default {
  name: "damaged",
  components: {
    Header,
  },
  data: () => ({
    loading: true,
    percent: null,
    productImages: productImages,
    searchRetoure: "",

    trashDialog: false,
    trashData: {},
    dhlDialog: false,
    dhlData: {
      orderId: "",
      name: "",
      name2: "",
      street: "",
      streetNumber: "",
      addition: "",
      zip: "",
      city: "",
    },

    rmaError: "Bitte gebe eine RMA Nummer ein!",

    carrier: ["DHL", "DPD", "Post"],
    types: [
      { text: "Retoure", value: "Retoure" },
      { text: "Fehlzustellung", value: "NotDelivered" },
    ],
    reasons: [
      "Kam nicht rechtzeitig an",
      "Versandverpackung und Artikel beschädigt",
      "Falscher Artikel versendet",
      "Versandverpackung und Artikel beschädigt",
      "Empfänger hat Annahme verweigert: nicht gewünscht",
      "Leistung oder Qualität ungenügend",
      "Der Transportdienst hat versucht zuzustellen",
      "Lieferadresse falsch oder unvollständig",
      "kein Grund angegeben",
      "nicht abgeholt",
      "Empfänger/Firma unter der angegebenen Anschrift nicht zu ermitteln",
      "Name fehlt an Klingel",
      "Zustellung versucht – Geschäft geschlossen",
      "Adresse nicht gefunden (AT)",
      "Nicht abgeholt (AT)",
      "Sendung entspricht nicht den Versandbedingungen",
      "Irrtümlich bestellt",
    ],

    retoureItem: {
      type: null,
      RMA: null,
      carrier: null,
      orderId: null,
      reason: null,
      sku: null,
    },

    searchTerm: null,
    selectedItemDialog: false,
    searchResults: [],
    history: [],
    historyTableHeader: [
      { text: "Entsorgung", value: "trash" },
      { text: "Datum", value: "date" },
      { text: "Bestellnummer", value: "orderId" },
      { text: "type", value: "type" },
      { text: "RMA", value: "RMA" },
      { text: "SKU", value: "sku" },
      { text: "carrier", value: "carrier" },
      { text: "Grund", value: "reason" },
      { text: "DHL", value: "dhl" },
      { text: "DHL Tracking", value: "reshipment" },
    ],

    error: null,
  }),
  create() {},
  async mounted() {
    this.refreshHistory();
  },

  computed: {},

  methods: {
   

    selectSearchItem(order) {
      console.log(order.orderId);
      this.$set(this.retoureItem, "orderId", order.orderId);
      this.searchRetoure = order.orderId;
      this.$set(this.retoureItem, "sku", order.products[0].sku);
    },

    async refreshHistory() {
      this.loading = true;
      try {
        this.history = (await service.getRetoureHistory()).data.reverse();
        console.log(this.history);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async search() {
      try {
        this.searchResults = (await service.searchOrder(this.searchTerm)).data;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
        this.retoureItem = {};
      }
    },

    async createRetoure() {
      this.loading = true;

      try {
        let response = await service.createRetoure(this.retoureItem);
        await this.refreshHistory();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
        this.retoureItem = {};
      }
    },

    async createDHLLabel() {
      this.loading = true;

      let payload = this.dhlData;
      try {
        let response = (await service.createShipment(payload)).data;

        console.log(response);

        let file = atob(response.file);
        const byteNumbers = new Array(file.length);
        for (let i = 0; i < file.length; i++) {
          byteNumbers[i] = file.charCodeAt(i);
        }
        let fileArray = new Uint8Array(byteNumbers);
        let blobfile = new Blob([fileArray], { type: "buffer" });

        console.log(fileArray);
        console.log(blobfile);

        fileDownload(blobfile, "DHLLabel.pdf", "application/pdf");

        this.dhlDialog = false;
        await this.refreshHistory();
      } catch (err) {
        console.error(err);
        this.error = "Es konnte kein Label für diese Adresse erzeugt werden!";
      } finally {
        this.loading = false;
        this.dhlData = {
          orderId: "",
          name: "",
          name2: "",
          street: "",
          streetNumber: "",
          addition: "",
          zip: "",
          city: "",
        };
      }
    },
    async loadDHLDialog(orderId) {
      this.loading = true;

      try {
        let response = (await service.loadOrderData(orderId)).data;
        this.dhlDialog = true;
        this.dhlData.orderId = orderId;
        this.dhlData.name = response.name1;
        this.dhlData.name2 = response.name2 + " " + response.name3;
        this.dhlData.street = response.address1;
        this.dhlData.streetNumber = response.address2;
        this.dhlData.addition = response.address3;
        this.dhlData.zip = response.postalCode;
        this.dhlData.city = response.town;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async loadTrashDialog(orderId) {
      this.loading = true;

      try {
        let response = (await service.getOrder(orderId)).data;
        this.trashDialog = true;
        this.trashData.orderId = orderId;
        console.log(response.orderItems);
        this.trashData.orderItems = response.orderItems.flatMap((x) => {
          return {
            id: x.id,
            variationId: x.itemVariationId,
            qty: x.quantity,
            name: x.orderItemName,
          };
        });
        console.log(this.trashData.orderItems);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async applyTrash() {
      let payload = this.trashData.orderItems.filter((x) => x.trash == true);
      console.log("going to delete   " + payload.length + " items.");
      this.loading = true;
      for (let item of payload) {
        try {
          await service.applyTrash(
            this.trashData.orderId,
            item.variationId,
            item.qty
          );
        } catch (err) {
          this.err = err;
          continue;
        }
      }
      this.loading = false;
      this.trashDialog = false;
      this.refreshHistory();
    },
  },
};
</script>

<style>
</style>