import Api from '@/services/Api'

export default {
  get() {
    return Api().get('timetracking')
  },

  tiggerTracking() {
    return Api().post('timetracking')
  }
 

}