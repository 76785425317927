<template>
  <div>
    <v-dialog v-model="dialog" max-width="800">
      <v-card v-if="loading == null">
        <v-card-text>
          <v-card-title>Bestellung hinzufügen</v-card-title>
          <v-row>
            <v-col><v-text-field solo v-model="orderId" placeholder="305-2262349-9088325"></v-text-field></v-col>
            <v-col><v-btn large @click="loadOrder()">Suchen</v-btn></v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card :loading="loading" v-if="loading != null">
        <v-alert type="error" :value="error" v-if="error"> </v-alert>
        <v-card-title class="headline"> Bestellung hinzufügen </v-card-title>
        <v-card-text>
          <p>
            Diese Bestellung wird in die Produktions Datenbank hinzugefügt und
            ist bis zur nächsten Synchronisierung vorhanden. Diese Funktion
            dient dazu, um fehlende Artikel einer Bestellung nachzuproduzieren.
          </p>

          <h2>Inhalt der Bestellung</h2>

          <v-list subheader two-line flat>
            <v-subheader>{{ orderData.orderId }}</v-subheader>

            <v-list-item-group multiple>
              <v-list-item
                v-for="item of orderData"
                :key="orderData.indexOf(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.sku }}</v-list-item-title>
                  <v-list-item-subtitle
                    >Menge: {{ item.qty }}</v-list-item-subtitle
                  >
                  <pre>{{ item.custom }}</pre>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="
              dialog = false;
              loading = null;
            "
          >
            Abbrechen
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="
              addOrder();
              loading = null;
            "
          >
            Hinzufügen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import service from "@/services/ProductionService";
import EventBus from "@/event-bus";
export default {
  data: () => ({
    loading: null,
    error: null,
    dialog: false,
    orderData: null,
    orderId: null,
    success: false,
  }),

  mounted() {
    EventBus.$on("ADD_ORDER", (payload) => {
      this.dialog = true;
    });
  },
  methods: {
    formatCustomData(payload) {
      if (!payload) return null;
      let output = "";

      let excluded = ["orderId", "plentyId", "managedId", "sku", "table", "extras"];
      for (let o of Object.keys(payload)) {
        if (excluded.indexOf(o) == -1) {
          output += o + ": " + payload[o] + "\n";
        }
      }

      return output;
    },

    async loadOrder() {
      try {
        this.loading = true;
        let data = (await service.getMongoOrder(this.orderId)).data;

        this.orderData = [];

        for (let p of data.products) {
          this.orderData.push({
            sku: p.sku,
            qty: p.qty,
            custom: this.formatCustomData(p.translated),
          });
        }
      } catch (e) {
        this.error = e.message;
        setTimeout(() => {
          this.error = null;
        }, 10000);
      } finally {
        this.loading = false;
      }
    },

    async addOrder() {
      this.loading = true;
      let response = await service.productionAddOrder(this.orderId);
      this.orderId = null;
      this.orderData = null;
      this.loading = false;
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>