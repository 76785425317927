<template>
  <div>
    <Header />
    <v-container grid-list-xs>
      <v-row>
        <v-col cols="12">
          <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="selectedDate"
            transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="selectedDate" label="Wähle ein Datum aus" prepend-icon="mdi-calendar" readonly
                v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="selectedDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary"
                @click="
                  $refs.menu.save(selectedDate);
                refresh();
                                                                                                                                                                                      ">
                OK </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row v-if="timelines">
        <v-col cols="12" v-for="timeline in timelines" :key="timeline.userId">
          <v-card class="my-5">
            <v-card-title>{{ timeline.userId }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <p>Laser Einträge: {{ getLaserCount(timeline.timeline) }}</p>
                  <p>Pack Einträge: {{ getPackCount(timeline.timeline) }}</p>
                </v-col>
                <v-col cols="6">
                  <p>Durchschnittliche Packdauer: {{ getAveragePackTime(timeline.timeline) }} Sekunden</p>
                  <p>Durchschnittliche Pausen zwischen dem Packen: {{ getAveragePauseTime(timeline.timeline) }} Sekunden
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <apexchart :options="chartOptions" :series="timelineSeries(timeline.timeline)" type="bar"
                    height="500" width="100%" />
                </v-col>
              </v-row>
              <v-row>
                <v-data-table :headers="headers" :items="generateTableItems(timeline)" style="width: 100%">
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.hour }}</td>
                      <td>{{ item.userId }}</td>
                      <td>
                        <div :style="itemClass(item, 'laserEvents', generateTableItems(timeline))">{{ item.laserEvents }}</div>
                      </td>
                      <td>
                        <div :style="itemClass(item, 'packEvents', generateTableItems(timeline))">{{ item.packEvents }}</div>
                      </td>
                      <td>{{ item.packSpeed }}</td>
                      <td>{{ item.laserSpeed }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Header from "./header";
import service from "@/services/ProductionService";
import router from "@/router";
import moment from "moment";

export default {
  name: "dashboard",
  components: {
    Header,
  },
  data: () => ({
    loading: true,
    timelines: [],
    selectedDate: new Date().toISOString().substr(0, 10),
    headers: [
      { text: "Stunde", value: "hour" },
      { text: "Nutzer ID", value: "userId" },
      { text: "Laser Events", value: "laserEvents" },
      { text: "Pack Events", value: "packEvents" },
      { text: "Packgeschwindigkeit", value: "packSpeed" },
      { text: "Lasergeschwindigkeit", value: "laserSpeed" },
    ],
  }),
  create() { },
  async mounted() {
    this.refresh();
  },

  methods: {

    getLaserCount(timeline) {
      return timeline.filter((item) => item.type === "laser").length;
    },
    getPackCount(timeline) {
      return timeline.filter((item) => item.type === "packing").length;
    },
    getAveragePackTime(timeline) {
      const packTimes = timeline
        .filter((item) => item.type === "packing")
        .map((item) => {
          const start = new Date(item.time.timeStart).getTime();
          const end = new Date(item.time.timeEnd).getTime();
          return end - start;
        });
      if (packTimes.length > 0) {
        const average = packTimes.reduce((a, b) => a + b) / packTimes.length;
        return Math.round(average / 1000);
      }
      return 0;
    },
    getAveragePauseTime(timeline) {
      const packTimes = timeline.filter((item) => item.type === "packing");
      if (packTimes.length > 1) {
        const pauses = [];
        for (let i = 1;i < packTimes.length;i++) {
          const start = new Date(packTimes[i - 1].time.timeEnd).getTime();
          const end = new Date(packTimes[i].time.timeStart).getTime();
          pauses.push(end - start);
        }
        const average = pauses.reduce((a, b) => a + b) / pauses.length;
        return Math.round(average / 1000);
      }
      return 0;
    },

    timelineSeries(timeline) {
      const laserData = timeline
        .filter((item) => item.type === 'laser')
        .map((item) => ({
          x: new Date(item.time.timeStart).getTime(),
          y: 1,
          type: item.type,
          timeline: item,
        }));

      const packingData = timeline
        .filter((item) => item.type === 'packing')
        .map((item) => ({
          x: new Date(item.time.timeStart).getTime(),
          y: 2,
          type: item.type,
          timeline: item,
        }));

      
        let payload = [];

        if(laserData.length > 0) {
          payload.push({
            name: 'Laser',
            data: laserData,
          });
        }

        if(packingData.length > 0) {
          payload.push({
            name: 'Verpackung',
            data: packingData,
          });
        }

      return payload;
    },

    itemClass(item, type, tableItems) {
      const maxValue = Math.max(...tableItems.map((i) => i[type]));
      const minValue = Math.min(...tableItems.map((i) => i[type]));

      const value = item[type];
      const ratio = (value - minValue) / (maxValue - minValue);

      const red = Math.round(255 * (1 - ratio));
      const green = Math.round(255 * ratio);

      return {
        'background-color': `rgb(${red}, ${green}, 0)`,
        'padding': '15px',
        'text-align': 'center',
        'text-weight': 'bold',
      };
    },


    generateTableItems(timeline) {

      const hours = Array.from({ length: 13 }, (_, i) => 6 + i); // [6, 7, 8, ..., 18]
      const tableItems = [];

      for (const hour of hours) {
        const startHour = new Date(this.selectedDate).setHours(hour, 0, 0, 0);
        const endHour = new Date(this.selectedDate).setHours(hour + 1, 0, 0, 0);

        const eventsInHour = timeline.timeline.filter((event) => {
          const startTime = new Date(event.time.timeStart).getTime();
          return startTime >= startHour && startTime < endHour;
        });

        const laserEvents = eventsInHour.filter((event) => event.type === "laser");
        const packEvents = eventsInHour.filter((event) => event.type === "packing");

        // Hier kannst du die Logik für die Berechnung der Packgeschwindigkeit und Lasergeschwindigkeit hinzufügen
        const calculateAverageDuration = (events) => {
          if (events.length === 0) return 0;

          const totalDuration = events.reduce((sum, event) => {
            const start = new Date(event.time.timeStart).getTime();
            const end = new Date(event.time.timeEnd).getTime();
            return sum + (end - start);
          }, 0);

          return Math.round((totalDuration / events.length) / 1000);
        };

        const packSpeed = calculateAverageDuration(packEvents) + "s";
        const laserSpeed = calculateAverageDuration(laserEvents) + "s";

        tableItems.push({
          hour: `${hour}:00 - ${hour + 1}:00`,
          userId: timeline.userId,
          laserEvents: laserEvents.length,
          packEvents: packEvents.length,
          packSpeed,
          laserSpeed,
        });
      }

      return tableItems;
    },

    async refresh() {
      this.timelines = null;
      this.loading = true;
      try {

        setTimeout(async () => {
          
          this.timelines = (await service.getV2Analytics(new Date(this.selectedDate))).data.timelines;
          
          this.loading = false;
        }, 1000);
      } catch (err) {
        console.error(err);
        if (err.response.status == 401) {
          router.replace("/auth/login");
        }
      }
    },
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          height: 1200,
          type: "timeline",
          toolbar: {
            show: true,
          },
          width: '100%',
        },
        plotOptions: {
          timeline: {
            enableShades: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#FF4560", "#008FFB"],
        xaxis: {
          type: "datetime",
          labels: {
            datetimeUTC: false,
          },
          min: new Date(this.selectedDate).setHours(6, 0, 0, 0),
          max: new Date(this.selectedDate).setHours(18, 0, 0, 0),
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value;
            },
          },
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const { timeline } = series[seriesIndex].data[dataPointIndex];
            const { time, type } = timeline;
            const date = new Date(time.time);
            return (
              '<div class="apexcharts-tooltip">' +
              '<div class="apexcharts-tooltip-series-group">' +
              '<span class="apexcharts-tooltip-marker" style="background-color: ' +
              w.config.colors[dataPointIndex] +
              '"></span>' +
              '<div class="apexcharts-tooltip-text">' +
              '<div>' + type + ': ' + date.toLocaleString() + '</div>' +
              '<div>' + "SKU: " + time.sku + '</div>' +
              '<div>' + "Bestellnummer: " + time.orderId + '</div>' +
              '</div>' +
              '</div>' +
              '</div>'
            );
          },
        },
      };
    },
  },

};
</script>