
<template>
  <div>
    <Header />
    <v-container style="max-width: 100%">
      <v-row>
        <v-col cols="3">
          <h1>Kartonagen</h1>
          <v-row no-gutters>
            <v-col v-for="item in cartons" :key="item.id" cols="12">
              <v-container style="border: 1px solid #00000055; margin: 2px">
                <v-row height="50px">
                  <v-col cols="5">
                    <v-img :src="item.img" contain height="120px"></v-img>
                  </v-col>
                  <v-col cols="7" style="border-left: 1px solid #00000055">
                    <span
                      class="subtitle-1"
                      style="overflow: hidden; word-wrap: break-word; text-overflow: ellipsis;}"
                      >#{{ item.id }}: {{ item.name }}</span
                    >
                    <br />
                    <span
                      class="subtitle-2"
                      style="overflow: hidden; word-wrap: break-word; text-overflow: ellipsis;}"
                      >Externe ID: {{ item.exId }}</span
                    >
                  </v-col>
                </v-row>
                <v-row height="50px" style="border-top: 1px solid #00000055">
                  <v-col class="stockKPI"
                    >{{ item.qty || 0 }} <br />
                    <span class="label">Anzahl</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="5">
          <h1>Nicht zugewiesene Produkte</h1>
          <v-row no-gutters>
            <v-col
              v-for="item in products.filter((x) => x.cartonId == null)"
              :key="item.id"
              cols="12"
            >
              <v-container style="border: 1px solid #00000055; margin: 2px">
                <v-row height="50px">
                  <v-col cols="5">
                    <v-img :src="item.image" contain height="120px"></v-img>
                  </v-col>
                  <v-col cols="7" style="border-left: 1px solid #00000055">
                    <span
                      class="subtitle-1"
                      style="overflow: hidden; word-wrap: break-word; text-overflow: ellipsis;}"
                      >{{ item.name }}</span
                    >
                    <br />
                    <span
                      class="subtitle-2"
                      style="overflow: hidden; word-wrap: break-word; text-overflow: ellipsis;}"
                      >SKU: {{ item.sku }}</span
                    >
                    <br />
                    <br />
                    <v-btn small primary @click="connectProduct = item"
                      ><v-icon>add</v-icon> Karton zuweisen</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <h1>zugewiesene Produkte</h1>
          <v-row no-gutters>
            <v-col
              v-for="item in products.filter((x) => x.cartonId != null)"
              :key="item.id"
              cols="12"
            >
              <v-container style="border: 1px solid #00000055; margin: 2px">
                <v-row height="50px">
                  <v-col cols="5">
                    <v-img :src="item.image" contain height="120px"></v-img>
                  </v-col>
                  <v-col cols="7" style="border-left: 1px solid #00000055">
                    <span
                      class="subtitle-1"
                      style="overflow: hidden; word-wrap: break-word; text-overflow: ellipsis;}"
                      >{{ item.name }}</span
                    >
                    <br />
                    <span
                      class="subtitle-2"
                      style="overflow: hidden; word-wrap: break-word; text-overflow: ellipsis;}"
                      >SKU: {{ item.sku }}</span
                    >
                    <br />
                    <v-img contain :src="getCartonImg(item.cartonId)" height="90px" @click="connectProduct = item"/>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-bottom-sheet :value="connectProduct != null" persistent scrollable>
      <v-card class="text-center">
        <v-card-text>
          <v-btn class="mt-6" text color="red" @click="connectProduct = null">
            Schließen
          </v-btn>
          <div class="py-3">
            <v-row v-if="connectProduct">
              <v-col cols="4">
                <h2>{{ connectProduct.name }}</h2>
                <v-img
                  :src="connectProduct.image"
                  contain
                  height="200px"
                ></v-img>
                <h4>{{ connectProduct.sku }}</h4>
              </v-col>
              <v-col cols="4">
                <div height="700px" style="overflow: scroll">
                  <v-row>
                    <v-col cols="12" v-for="carton in cartons" :key="carton.id">
                      <v-card
                        @click="connectCarton(carton.id, connectProduct.id)"
                      >
                        <v-card-title>{{ carton.name }}</v-card-title>
                        <v-card-text>
                          <v-img :src="carton.img" contain height="100px" />
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col>
                <v-btn @click="connectCarton(0, connectProduct.id)"
                  >Ohne Kartonage verknüpfen</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </v-card-text></v-card
      ></v-bottom-sheet
    >
  </div>
</template>

<script>
import Header from "./header";
import service from "@/services/ProductionService";
import router from "@/router";

const colorAlert = [211, 47, 47];

var percentColors = [
  { pct: 0.0, color: { r: 211, g: 47, b: 47 } },
  { pct: 0.5, color: { r: 255, g: 235, b: 59 } },
  { pct: 1.0, color: { r: 76, g: 175, b: 80 } },
];

export default {
  name: "dashboard",
  components: {
    Header,
  },
  data: () => ({
    timer: null,
    loading: true,
    cartons: [],
    products: [],
    connectProduct: null,
  }),
  create() {},
  async mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.loading = true;
      try {
        this.cartons = (await service.getCartons()).data;
        this.products = (await service.getWarehouse()).data;

        this.loading = false;
      } catch (err) {
        if (err.response.status == 401) {
          router.replace("/auth/login");
        }
      }
    },

    getCartonImg(cartonId) {
      let carton = this.cartons.filter((x) => x.id == cartonId);
      return carton.length > 0 ? carton[0].img : null;
    },
    async connectCarton(cartonId, productId) {
      this.loading = true;
      try {
        await service.connectCarton(cartonId, productId);
        this.connectProduct = null;
        this.refresh();
      } catch (err) {}
    },
  },
  breforeDestroy() {},
};
</script>
<style>
.row {
  margin: 0px !important;
}
.stockKPI {
  text-align: center;
  background: #424242;

  color: white;
  font-weight: bold;
}

.label {
  font-size: 0.5em;
}
</style>
