<template>
  <v-timeline
    dense
    clipped
    v-if="tracking"
  >
    <v-timeline-item
      class="mb-4"
      color="grey"
      icon-color="grey lighten-2"
      small
      v-for="event of tracking.steps" 
      :key="event.timestamp"
    >
      <v-row justify="space-between">
        <v-col cols="3">
          <v-chip 
            :color="getStatusColor(event.statusCode)"
            :class="{'white--text': event.statusCode === 'delivered'}"
          >
            {{ formatStatus(event.statusCode) }}
          </v-chip>
        </v-col>
        <v-col cols="6">
          <div>{{ event.description }}</div>
          <div v-if="event.location && event.location.address" class="caption grey--text">
            {{ event.location.address.addressLocality }}
          </div>
        </v-col>
        <v-col
          class="text-right"
          cols="3"
        >
          {{ new Date(event.timestamp).toLocaleString() }}
        </v-col>
      </v-row>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
export default {
  name: "DHLTracking",
  props: ["tracking"],
  
  methods: {
    getStatusColor(status) {
      switch(status) {
        case 'delivered':
          return 'success';
        case 'transit':
          return 'info';
        case 'pre-transit':
          return 'warning';
        default:
          return 'grey';
      }
    },

    formatStatus(status) {
      switch(status) {
        case 'delivered':
          return 'Zugestellt';
        case 'transit':
          return 'In Transit';
        case 'pre-transit':
          return 'Angekündigt';
        default:
          return status;
      }
    }
  }
};
</script>

<style scoped>
.caption {
  font-size: 0.75rem;
}
</style>