<template>
  <div>
    <Header />
  
    <v-container mb-10 style="max-width: 98%;">

      <v-row>
        <v-col cols="8">
        <v-text-field
          v-model="managedId"
          label="Barcode"
          outlined
          dense
          clearable
          @keydown.enter="searchOrder"></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-btn @click="searchOrder">Suchen</v-btn>
          </v-col>
      </v-row>

      <v-row v-if="currentItem.products">

        <v-col cols="8" style="font-size: 0.7em; padding:0px">
           <span>Bestellnummer: {{ this.currentItem.orderId }}</span> | <span>Bestellt am {{ this.currentItem.createDate | moment("DD.MM.yyyy HH:mm") }}</span> | <span>Marktplatz: {{ this.currentItem.shop }}</span>
          <v-alert
            :color="progress > 0 ? 'info' : 'error'"
            :value="true"
            dense
            style="margin: 0px !important;"
          >
            <v-progress-linear
            :value="progress"
            height="25"
            rounded
            :color="barColor"
            :class="{ 'blink': isBlinking }"
          >
    Deine Packzeit: <strong>{{ minutes }} m {{ seconds }}s  | ⌀ Heute: {{ Math.round(packStats.time) }}s ({{ packStats.count }} Pakete)</strong>
  </v-progress-linear>
          </v-alert>
        </v-col>
        <v-col cols="4" style="text-align: right">
          <v-icon style="margin-left: 20px" primary @click="showPrinterSelectionDialog = true">settings</v-icon>
        </v-col>
      </v-row>
      <!-- Top bar  -->
     
      <v-row v-if="order && order.status == 'packed'">
        <v-col cols="12">
          <v-alert type="success">Bereits gepackt</v-alert>
        </v-col>

      </v-row>
      <v-row v-if="error">
        <v-col cols="12">
          <v-alert :value="error" type="error">{{ error }}</v-alert>
        </v-col>
      </v-row>
      <v-row v-if="currentItem && currentItem.warranty == true">
        <v-col cols="12">
          <v-alert type="warning">Das ist eine Reklamation. <span v-if="currentItem.warrantyReason">Grund: {{ currentItem.warrantyReason }}</span> <span v-if="currentItem.warrantyNote">Notitz: {{ currentItem.warrantyNote }}</span></v-alert>
        </v-col>

      </v-row>
      <v-row v-if="currentItem.products">
        <v-col cols="8" style="margin-bottom: 60px">
          <v-card class="pa-3" :class="currentItem.products.filter(x => x.sku).length > 1 ? 'multiCart' : ''">
            <h2 v-if="currentItem.products.filter(x => x.sku).length > 1">
              DIESE BESTELLUNG HAT MEHRERE ARTIKEL <h1 style="color:white; background: black; padding:5px;display: inline;font-size: 1.2em;">{{ currentItem.products.filter(x => x.sku).length }} ARTIKEL</h1>
            </h2>
            <div style="border: 1px solid #000; padding: 5px;" v-for="p in currentItem.products.filter(x => x.sku)" :key="currentItem.products.indexOf(p)">
              <div v-if="p.status == 'ready'" class="ready">Bereit <span v-if="p.shelf">in Fach {{ p.shelf.slot }}</span></div>
              <div v-if="p.status == 'missing'" class="missing">Fehlt</div>
              <span class="blockLine" style="font-size: 1.4em">SKU: <span class="customValue">{{ p.sku }}</span></span> 
                <h2 v-if="p.sku == 'zippo_gb_foto'"  style="background:black; color: magenta">ZIPPO GESCHENKSET! </h2>
                <p v-for="a in filterAttributes(p.attributes)" :key="a" style="margin-bottom:0px !important">
                  <v-img v-if="p.attributes[a] != null && a == 'designId'" :src="'https://accelery-poster.s3.eu-central-1.amazonaws.com/' + encodeURIComponent(p.attributes[a]) + '.png'" height="200px" style="background-color: grey" contain></v-img>
                  <v-img v-else-if="p.attributes['designId'] == null && p.attributes[a] != null && p.attributes[a].toString().indexOf('http') > -1" :src="p.attributes[a]" height="200px" style="background-color: grey" contain></v-img>
                  <span v-else class="blockLine">{{ a }}: <span class="customValue">{{ p.attributes[a] }}</span></span>
                </p>
                <p v-if="p.translated" style="margin-bottom:0px !important">
                  <span class="blockLine" v-if="p.translated.line1 && p.translated.font1"><span :class="'font-' + p.translated.font1">{{ p.translated.line1 }}</span></span>
                </p>
                <span class="blockLine qty">Menge: <span class="customValue">{{ p.qty }}</span></span>
                
            </div>
          </v-card>

          <center> <v-btn class="ma-2" @click="openDamagedDialogResetOnly">Produkt zurücksetzen</v-btn>
            <v-btn v-if="currentItem.products.filter(x => x.sku).length > 1" class="ma-2" @click="manualMultiCartAction.dialog = true">Artikel in Warenkorbregal</v-btn>
            <!--<v-btn class="ma-2" color="error" @click="openDamagedDialog">Fehlgravur melden</v-btn>--> 
          </center>


        </v-col>
        <v-col cols="4" style="margin-bottom: 60px">
          <v-card class="pa-3" color="#f0f0f0" height="100%">
            <h1>Adresse</h1>
            <p style="font-size: 1.1em !important;line-height: 1.4em !important;">
              <span class="blockLine name">{{ currentItem.customer.firstName }}
                {{ currentItem.customer.lastName }}</span>
              <span class="blockLine">{{
                currentItem.customer.adress
              }}</span>
              <span class="blockLine">{{ currentItem.customer.zip }}
                {{ currentItem.customer.city }}</span>
              <span class="blockLine">{{
                currentItem.customer.country
              }}</span>
            </p>

            <v-alert type="warning" v-if="currentItem.shop == 'amazon'">Flyer beilegen</v-alert>
            <v-alert type="success" v-if="currentItem.shop == 'muckelmeister'">MuckelMeister Flyer beilegen</v-alert>
            <v-alert type="success" v-if="currentItem.shop == 'acs' && currentItem.reference.indexOf('#FW') > -1">Famwalls Flyer beilegen</v-alert>
            <v-alert type="success" v-if="currentItem.shop == 'acs' && currentItem.reference.indexOf('#BP') > -1">Bo & Birdie Flyer beilegen</v-alert>
            <v-alert type="error" v-if="currentItem.registered == undefined">Versand noch nicht angemledet!</v-alert>

          </v-card>

        </v-col>

      </v-row>
      <!--  Content -->
    </v-container>

    <div class="bottomControls" v-if="currentItem.products">
      <v-container>
        <v-row v-if="currentItem.registered == undefined" style="background: red">
          <v-col cols="6">
            <v-btn @click="under5cm = true" small block :class="under5cm ? 'btnSelected' : ''">Unter 5 cm</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn @click="under5cm = false" small block :class="!under5cm ? 'btnSelected' : ''">Über 5 cm</v-btn>
          </v-col>
        </v-row>
        <v-row class="text-md-center" v-if="!missingItems">
          <v-col v-if="!printing && operatorState == 1 || printing" pa-2>
            <v-btn block color="info" @click="print(); operatorState = 2" large :loading="printing" :disabled="cooldown > 0">
              Drucken
              <v-icon>print</v-icon>
              <span style="padding-left: 8px" v-if="cooldown > 0">({{ cooldown }})</span>
            </v-btn>
          </v-col>
          <v-col v-if="!printing && operatorState == 2" pa-2>
            <v-btn block color="success" @click="pack(); operatorState = 1" large :disabled="cooldown > 0">
              Gepackt
              <v-icon>check</v-icon>
              <span style="padding-left: 8px" v-if="cooldown > 0">({{ cooldown }})</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="text-md-center" v-else-if="missingItems">
          <v-col pa-2>
            <v-btn block color="warning" @click="startMultiCartAction();" large :loading="printing">
              In Warenkorbregal legen
            </v-btn>
          </v-col>
          
        </v-row>
      </v-container>
    </div>

    <v-dialog v-if="currentItem.products" v-model="showPrinterSelectionDialog" scrollable max-width="400px">
      <v-card>
        <v-card-title>Wähle Deinen Drucker aus</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-radio-group v-model="printer" column>
            <v-radio v-for="p in printers" :key="p.url" :label="p.name" :value="p.url"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="showPrinterSelectionDialog = false">
            Abbrechen
          </v-btn>
          <v-btn color="blue darken-1" text @click="savePrinter(); showPrinterSelectionDialog = false;">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="currentItem.products" v-model="multiCartSlotDialog" scrollable max-width="800px">
      <v-card v-if="multiCartPayload">
        <v-card-title>Lege folgende Artikel in das Warenkorbregal</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
         <v-row>
          <v-col cols="4">
            <!-- large number -->
            <v-card class="pa-3" color="#f0f0f0" height="100%">
              <h1>Fach</h1>
              <p style="font-size: 4.1em !important;line-height: 1.4em !important;">
                <span class="blockLine">{{ multiCartPayload.location }}</span>
              </p>
            </v-card>
          </v-col>
          <v-col cols="8">
            <v-card class="pa-3" color="#f0f0f0" height="100%">
              <h1>Artikel</h1>
              <div v-if="multiCartPayload && multiCartPayload.items" style="border: 1px solid #000; padding: 5px;" v-for="p in multiCartPayload.items" :key="multiCartPayload.items.indexOf(p)">
                <span class="blockLine" style="font-size: 1.4em">SKU: <span class="customValue">{{ p.sku }}</span></span>
                  <p v-for="a in filterAttributes(p.attributes)" :key="a" style="margin-bottom:0px !important">
                    <v-img v-if="p.attributes[a] != null && p.attributes[a].toString().indexOf('http') > -1" :src="p.attributes[a]" height="200px" style="background-color: grey" contain></v-img>
                    <span v-else class="blockLine">{{ a }}: <span class="customValue">{{ p.attributes[a] }}</span></span>
                  </p>
                  <span class="blockLine qty">Menge: <span class="customValue">{{ p.qty }}</span></span>
              </div>
            </v-card>
          </v-col>
         </v-row>
          
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="multiCartSlotDialog = false">
            Abbrechen
          </v-btn>
          <v-btn color="blue darken-1" text @click="putMultiCartItems(); multiCartSlotDialog = false;">
            Erledigt
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    
    <v-dialog v-if="currentItem.products" v-model="manualMultiCartAction.dialog" scrollable max-width="800px">
      <v-card v-if="manualMultiCartAction.dialog">
        <v-card-title>Welche Artikel möchtest du in den Warenkorb legen?</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
         <v-row>
          <v-col cols="4">
            <!-- large number -->
            <v-card class="pa-3" color="#f0f0f0" height="100%">
              <h1>Fach</h1>
              <p style="font-size: 4.1em !important;line-height: 1.4em !important;">
                <v-select v-model="manualMultiCartAction.location" :items="manualMultiCartAction.options" label="Fach"></v-select>
              </p>
            </v-card>
          </v-col>
          <v-col cols="8">
            <v-card class="pa-3" color="#f0f0f0" height="100%">
              <h1>Artikel</h1>
              <div v-if="currentItem.products" style="border: 1px solid #000; padding: 5px;" v-for="p in currentItem.products.filter(x => x.status == 'ready' && !x.shelf)" :key="currentItem.products.indexOf(p)">
                <v-checkbox v-model="manualMultiCartAction.items" :value="p" :label="'SKU: ' + p.sku"></v-checkbox>
                <p v-for="a in filterAttributes(p.attributes)" :key="a" style="margin-bottom:0px !important">
                  <v-img v-if="p.attributes[a] != null && p.attributes[a].toString().indexOf('http') > -1" :src="p.attributes[a]" height="200px" style="background-color: grey" contain></v-img>
                  <span v-else class="blockLine">{{ a }}: <span class="customValue">{{ p.attributes[a] }}</span></span>
                </p>
                <span class="blockLine qty">Menge: <span class="customValue">{{ p.qty }}</span></span>
                <hr>
              </div>
            </v-card>
          </v-col>
         </v-row>
          
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="manualMultiCartAction.dialog = false">
            Abbrechen
          </v-btn>
          <v-btn color="blue darken-1" text @click="startManualMultiCartAction()">
            Erledigt
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <DamagedDialog />
  </div>
</template>

<script>
import Header from "../header";
import packlistService from "@/services/PacklistService";

import DamagedDialog from "./DamagedDialog.vue";

import EventBus from "@/event-bus";

const COOL_DOWN_TIME = 2;

export default {
  name: "production",
  components: {
    Header,
    DamagedDialog,
  },
  data: () => ({

    managedId: null,
    items: [],
    index: 0,
    loading: true,
    percent: 0,
    currentItem: { sku: null, finished: false },
    show: false,
    error: null,
    showPrinterSelectionDialog: false,
    printers: null,
    printer: null,
    printing: false,
    interval: null,
    init: false,
    under5cm: true,
    keyPressed: [],
    cooldownInterval: null,
    seconds: 0,
    minutes: 0,
    totalTime: 26, // 25 seconds
    remainingTime: 26,
    progress: 100,
    isBlinking: false,
    operatorState: 1,
    startTime: new Date(),
    missingItems: false,
    multiCartSlot: null,
    multiCartSlotDialog: false,
    multiCartPayload: null,
    packStats: { time: "" },

    manualMultiCartAction: {
      options: ["A1", "A2", "A3", "A4", "A5", "A6", "A7", "A8", "B1", "B2", "B3", "B4", "B5", "B6", "B7", "B8", "C1", "C2", "C3", "C4", "C5", "C6", "C7", "C8", "D1", "D2", "D3", "D4", "D5", "D6", "D7", "D8", "E1", "E2", "E3", "E4", "E5", "E6", "E7", "E8"],
      dialog: false,
      location: null,
      items: []
    },
  }),
  create() { },
  computed: {
    barColor() {
      if (this.progress > 50) return 'green';
      if (this.progress > 25) return 'orange';
      return 'red';
    },
  },

  async mounted() {
    await this.loadPrinters();


    this.cooldownInterval = setInterval(() => {
      if (this.cooldown > 0) {
        this.cooldown--;
      }
    }, 1000);



    //calculate time
    setInterval(() => {

      let now = new Date();
      let diff = now - this.startTime;
      this.seconds = Math.floor(diff / 1000);
      this.minutes = Math.floor(this.seconds / 60);
      this.seconds = this.seconds % 60;


      this.remainingTime -= 0.5;
      this.progress = (this.remainingTime / this.totalTime) * 100;

      if (this.remainingTime <= 0) {
        this.startBlinking();
      }
    }, 500);

   

    window.addEventListener("keyup", (e) => {
      if (e.key == "a") {
        this.under5cm = !this.under5cm;
      }
      if (e.key == "b") {
        if (this.operatorState == 1) {
          this.print();
          this.operatorState = 2;
        }
      }
      if (e.key == "c") {
        if (this.operatorState == 2) {
          this.pack();
          this.operatorState = 1;
        }
      }
    });
  },

  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.cooldownInterval);
  },

  

  methods: {


    async searchOrder(){
      this.error = null;
      this.items = (await packlistService.findPacklistItem(this.managedId)).data;
      this.loadOrder(this.items[this.index].orderId);
    },


    async openDamagedDialog() {
      EventBus.$emit("OPEN_DAMAGED_DIALOG", this.currentItem);
    },

    async openDamagedDialogResetOnly() {
      EventBus.$emit("OPEN_DAMAGED_DIALOG_RESETONLY", this.currentItem);
    },

    async loadPrinters() {
      this.printers = (await packlistService.getPrinters()).data;
    },

    startBlinking() {
      this.isBlinking = !this.isBlinking;
    },


    async loadOrder(orderId) {
      if (orderId) {
        this.loading = true;

        this.packStats = (await packlistService.getPackStats()).data;

        this.currentItem = (
          await packlistService.getPacklistItem(orderId)
        ).data;

        //ignore skus: 
        let ignoreSkus = [
          "shopify_extra_helle_gravur",
          "Artikel_Extras",
        ];

        this.currentItem.products = this.currentItem.products.filter(
          (x) => ignoreSkus.indexOf(x.sku) == -1
        );

        //check for missing items
        this.missingItems = this.currentItem.products.filter(
          (x) => x.status == "missing"
        ).length > 0;

        //check for Tip

        this.currentItem.tip = null;
        let possibleTip = this.currentItem.plentyOrder.orderItems.filter(
          (x) => x.orderItemName == "Tip"
        );
        if (possibleTip.length > 0) {
          this.currentItem.tip = possibleTip[0].amounts[0].priceGross;
        }

        packlistService.trackStart(this.items[this.index].id);
        this.startTime = new Date();
        this.remainingTime = 25;
        this.isBlinking = false;

        //focus class .selectedTile 
        let selectedTile = document.querySelector(".selectedTile");
        if (selectedTile) {
          selectedTile.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }

        this.loading = false;
      }
    },

    async print() {
      if (this.cooldown > 0) return;

      this.printing = true;
      let printerCookie = this.$cookies.get("printer");
      if (!printerCookie) {
        this.loadPrinters();
        //no printer selected
        this.showPrinterSelectionDialog = true;
      } else {
        await packlistService.print(
          this.currentItem.orderId,
          printerCookie,
          this.under5cm
        );
        this.cooldown = COOL_DOWN_TIME;
        this.printing = false;
      }
    },

    async startMultiCartAction() {
      this.printing = true;
      this.multiCartSlot = (await packlistService.requestMultiCartSlot(this.currentItem.orderId, { printer: this.$cookies.get("printer") })).data;

      let currentSKU = this.items[this.index].sku;
      let exisitingItems = this.currentItem.products.filter(x => x.status == "ready" && x.sku == currentSKU && x.shelf == null);

      this.multiCartPayload = {
        packlistItemId: this.items[this.index].id,
        location: this.multiCartSlot.location,
        items: exisitingItems

      }

      console.log(this.multiCartPayload)

      this.multiCartSlotDialog = true;
      this.printing = false;
    },

    async startManualMultiCartAction() {
      this.multiCartPayload = {
        packlistItemId: this.items[this.index].id,
        location: this.manualMultiCartAction.location,
        items: this.manualMultiCartAction.items,
        printer: this.$cookies.get("printer")
      }

      await this.putMultiCartItems();
      this.manualMultiCartAction.dialog = false;
      this.multiCartPayload.location = null;
      this.multiCartPayload.items = [];
      this.multiCartPayload.printer = null;
      this.manualMultiCartAction.items = [];
      this.manualMultiCartAction.location = null;
    },

    async putMultiCartItems() {
      this.printing = true;
      await packlistService.putMultiCartItems(this.currentItem.orderId, this.multiCartPayload);
      this.cooldown = COOL_DOWN_TIME;
      this.printing = false;
      this.multiCartSlotDialog = false;

    },

  

    savePrinter() {
      this.$cookies.set("printer", this.printer);
    },

    async pack() {
      if (this.cooldown > 0) return;

      this.items[this.index].status = "packed";
      packlistService.trackEnd(this.items[this.index].id);
      this.startTime = new Date();



    },

    filterAttributes(attributes) {
      const blackList = [
        "Schriftfarbe",
        "Schriffarbe",
        "Schriftfarbe2",
        "Schriftart2",
        "subtotal",
        "vQty",
      ];
      let attr = Object.keys(attributes).filter(
        (x) => blackList.indexOf(x.toString()) == -1
      );
      return attr;
    },
  },
};
</script>

<style>
.qty {
  font-size: 1.4em !important;
}

.qty>.customValue {
  background: black;
  padding: 5px;
}

.customvalue,
.customValue {
  font-weight: bold !important;
  color: red !important;
}

.name {
  font-weight: bold;
  color: green;
}

.blockLine {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.multiCart {
  background-color: lime !important;
}

.btnSelected {
  border: 3px solid lime;
}

.btnSelected>span {
  background-color: lime !important;
}

.bottomControls {
  bottom: 0px;
  position: fixed;
  left: 0px;
  right: 0px;
}

@font-face {
  font-family: "Schrift_1";
  src: url(~@/assets/fonts/Schrift_1.woff) format("woff");
}

@font-face {
  font-family: "Schrift_2";
  src: url(~@/assets/fonts/Schrift_2.woff) format("woff");
}

@font-face {
  font-family: "Schrift_3";
  src: url(~@/assets/fonts/Schrift_3.woff) format("woff");
}

@font-face {
  font-family: "Schrift_4";
  src: url(~@/assets/fonts/Schrift_4.woff) format("woff");
}

@font-face {
  font-family: "Schrift_5";
  src: url(~@/assets/fonts/Schrift_5.woff) format("woff");
}

@font-face {
  font-family: "Schrift_6";
  src: url(~@/assets/fonts/Schrift_6.woff) format("woff");
}

@font-face {
  font-family: "Schrift_7";
  src: url(~@/assets/fonts/Schrift_7.woff) format("woff");
}

@font-face {
  font-family: "Schrift_8";
  src: url(~@/assets/fonts/Schrift_8.woff) format("woff");
}

@font-face {
  font-family: "Schrift_9";
  src: url(~@/assets/fonts/Schrift_9.woff) format("woff");
}

@font-face {
  font-family: "Schrift_10";
  src: url(~@/assets/fonts/Schrift_10.woff) format("woff");
}

@font-face {
  font-family: "Schrift_11";
  src: url(~@/assets/fonts/Schrift_11.woff) format("woff");
}

@font-face {
  font-family: "Schrift_12";
  src: url(~@/assets/fonts/Schrift_12.woff) format("woff");
}

@font-face {
  font-family: "Schrift_13";
  src: url(~@/assets/fonts/Schrift_13.woff) format("woff");
}

@font-face {
  font-family: "Schrift_14";
  src: url(~@/assets/fonts/Schrift_14.woff) format("woff");
}

@font-face {
  font-family: "Schrift_15";
  src: url(~@/assets/fonts/Schrift_15.woff) format("woff");
}

@font-face {
  font-family: "Schrift_16";
  src: url(~@/assets/fonts/Schrift_16.woff) format("woff");
}

.font {
  font-size: 3em;
}

.font-1 {
  font-family: "Schrift_1" !important;
}

.font-2 {
  font-family: "Schrift_2" !important;
}

.font-3 {
  font-family: "Schrift_3" !important;
}

.font-4 {
  font-family: "Schrift_4" !important;
}

.font-5 {
  font-family: "Schrift_5" !important;
}

.font-6 {
  font-family: "Schrift_6" !important;
}

.font-7 {
  font-family: "Schrift_7" !important;
}

.font-8 {
  font-family: "Schrift_8" !important;
}

.font-9 {
  font-family: "Schrift_9" !important;
}

.font-10 {
  font-family: "Schrift_10" !important;
}

.font-11 {
  font-family: "Schrift_11" !important;
}

.font-12 {
  font-family: "Schrift_12" !important;
}

.font-13 {
  font-family: "Schrift_13" !important;
}

.font-14 {
  font-family: "Schrift_14" !important;
}

.font-15 {
  font-family: "Schrift_15" !important;
}

.font-16 {
  font-family: "Schrift_16" !important;
}

.missing {
  position: absolute;
  right: 21px;
  background: red;
  overflow: hidden;
  padding: 8px;
  color: white;
  border-radius: 7px;
  font-weight: bold;
}

.ready {
  position: absolute;
  right: 21px;
  background: #00a803;
  overflow: hidden;
  padding: 8px;
  color: white;
  border-radius: 7px;
  font-weight: bold;
}

.blink {
  animation: blinker 0.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
