<template>
  <div>
    <v-list dense>
      <v-select solo :items="filterOptions" v-model="filter"></v-select>
      <v-row align="center" class="px-3">
        <v-col>
          <v-subheader>Einträge: {{ filteredOrdersCount }}</v-subheader>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="showOnlyAmazon"
            label="Nur Amazon"
            hide-details
            dense
            class="mt-0"
          ></v-checkbox>
        </v-col>
      </v-row>

      <!-- Virtuelles Scrolling für bessere Performance -->
      <v-virtual-scroll
        :items="paginatedOrders"
        :height="600"
        item-height="150"
      >
        <template v-slot:default="{ item }">
          <v-list-item :key="item.orderId">
            <v-list-item-content>
              <!-- Header mit OrderID und Shop -->
              <v-list-item-title class="d-flex align-center mb-1">
                <img 
                  v-if="item.shop === 'amazon'" 
                  src="@/assets/amazon.png" 
                  height="12px"
                  class="mr-2"
                />
                <strong>
                  <a 
                    v-if="item.shop === 'amazon'" 
                    :href="'https://sellercentral.amazon.de/orders-v3/order/' + item.orderId"
                    target="_blank"
                  >
                    {{ item.orderId }}
                  </a>
                  <span v-else>{{ item.orderId }}</span>
                </strong>
                <span class="ml-2 grey--text text-caption">#{{ item.managedId }}</span>
                
                <!-- Status Chips -->
                <v-spacer></v-spacer>
                <v-chip 
                  v-if="item.speedmark" 
                  color="blue" 
                  small
                  class="mr-1"
                >
                  In Produktion
                </v-chip>
                <v-chip 
                  v-if="isReadyToPack(item.orderId)" 
                  color="green" 
                  small
                  class="mr-1"
                >
                  Bereit zum Packen
                </v-chip>
                <v-chip 
                  v-if="item.shipping?.type === 'EXPRESS'" 
                  color="red" 
                  small
                >
                  EXPRESS
                </v-chip>
              </v-list-item-title>

              <!-- Produkte -->
              <div v-for="product in item.products" :key="item.orderId + product.sku" class="d-flex align-center my-1">
                <img 
                  v-if="getImage(product.sku)" 
                  :src="getImage(product.sku)" 
                  height="40"
                  class="mr-2"
                />
                <div class="flex-grow-1">
                  <div class="d-flex justify-space-between">
                    <strong>{{ product.sku }}</strong>
                    <span class="ml-2">Menge: {{ product.qty }}</span>
                  </div>
                  <div class="text-caption" v-if="product.attributes">
                    <template v-for="(value, key) in product.attributes" :key="key">
                      {{ key }}: <span v-html="formatValue(value)"></span>
                      <br>
                    </template>
                  </div>
                </div>
              </div>

              <!-- Versanddatum -->
              <v-list-item-subtitle class="mt-1 text-caption">
                Versand am: {{ getShippmentDate(item.shipping?.lastShippmentDate || item.shipping?.shippmentDate) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>

      <!-- Load More Button -->
      <v-btn
        v-if="hasMoreOrders"
        block
        color="primary"
        @click="loadMore"
        class="mt-3"
        :loading="loading"
      >
        Weitere Bestellungen laden ({{ remainingOrders }} übrig)
      </v-btn>
    </v-list>

    <!-- Prime Orders Zusammenfassung -->
    <v-expansion-panels class="mt-4">
      <v-expansion-panel>
        <v-expansion-panel-header class="title">
          Prime Bestellungen Übersicht
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
              <!-- Offen -->
              <v-col cols="12" md="12">
              <v-card outlined>
                <v-card-title class="subtitle-1 red--text">
                  Noch zu produzieren
                </v-card-title>
                <v-card-text>
                  <v-list dense>
                    <v-list-item v-for="item in primeOrdersSummary" :key="'open-'+item.sku" v-if="item.pending > 0">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.sku }}</v-list-item-title>
                        <v-list-item-subtitle>
                          <v-chip color="red" small>{{ item.pending }} Stück</v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          
            <!-- Bereit zum Packen -->
            <v-col cols="12" md="12">
              <v-card outlined>
                <v-card-title class="subtitle-1 green--text">
                  Bereit zum Packen
                </v-card-title>
                <v-card-text>
                  <v-list dense>
                    <v-list-item v-for="item in primeOrdersSummary" :key="'pack-'+item.sku" v-if="item.readyToPack > 0">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.sku }}</v-list-item-title>
                        <v-list-item-subtitle>
                          <v-chip color="green" small>{{ item.readyToPack }} Stück</v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>

            <!-- Produktionskarte -->
            <v-col cols="12" md="12">
              <v-card outlined>
                <v-card-title class="subtitle-1 blue--text">
                  In Produktion
                </v-card-title>
                <v-card-text>
                  <v-list dense>
                    <v-list-item v-for="item in primeOrdersSummary" :key="'prod-'+item.sku" v-if="item.inProduction > 0">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.sku }}</v-list-item-title>
                        <v-list-item-subtitle>
                          <v-chip color="blue" small>{{ item.inProduction }} Stück</v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>

          

          <!-- Gesamtübersicht -->
          <v-card outlined class="mt-4">
            <v-card-title class="subtitle-1">
              Gesamtübersicht Prime Bestellungen
            </v-card-title>
            <v-card-text>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>SKU</th>
                      <th class="text-right">Gesamt</th>
                      <th class="text-right">In Produktion</th>
                      <th class="text-right">Bereit zum Packen</th>
                      <th class="text-right">Offen</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in primeOrdersSummary" :key="item.sku">
                      <td><strong>{{ item.sku }}</strong></td>
                      <td class="text-right">{{ item.total }}</td>
                      <td class="text-right">
                        <v-chip color="blue" x-small v-if="item.inProduction > 0">{{ item.inProduction }}</v-chip>
                        <span v-else>-</span>
                      </td>
                      <td class="text-right">
                        <v-chip color="green" x-small v-if="item.readyToPack > 0">{{ item.readyToPack }}</v-chip>
                        <span v-else>-</span>
                      </td>
                      <td class="text-right">
                        <v-chip color="red" x-small v-if="item.pending > 0">{{ item.pending }}</v-chip>
                        <span v-else>-</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import moment from "moment";
import images from "../../../articleImages.js";  // Stellen Sie sicher, dass der Pfad korrekt ist

export default {
  name: "orderList",
  props: {
    orders: {
      type: Array,
      required: true,
    },
    packlist: {
      type: Array,
      required: false,
    }
  },
  
  data: () => ({
    filter: null,
    filterOptions: [],
    showOnlyAmazon: true,
    pageSize: 50,
    currentPage: 1,
    loading: false
  }),

  computed: {
    filteredOrders() {
      let filtered = this.orders.filter(x => x.shipping != null);
      
      if (this.filter) {
        filtered = filtered.filter(x => 
          x.products.some(p => p.sku === this.filter)
        );
      }
      
      if (this.showOnlyAmazon) {
        filtered = filtered.filter(x => 
          x.shop === 'amazon' || x.acsCustomer === "Shirtracer"
        );
      }
      
      return filtered;
    },

    filteredOrdersCount() {
      return this.filteredOrders.length;
    },

    paginatedOrders() {
      const start = 0;
      const end = this.currentPage * this.pageSize;
      return this.filteredOrders.slice(start, end);
    },

    hasMoreOrders() {
      return this.filteredOrders.length > this.currentPage * this.pageSize;
    },

    remainingOrders() {
      return this.filteredOrders.length - (this.currentPage * this.pageSize);
    },

    primeOrdersSummary() {
      // Nur Prime-Bestellungen filtern
      const primeOrders = this.orders.filter(order => 
        order.shipping?.type === 'PRIME'
      );
      
      // SKUs sammeln und zusammenfassen
      const summary = {};
      
      primeOrders.forEach(order => {
        order.products.forEach(product => {
          if (!summary[product.sku]) {
            summary[product.sku] = {
              sku: product.sku,
              total: 0,
              inProduction: 0,
              readyToPack: 0,
              pending: 0
            };
          }
          
          const qty = product.qty || 1;
          summary[product.sku].total += qty;
          
          if (this.isReadyToPack(order.orderId)) {
            summary[product.sku].readyToPack += qty;
          } else if (order.speedmark) {
            summary[product.sku].inProduction += qty;
          } else {
            summary[product.sku].pending += qty;
          }
        });
      });
      
      // In Array umwandeln und nach SKU sortieren
      return Object.values(summary).sort((a, b) => a.sku.localeCompare(b.sku));
    }
  },

  methods: {
    loadMore() {
      this.loading = true;
      setTimeout(() => {
        this.currentPage++;
        this.loading = false;
      }, 100);
    },

    isReadyToPack(orderId) {
      return this.packlist?.some(x => x.orderId === orderId) || false;
    },

    getShippmentDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },

    getImage(sku) {
      return images[sku];
    },

    formatValue(value) {
      if (typeof value === 'string' && value.includes('http')) {
        // Extrahiere den Dateinamen aus der URL
        const fileName = value.split('/').pop();
        return `<a href="${value}" target="_blank">${fileName.toString().slice(0, 20)}...</a>`;
      }
      return `<strong>${value.toString().slice(0, 20)}</strong>`;
    }
  },

  watch: {
    orders: {
      immediate: true,
      handler(newOrders) {
        if (newOrders?.length) {
          // SKUs für Filter extrahieren
          const skus = [...new Set(
            newOrders.flatMap(o => 
              o.products?.map(p => p.sku) || []
            )
          )];
          this.filterOptions = ["", ...skus.sort()];
        }
        // Reset pagination
        this.currentPage = 1;
      }
    },

    filter() {
      this.currentPage = 1;
    },

    showOnlyAmazon() {
      this.currentPage = 1;
    }
  }
};
</script>

<style scoped>
.product-item {
  margin: 8px 0;
}

.product-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.attributes {
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.6);
}

.shipping-info {
  margin-top: 8px;
}

.status-chips {
  margin-top: 4px;
}

/* Neue Styles */
.v-card {
  transition: none !important; /* Verhindert Animations-Lag beim Scrollen */
}

.v-card__text {
  padding: 8px !important;
}
</style>