
<template>
  <div>
    <Header />
    <v-container>
      <v-layout row wrap justify-center fill-height>
        <v-flex md12>
          <h1 class="display-1">
            <v-icon @click="$router.go(-1)" large class="mr-2">arrow_back</v-icon>Bestellungen
          </h1>

        <!--<v-btn @click="generate">Download</v-btn>

          <v-btn class="secondary" small @click="packlist.dialog = true">
            Packliste anlegen</v-btn
          >

          <v-btn class="secondary" small @click="shipping.dialog = true"
            >Versandvorbereitung</v-btn
          >

              -->


          <v-row>
            <v-col xs="12" sm="12" md="3" lg="3" v-bind:key="item.orderId" v-for="item of items" class="pa-2">
              <v-card flat tile class="elevation-2">
                <v-card-text>
                  <h2>{{ item.orderId }}</h2>
                  <v-row>

                    <v-col cols="12">
                      <v-chip v-if="item.status == 'PENDING'" class="ma-2" color="red" text-color="white">
                        WARTE AUF FREIGABE
                      </v-chip>

                      <v-chip v-else class="ma-2" color="green" text-color="white">
                        FREIGEGEBEN
                      </v-chip>

                    </v-col>
                    <v-col cols="12"><v-img :src="item.line1" max-height="300px" max-width="300px" width="auto"></v-img></v-col>
                    <v-col cols="12">
                      <v-text-field label="Zeile 1" placeholder="Zeile1" outlined :value="item.line2"></v-text-field>

                      <v-text-field label="Schriftart" placeholder="Schriftart" outlined :value="item.font"></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-btn :href="item.line1" color="success">Herunterladen</v-btn>
                    </v-col>

                    <v-col cols="12" v-if="user && user.role == 'admin' && item.status == 'PENDING'">
                      <v-btn color="primary" @click="release(item)">Freigeben</v-btn>
                    </v-col>

                  </v-row>


                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>

    <v-dialog v-model="shipping.dialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Versandvorbereitung?</v-card-title>

        <v-card-text v-if="shipping.dialog">
          Möchtest du wirkliche alle Bestellungen in dieser Tabelle in den
          Versandvorbereitungsstatus übertragen?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="success" text @click="shipping.dialog = false">Abbrechen</v-btn>

          <v-btn color="success" text @click="
            shipping.dialog = false;
          createShipping();
                                    ">Anlegen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="shipping.success" max-width="400">
      <v-card color="success" dark>
        <v-card-title class="headline">Versandvorbereitung erfolgreich!</v-card-title>

        <v-card-text>Deine Bestellungen wurde in den Versandstatus übertragen</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="shipping.success = false">Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="success" dark>
        <v-card-text>
          Daten werden geladen
          <v-progress-linear v-model="percent" :indeterminate="percent == null" :query="true" color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="packlist.dialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Packliste erstellen?</v-card-title>

        <v-card-text v-if="packlist.dialog">
          Möchtest du wirkliche eine Packliste für folgende Artikel erstellen?
          <br />
          <h1>
            <strong>{{ packlist.name }}</strong>
          </h1>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="success" text @click="packlist.dialog = false">Abbrechen</v-btn>

          <v-btn color="success" text @click="
            packlist.dialog = false;
          createPacklist();
          packlist.name = null;
                                    ">Anlegen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="shipping.dialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Versandvorbereitung?</v-card-title>

        <v-card-text v-if="shipping.dialog">
          Möchtest du wirkliche alle Bestellungen in dieser Tabelle in den
          Versandvorbereitungsstatus übertragen?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="success" text @click="shipping.dialog = false">Abbrechen</v-btn>

          <v-btn color="success" text @click="
            shipping.dialog = false;
          createShipping();
                                    ">Anlegen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="packlist.success" max-width="400">
      <v-card color="success" dark>
        <v-card-title class="headline">Packliste wurde erstellt!</v-card-title>

        <v-card-text>Deine Packliste wurde erstellt</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="packlist.success = false">Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Header from "../header";
import productionService from "@/services/ProductionService";
import AuthService from "@/services/AuthService";

import router from "@/router";
import fileDownload from "js-file-download";
import JSZip from "jszip";

export default {
  name: "productionItem",
  components: {
    Header,
  },
  data: () => ({
    loading: true,
    percent: 0,
    user: null,
    itemNames: [],
    items: [],
    tableHeaders: [],
    shipping: {
      dialog: false,
      success: false,
      name: "",
    },

    packlist: {
      dialog: false,
      success: false,
      name: "",
    },
  }),
  create() { },
  async mounted() {
    this.refresh();
  },
  methods: {
    async generate(page = 0) {
      let data = (await productionService.generatePhotoAMZZippo()).data;
      const zip = new JSZip();
      await zip.loadAsync(data, { base64: true });
      const blob = await zip.generateAsync({ type: "blob" });
      fileDownload(blob, "Amz-Photozippo.zip", "application/zip");
    },

    async createShipping() {
      try {
        this.loading = true;
        let data = (await productionService.createShipping("photozippo_amz"))
          .data;
        this.shipping.success = true;
      } catch (e) {
        this.error = e.message;
      } finally {
        this.loading = false;
      }
    },

    async createPacklist() {
      try {
        let data = (await productionService.createPacklist("photozippo_amz"))
          .data;
        this.packlist.success = true;
      } catch (e) {
        this.error = e.message;
      }
    },

    async release(item) {
      try {
        await productionService.releaseState("photozippo_amz", item.id);
        this.refresh();
      } catch (err) {
        this.error = err.message;
      }
    },

    async refresh() {
      this.loading = true;
      this.itemNames = null;
      this.items = [];
      try {
        this.user = (await AuthService.getUser()).data;
        let data = (await productionService.getItem("photozippo_amz")).data;
        this.itemNames = Object.keys(data[0]);
        this.items = data;

        this.loading = false;
      } catch (err) {
        console.log(err);
        if (err.response.status == 401) {
          router.replace("/auth/login");
        } else {
          console.log(err);
        }
      }
    },
    customSort(items, index, isDescending) {
      items.sort((a, b) => {
        if (index === "count") {
          return b.count - a.count;
        }
      });

      return items;
    },
  },
  breforeDestroy() { },
};
</script>
