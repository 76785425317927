
<template>
    <div>
        <Header />
        <v-container style="max-width: 100%">
            <!-- OrderID Search Bar -->
            <v-row>
                <v-col cols="12" v-if="error">
                    <v-alert type="error">{{ error }}</v-alert>
                </v-col>

                <v-col cols="3" style="position: fixed; left: 0px; overflow: scroll; height: 90%;">

                    


                    <v-card-title>Nachrichten ({{ tickets ? tickets.length : "Wird geladen"}})</v-card-title>
                    <v-card-text>
                        <v-list three-line>
                            <template v-for="(email, index) in tickets" :key="index">
                                <v-list-item @click="loadTicket(email.id)">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ formatDate(email.created_at) }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ email.subject }}</v-list-item-subtitle>
                                        <v-list-item-subtitle>{{ formatTimeLeft(email.time_left_to_reply)
                                        }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <a :href="email.uri" target="_blank">Link</a>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-divider></v-divider>
                            </template>
                        </v-list>
                    </v-card-text>
                </v-col>

                <v-col cols="5" style="position: fixed; left: 25%; overflow: scroll; height: 90%;">
                    <v-row v-if="ticket && ticket.messages">

                        <v-col cols="12">
                            <!-- Green Button to Close ticket with Check Mark -->
                            <v-btn color="success" @click="closeTicket(ticket.id)">Ticket schließen</v-btn>
                            <v-text-field v-if="!order" label="Bestellnummer" v-model="ticket.orderId" @keyup.enter="loadOrder" />
                        </v-col>
                        <v-col cols="12" v-for="message in ticket.messages" :key="message.id">

                            <v-card :class="message.direction">
                                <v-card-title>{{ message.direction == "Incoming" ? "Kunde" : "Mitarbeiter" }} am {{
                                    formatDate(message.created_at * 1000) }}</v-card-title>
                                <v-card-text>
                                    <div v-html="message.body"></div>
                                    <!-- Attachments -->
                                    <v-row v-if="message.attachments && message.attachments.length > 0">
                                        <v-col cols="12">
                                            <v-card-title>Anhänge</v-card-title>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12" v-for="attachment in message.attachments"
                                                        :key="attachment.id">
                                                        <v-btn :href="attachment.link" target="_blank">{{ attachment.name
                                                        }}</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>

                        </v-col>
                    </v-row>

                    <!-- empty -->
                    <v-row v-else>
                        <v-col cols="12">
                            <v-card>
                                <v-card-title>Keine Nachrichten</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-alert type="info">Keine Nachrichten vorhanden</v-alert>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-card v-if="ticket">
                        <v-card-title>Antworten</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <v-textarea v-model="reply"></v-textarea>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-actions>
                            <v-btn color="primary" @click="sendReply">Antworten</v-btn>
                        </v-card-actions>

                    </v-card>

                </v-col>


                <v-col cols="4" v-if="order" style="position:fixed; right: 0; height: 90%; overflow:scroll">
                    <v-card-title>Details {{ order.orderId }}</v-card-title>

                    <v-card>
                        <v-card-title primary-title> Adresse </v-card-title>
                        <v-card-text>
                            {{ order.customer.firstName }} {{ order.customer.lastName }}<br />
                            {{ order.customer.adress }}<br />
                            {{ order.customer.zip }} {{ order.customer.city }}<br />
                        </v-card-text>
                    </v-card>
                    <v-card>
                        <v-card-title primary-title>
                            Status {{ order.managedId }}</v-card-title>
                        <v-card-text>
                            <v-chip>{{ order.state }}</v-chip>
                            <v-chip v-if="order.plentyOrder">{{ order.plentyOrder.statusName }}</v-chip>

                            <v-text-field label="Asana Task Template Title" model-value="{{ order.plentyId }} | {{ order.orderId }} | Grund"></v-text-field>

                            <!-- Cacel Order Button if order.state is UNSHIPPED -->
                            <v-btn v-if="order.state == 'UNSHIPPED'" color="error" @click="cancelOrder(order.plentyId)">
                                Bestellung stornieren</v-btn>
                        </v-card-text>
                    </v-card>
                    <v-card>
                        <v-card-title primary-title> Warenkorb </v-card-title>
                        <v-card-text>
                            <v-card class="mx-auto" outlined v-for="p of order.products" :key="order.products.indexOf(p)">
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <div class="text-overline mb-4">Menge: {{ p.qty }}</div>
                                        <v-list-item-title class="text-h5 mb-1">
                                            {{ p.sku }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-if="p.attributes && Object.keys(p.attributes).length > 0
                                            ">
                                            <v-simple-table>
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">Name</th>
                                                            <th class="text-left">Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="a of Object.keys(p.attributes)" :key="a">
                                                            <td>{{ a }}</td>
                                                            <td>
                                                                <v-img v-if="p.attributes[a].indexOf('.png') > -1"
                                                                    :src="p.attributes[a]" width="100" />
                                                                <strong v-else>{{ p.attributes[a] }}</strong>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                        </v-card-text>
                    </v-card>



                    <v-card v-if="order.plentyOrder && order.plentyOrder.shippingPackages.length > 0">
                        <v-card-title primary-title> Paketnummer </v-card-title>
                        <v-card-text>
                            <v-alert dense outlined text v-for="s of order.plentyOrder.shippingPackages" :key="s.id">
                                {{ s.packageNumber }}
                            </v-alert>
                        </v-card-text>
                    </v-card>
                    <v-card v-if="order.retoure.length > 0">
                        <v-card-title primary-title> Retoure </v-card-title>
                        <v-card-text>
                            <v-alert dense outlined text type="warning">
                                Das Paket befindet sich in der Retourenabteilung.
                            </v-alert>

                            <v-simple-table v-for="retoure of order.retoure" :key="retoure.date">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">Name</th>
                                            <th class="text-left">Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Datum</td>
                                            <td>{{ new Date(retoure.date).toLocaleString() }}</td>
                                        </tr>
                                        <tr>
                                            <td>Typ</td>
                                            <td>{{ retoure.type }}</td>
                                        </tr>
                                        <tr>
                                            <td>Grund</td>
                                            <td>{{ retoure.reason }}</td>
                                        </tr>
                                        <tr>
                                            <td>Neu Versand</td>
                                            <td>{{ retoure.reshipment ? retoure.reshipment : '-' }}</td>
                                        </tr>
                                        <tr>
                                            <td>Entsorgt</td>
                                            <td>{{ retoure.trash == 0 ? 'NEIN' : 'JA' }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>

                    <v-card :loading="loading">
                        <v-card-title primary-title> Tracking Verlauf </v-card-title>
                        <v-card-text v-if="tracking">
                            <DHLTracking v-if="tracking.carrier == 'dhl'" :tracking="tracking" />
                            <DPDTracking v-if="tracking.carrier == 'dpd'" :tracking="tracking" />
                        </v-card-text>
                        <v-alert v-else type="warning" :value="true">
                            Kein Tracking verfügbar.
                        </v-alert>
                    </v-card>


                   

            </v-row>

        </v-container>



        <LoadingDialog />
    </div>
</template>
  
<script>
import Header from "../header";
import service from "@/services/CustomerSupportService";

import DHLTracking from "@/components/DHLTracking";
import DPDTracking from "@/components/DPDTracking";

import LoadingDialog from "../production/components/LoadingDialog";

import EventBus from "@/event-bus";
import fileDownload from "js-file-download";

export default {
    name: "dashboard",
    components: {
        Header,
        DHLTracking,
        DPDTracking,
        LoadingDialog,
    },
    data: () => ({
        loading: false,
        tickets: [],
        ticket: null,
        attachments: [],
        reply: ``,
        signature: `Mit freundlichen Grüßen
Ihr Kundenservice

Accelery GmbH
Bleichenbrücke 11
20354 Hamburg
Deutschland

eingetragen im Handelsregister des Amtsgerichtes Hamburg
Handelsregister Nummer: HRB 153058
​
Umsatzsteuer-Identifikationsnummer: DE815779986`,
        ticketHeaders: [
            { text: 'Betreff', value: 'subject' },
            { text: 'Erstellt am', value: 'created_at' },
            { text: 'Status', value: 'status' },
            { text: 'Verbleibende Zeit', value: 'time_left_to_reply' },
        ],

        order: null,
        tracking: null,

    }),
    create() { },
    async mounted() {
        await this.loadTickets();
    },
    methods: {
        async loadTickets() {
            EventBus.$emit("LOADING_START");
            this.tickets = (await service.loadTickets()).data;
            console.log(this.tickets);
            EventBus.$emit("LOADING_END");
        },

        async loadTicket(ticketId) {
            this.loading = true;
            EventBus.$emit("LOADING_START");
            try {
                this.ticket = (await service.loadTicket(ticketId)).data;
                console.log(this.ticket);
                this.order = null;
                this.reply = "Guten Tag \n\n" + this.ticket.reply + "\n\n" + this.signature;

                if (this.ticket.orderId) {
                    await this.loadOrder();
                }
                this.loading = false;
                EventBus.$emit("LOADING_END");
            } catch (err) { }
        },

        async getTracking() {
            this.loading = true;
            try {
                this.tracking = (
                    await service.getTrackingState(this.order.plentyOrder)
                ).data;
                this.loading = false;
            } catch (err) { }
        },

        async closeTicket(ticketId) {
            this.loading = true;
            try {
                await service.closeTicket(ticketId);
                this.ticket = null;
                setTimeout(this.loadTickets(), 1000);
                
                this.loading = false;
            } catch (err) { }
        },

        async cancelOrder(orderId) {
            this.loading = true;
            EventBus.$emit("LOADING_START");
            try {
                await service.cancelOrder(orderId);
                await this.loadOrder();
                EventBus.$emit("LOADING_END");
                this.loading = false;
            } catch (err) { }
        },


        async loadOrder() {
            try {
                if (!this.ticket.orderId) return;
                EventBus.$emit("LOADING_START");
                this.tracking = null;
                
                this.order = (await service.getMongoOrder(this.ticket.orderId)).data;
                EventBus.$emit("LOADING_END");

                this.reply = "Guten Tag " + this.order.customer.firstName + " " + this.order.customer.lastName + "\n\n" + this.ticket.reply + "\n\n" + this.signature;
                if (this.order) {
                    this.getTracking();
                }
            } catch (err) {
                this.error = "Bestellung konnte nicht gefunden werden!";
                EventBus.$emit("LOADING_END");
                setTimeout(function () {
                    this.error = null;
                }, 3500);
            }
        },
    

        async sendReply() {
            let ticketId = this.ticket.id;
            let reply = this.reply;
            let attachments = this.attachments;

            this.loading = true;
            service
                .replyToTicket(ticketId, reply, attachments)
                .then((res) => {
                    this.ticket.reply = "";
                    this.attachments = [];
                    this.loadTicket(ticketId);
                    this.loadTickets();
                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                });
        },



        formatDate(dateString) {
            const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
            return new Date(dateString).toLocaleString('de-DE', options);
        },
        formatTimeLeft(timeInSeconds) {
            let hours = Math.floor(timeInSeconds / 3600);
            let minutes = Math.floor((timeInSeconds - (hours * 3600)) / 60);
            let seconds = timeInSeconds - (hours * 3600) - (minutes * 60);

            return `${hours} Stunde(n) ${minutes} Minute(n) ${seconds} Sekunde(n)`;
        },
    },
    breforeDestroy() { },
};
</script>
  
<style scoped>
.elevation-1 {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .2), 0 3px 1px -2px rgba(0, 0, 0, .12), 0 1px 5px 0 rgba(0, 0, 0, .14);
}


.Incoming {
    background-color: #e0e0e0;
    margin-bottom: 10px;
}

.Outgoing {
    background-color: #a0d5cd;
    margin-bottom: 10px;
}
</style>