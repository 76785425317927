
<template>
  <div>
    <Header />
    <v-container style="max-width: 100%">
      <!-- OrderID date Bar -->
      <v-row>
        <v-col cols="6">
          <v-card-text>
            <v-text-field v-model="date" label="Datum" type="date" outlined @change="loadDataByDate()"
              @keydown.enter="loadDataByDate()"></v-text-field>
          </v-card-text>
        </v-col>

        <v-col cols="6" v-if="inventory && inventory.length > 0">
          <v-card>
            <v-card-title class="justify-center">{{ inventoryWorth }}</v-card-title>
            <v-card-subtitle text-align="center">Gesamt Wert</v-card-subtitle>
            <v-card-actions>
              <v-btn color="primary" text @click="downloadCSV()">Download CSV</v-btn>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="error">
          <v-alert type="error">{{ error }}</v-alert>
        </v-col>
        <v-row>

          <v-col cols="12">
            <!-- Data Table of inventory -->
            <!-- 
              [  {
    "itemId": 34490451,
    "variationId": 3208,
    "sku": "knig_lwen_6teilig",
    "image": "http://img.de/image.png"
    "name": "Kinderbesteck Set 6 LION",
    "stock": 584,
    "currentPrice": "30,31",
    "worth": 17701.04
  },..]

            -->
            <v-data-table :headers="headers" :items="inventory" :items-per-page="400" style="width:100%"
              :expanded.sync="expanded" show-expand :single-expand="true" item-key="variationId">
              <template v-slot:item.image="{ item }">
                <v-img :src="item.image" width="60" height="60"></v-img>
              </template>

              <template v-slot:item.sku="{ item }">
                <v-chip color="green" small class="mr-1" outlined pill v-for="sku of [...new Set(item.sku)]">{{ sku
                }}</v-chip>
              </template>

              <template v-slot:item.currentPrice="{ item }">
                {{ formatCurrency(parseFloat(item.currentPrice.replace(",", "."))) }}
              </template>
              <template v-slot:item.worth="{ item }">
                <strong>{{ formatCurrency(item.worth) }}</strong>
              </template>

              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <!-- Apexchart with variationId History -->
                  <div v-if="isExpanded(item.variationId)">
                    <apexchart height="500" width="100%" type="area" :options="options"
                      :series="series[item.variationId]"></apexchart>
                  </div>
                </td>
              </template>

              <template v-slot:item.share="{ item }">
                <v-progress-circular :size="50" :width="5" :value="item.share" color="primary">
                  {{ item.share.toFixed(2) }}%
                </v-progress-circular>

              </template>

            </v-data-table>

          </v-col>
        </v-row>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Header from "./header";
import service from "@/services/InventoryService";

export default {
  name: "dashboard",
  components: {
    Header,
  },
  data: () => ({
    loading: false,
    date: null,
    inventory: [],
    error: null,
    expanded: [],
    series: {},
    options: {
      chart: {
        type: 'area',
        height: 400,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          autoSelected: 'zoom'
        }
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0,
      },
      title: {
        text: 'Warenbestandsverlauf',
        align: 'left'
      },

      yaxis: {
        title: {
          text: 'Bestand'
        },
      },
      xaxis: {
        type: 'datetime',
      },
      tooltip: {
        shared: false,
      }
    },
    headers: [
      { text: "Item ID", value: "itemId" },
      { text: "Variation ID", value: "variationId" },
      { text: "SKU", value: "sku", width: "100px" },
      { text: "Image", value: "image" },
      { text: "Name", value: "name", width: "200px" },
      { text: "Stock", value: "stock" },
      { text: "Current Price", value: "currentPrice" },
      { text: "Worth", value: "worth" },
      { text: "Anteil", value: "share" }
    ],
    inventoryWorth: 0,
  }),
  create() { },
  async mounted() {
    this.loadDataByDate();

  },
  methods: {
    downloadCSV() {
      //convert the inventory to csv
      let csv = "Item ID;Variation ID;SKU;Name;Stock;Current Price;Worth\n";
      this.inventory.forEach((item) => {
        csv += `${item.itemId};${item.variationId};${item.sku};${item.name};${item.stock};${item.currentPrice.toString().replace(".", ",")};${item.worth.toString().replace(".", ",")}\n`;
      });

      //download the csv
      let blob = new Blob([csv], { type: "text/csv" });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = "inventory.csv";
      a.click();

    },

    isExpanded(variationId) {
      let expanded =  this.expanded.filter(x => x.variationId == variationId).length > 0;
      if(expanded) {
        this.loadHistory(variationId);
      }
      return expanded;
    },

    async loadDataByDate() {
      try {
        if (this.date == null) {
          this.date = new Date()
        }

        let data = (await service.getInventoryByDate(this.date)).data;

        this.inventory = data;
        let worth = this.inventory.reduce((a, b) => a + b.worth, 0);
        this.inventoryWorth = Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(worth);

        //calculate share of worth
        this.inventory.forEach((item) => {
          item.share = (item.worth / worth) * 100;
        });

        this.error = null;
      } catch (err) {
        console.log(err);
        this.error = "Inventar konnte nicht gefunden werden!";
        setTimeout(function () {
          this.error = null;
        }, 1000);
      }
    },

    async loadHistory(variationId) {
      let history = (await service.getHistoryByVariationId(variationId)).data;

      let data = [];
      history.forEach((item) => {
        data.push([new Date(item.date).getTime(), item.phy]);
      });

      // Update the series for this variationId
      this.$set(this.series, variationId, [{
        name: "Warenbestand",
        data: data
      }]);
    },

    formatCurrency(value) {
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(value);
    },

  },
  breforeDestroy() { },
};
</script>

<style scoped>
.v-card,
.v-btn {
  margin-bottom: 10px;
}
</style>