import Api from '@/services/Api'

export default {
  /**
   * Fire Login Request.
   * @param {Object} payload {user: "", password: ""}
   */
  login (payload) {
    return Api().post('auth/login', payload)
  },

  logout() {
    localStorage.removeItem('token');
    return Api().post("auth/logout")
  },

  getUser() {
    return Api().get('user')
  },

  isAuthenticated() {
    return !!localStorage.getItem('token');
  }
}