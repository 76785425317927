import Api from '@/services/Api'

export default {
  // Muckelmeister Routes
  getMuckelmeisterOrders() {
    return Api().get('/importer/muckelmeister/orders')
  },
  importMuckelmeisterOrder(orderId) {
    return Api().post('/importer/muckelmeister/orders/' + orderId)
  },
  getMuckelmeisterHealth() {
    return Api().get('/importer/muckelmeister/health')
  },

  // ACS Routes
  getACSOrders() {
    return Api().get('/importer/acs/orders')
  },
  importACSOrder(orderId) {
    return Api().post('/importer/acs/orders/' + orderId)
  },
  getACSHealth() {
    return Api().get('/importer/acs/health')
  },

  // Generic Routes
  getHealth(importer) {
    return Api().get(`/importer/${importer}/health`)
  },
  getMetrics(importer) {
    return Api().get(`/importer/${importer}/metrics`)
  },
  getOrders(importer) {
    return Api().get(`/importer/${importer}/orders`)
  },
  importOrder(importer, orderId) {
    return Api().post(`/importer/${importer}/orders/${orderId}`)
  }
}