
<template>
  <div>
    <Header />
    <v-container>
      <v-layout row wrap justify-center fill-height>
        <v-flex md12 mb-2 v-if="skus.length > -1">
          <v-card>
            <v-card-title>SKU Verteilung</v-card-title>
            <v-card-text>
              <div style="block" v-for="sku in skus">
                <h3>{{ sku.name }}: {{ sku.count }}</h3>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex md12>
          <v-card class="elevation-5">
            <v-card-text primary-title>
              <h1 class="display-1">
                <v-icon @click="$router.go(-1)" large class="mr-2"
                  >arrow_back</v-icon
                >Bestellungen
              </h1>

              <v-btn class="secondary" small @click="shipping.dialog = true"
                >Versandvorbereitung</v-btn
              >
              <v-data-table
                hide-default-footer
                :must-sort="true"
                :loading="loading"
                :items-per-page="-1"
                :headers="tableHeaders"
                :items="items"
              >
                <template v-slot:item.id="{ item }">
                  <td class="text-xs-left">{{ items.indexOf(item) }}</td>
                </template>
              </v-data-table>

              <v-btn @click="genereate()" primary>Grafik erzeugen (zip)</v-btn>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="success" dark>
        <v-card-text>
          Daten werden geladen
          <v-progress-linear
            v-model="percent"
            :indeterminate="percent == null"
            :query="true"
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="shipping.dialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Versandvorbereitung?</v-card-title>

        <v-card-text v-if="shipping.dialog">
          Möchtest du wirkliche alle Bestellungen in dieser Tabelle in den
          Versandvorbereitungsstatus übertragen?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="success" text @click="shipping.dialog = false"
            >Abbrechen</v-btn
          >

          <v-btn
            color="success"
            text
            @click="
              shipping.dialog = false;
              createShipping();
            "
            >Anlegen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="shipping.success" max-width="400">
      <v-card color="success" dark>
        <v-card-title class="headline"
          >Versandvorbereitung erfolgreich!</v-card-title
        >

        <v-card-text
          >Deine Bestellungen wurde in den Versandstatus übertragen</v-card-text
        >

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="shipping.success = false">Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Header from "../header";
import productionService from "@/services/ProductionService";
import router from "@/router";
import JSZip from "jszip";
import fileDownload from "js-file-download";

export default {
  name: "productionItem",
  components: {
    Header,
  },
  data: () => ({
    loading: true,
    percent: 0,
    itemNames: [],
    items: [],
    tableHeaders: [],
    skus: [],
    shipping: {
      dialog: false,
      success: false,
      name: "",
    },
  }),
  create() {},
  async mounted() {
    this.refresh();
  },
  methods: {
    async genereate(page = 0) {
      let data = (await productionService.generateDateKeychain(page)).data;
      const zip = new JSZip();
      await zip.loadAsync(data, { base64: true });
      const blob = await zip.generateAsync({ type: "blob" });
      fileDownload(blob, "Calendar.zip", "application/zip");
    },
    async createShipping() {
      try {
        this.loading = true;
        let data = (await service.createShipping(this.$route.params.name)).data;
        this.shipping.success = true;
      } catch (e) {
        this.error = e.message;
      } finally {
        this.loading = false;
      }
    },
    async refresh() {
      this.loading = true;
      this.itemNames = null;
      this.items = [];
      try {
        let data = (await productionService.getItem("date_keychain")).data;
        this.itemNames = Object.keys(data[0]);

        this.tableHeaders = [];
        for (let key of this.itemNames) {
          this.tableHeaders.push({
            text: key,
            value: key,
            align: "left",
            sortable: false,
          });
        }
        this.items = data;

        for (let item of this.items) {
          let sku = item.sku;
          if (!sku) continue;

          let isAdded = false;
          for (let s of this.skus) {
            if (s.name == sku) {
              isAdded = true;
              s.count++;
            }
          }

          if (!isAdded) this.skus.push({ name: sku, count: 1 });
        }

        this.loading = false;
      } catch (err) {
        console.log(err);
        if (err.response.status == 401) {
          router.replace("/auth/login");
        } else {
          console.log(err);
        }
      }
    },
  },
  breforeDestroy() {},
};
</script>
