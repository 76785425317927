
<template>
  <div>
    <Header />
    <v-container style="max-width: 100%">
      <!-- OrderID Search Bar -->
      <v-row>
        <v-col cols="12">
          <h1>Hallo {{ username }}</h1>
          <h2>Laser Historie</h2>
          <p>
            Mit der Reset-Taste kannst du die Bestellung wieder in Produktion
            geben. Mit der Fehlgravur-Funktion kannst du eine schiefe Gravur
            buchen.
          </p>
        </v-col>
        <v-col cols="12" v-if="error">
          <v-alert type="error">{{ error }}</v-alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" v-if="tray.length > 0">
          <v-card>
            <v-card-title>
              Die zuletzt gravierte Bestellung von Dir</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-data-table :headers="tableHeaders" :items="tray" :items-per-page="150" class="elevation-1">
                 

                    <template v-slot:item.trash="{ item }">
                      <v-btn  @click="trashOrder(item.order, false)">
                        Fehlgravur
                      </v-btn>
                    </template>

                    <template v-slot:item.reset="{ item }">
                      <v-btn  @click="trashOrder(item.order, true)">
                        Reset
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <DamagedDialog />
    <LoadingDialog />

  </div>
</template>
  
<script>
import Header from "./header";
import service from "@/services/PacklistService";
import AuthService from "@/services/AuthService";
import DamagedDialog from "./packlist_v2/DamagedDialog";
import LoadingDialog from "./production/components/LoadingDialog";

import EventBus from "@/event-bus";
import moment from "moment";

export default {
  name: "laserHistory",
  components: {
    Header,
    DamagedDialog,
    LoadingDialog
  },
  data: () => ({
    timer: null,
    username: "",
    tray: [],
    tableHeaders: [
      { text: "ID", value: "id" },
      { text: "Bestellnummer", value: "orderId" },
      { text: "Laserdatum", value: "laserEnd" },
      { text: "sku", value: "sku" },
      { text: "Line 1", value: "line1" },
      { text: "Line 2", value: "line2" },
      { text: "Motiv", value: "motiv" },
      { text: "Font", value: "font1" },
      { text: "Reset", value: "reset" },
      { text: "Fehlgravur", value: "trash" },
    ]

  }),
  create() { },
  async mounted() {
    this.username = (await AuthService.getUser()).data.user;
    this.timer = setInterval(this.refresh(), 10000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    async refresh() {
      try {
        let lastOrders = (await service.getLaserHistory()).data;

        let tray = [];
        if (lastOrders.length > 0) {
          let firstOrder = lastOrders.shift();
          tray.push(firstOrder);
          lastOrders = lastOrders.filter(x => x.table == firstOrder.table) // Filter for same table

          let next = lastOrders.shift();
          while (next) {
            let last = tray[tray.length - 1];
            if (new Date(last.laserStart).getTime() - new Date(next.laserEnd).getTime() < 5000) { // If the next order is within 5 seconds of the last order
              tray.push(next);
              next = lastOrders.shift();
            } else {
              next = false;
            }
          }

          for(let t of tray) {
            let orderId = t.orderId;
            t.order = (await service.getPacklistItem(orderId)).data;

            if(t.order.products.filter(x=> x.sku == t.sku).length > 0) {
              let item = t.order.products.filter(x=> x.sku == t.sku)[0];
              if(item.translated.line1) t.line1 = item.translated.line1;
              if(item.translated.line2) t.line2 = item.translated.line2;
              if(item.translated.motiv) t.motiv = item.translated.motiv;
              if(item.translated.font1) t.font1 = item.translated.font1;
            }
          }

          this.tray = tray;

        } else {
          this.error = "Du hast noch keine Bestellung graviert.";
        }
      } catch (error) {
        console.error(error);
        this.error = error;
      }
    },

    async trashOrder(order, resetOnly) {
      if (resetOnly) {
        EventBus.$emit("OPEN_DAMAGED_DIALOG_RESETONLY", order);
      } else {
        EventBus.$emit("OPEN_DAMAGED_DIALOG", order);
      }
    },
  },
};
</script>
  
<style scoped>

</style>