import Api from '@/services/Api'

export default {
  
  getOrder(orderId) {
    return Api().get(`order/${encodeURIComponent(orderId)}`);
  },

  getMongoOrder(orderId) {
    return Api().get(`mongo/order/${encodeURIComponent(orderId)}`);
  },

  getTrackingState(plentyOrder) {
    return Api().post("trackingState", plentyOrder);
  },

  loadTickets() {
    return Api().get("tickets");
  },

  loadTicket(ticketId) {
    return Api().get(`ticket/${ticketId}`);
  },

  closeTicket(ticketId) {
    return Api().post(`ticket/${ticketId}/close`);
  },
  

  replyToTicket(ticketId, message, attachments) {
    return Api().post(`ticket/${ticketId}/reply`, { message: message, attachments: attachments });
  },

  cancelOrder(orderId) {
    return Api().post(`cancelOrder/${orderId}`);
  },

}