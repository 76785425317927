<template>
  <div>
    <Header />
    <v-container style="max-width: 100%">
      <!-- OrderID Search Bar -->
      <v-row>
        <v-col cols="6">
          <v-card-text>
            <v-text-field v-model="search" label="Bestellnummer" outlined @keydown.enter="doSearch()"></v-text-field>
          </v-card-text>
        </v-col>
        <v-col cols="6">
          <v-card-text>
            <v-text-field v-model="searchCustom" label="Gravurtext" outlined @keydown.enter="doSearchCustom()"></v-text-field>
          </v-card-text>
        </v-col>

        <v-col cols="12" v-if="error">
          <v-alert type="error">{{ error }}</v-alert>
        </v-col>
        <v-col cols="12">
          <v-btn block color="primary" dark @click="bulkReklaDialog = true">
            Massen-Reklamationen
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="order">
        <v-col cols="4">
          <v-card>
            <v-card-title primary-title> Adresse </v-card-title>
            <v-card-text>
              {{ order.customer.firstName }} {{ order.customer.lastName }}<br />
              {{ order.customer.adress }}<br />
              {{ order.customer.zip }} {{ order.customer.city }}<br />
            </v-card-text>
          </v-card>
          <v-card>
            <v-card-title primary-title>
              Status {{ order.managedId }} BillBee</v-card-title>
            <v-card-text>
              <v-chip v-if="order.shippingProfile && order.shippingProfile.backendName">{{ order.shippingProfile.backendName }}</v-chip>
              <v-chip>{{ order.state }}</v-chip>
              <v-chip v-if="order.plentyOrder">{{ order.plentyOrder.statusName }}</v-chip>
            </v-card-text>
          </v-card>
          <v-card>
            <v-card-title primary-title> Warenkorb </v-card-title>
            <v-card-text>
              <v-card class="mx-auto" outlined v-for="p of order.products" :key="order.products.indexOf(p)">
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">Menge: {{ p.qty }} <span v-if="p.orderItemId">#{{ p.orderItemId }}</span></div>
                    <v-list-item-title class="text-h5 mb-1">
                      {{ p.sku }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="p.attributes && Object.keys(p.attributes).length > 0
                    ">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Name</th>
                              <th class="text-left">Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="a of Object.keys(p.attributes)" :key="a">
                              <td>{{ a }}</td>
                              <td>
                                <v-img v-if="p.attributes[a] && p.attributes[a].toString().indexOf('.png') > -1 || p.attributes[a].toString().indexOf('.jpg') > -1" :src="p.attributes[a]" width="100" />
                                <strong v-else>{{ p.attributes[a] }}</strong>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="5">
          <v-card v-if="order.plentyOrder && order.plentyOrder.shippingPackages.length > 0">
            <v-card-title primary-title> Paketnummer </v-card-title>
            <v-card-text>
              <v-alert dense outlined text v-for="s of order.plentyOrder.shippingPackages" :key="s.id">
                {{ s.packageNumber }}
              </v-alert>
            </v-card-text>
          </v-card>
          <v-card v-if="order.retoure.length > 0">
            <v-card-title primary-title> Retoure </v-card-title>
            <v-card-text>
              <v-alert dense outlined text type="warning">
                Das Paket befindet sich in der Retourenabteilung.
              </v-alert>

              <v-simple-table v-for="retoure of order.retoure" :key="retoure.date">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Name</th>
                      <th class="text-left">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Datum</td>
                      <td>{{ new Date(retoure.date).toLocaleString() }}</td>
                    </tr>
                    <tr>
                      <td>Typ</td>
                      <td>{{ retoure.type }}</td>
                    </tr>
                    <tr>
                      <td>Grund</td>
                      <td>{{ retoure.reason }}</td>
                    </tr>
                    <tr>
                      <td>Neu Versand</td>
                      <td>{{ retoure.reshipment ? retoure.reshipment : '-' }}</td>
                    </tr>
                    <tr>
                      <td>Entsorgt</td>
                      <td>{{ retoure.trash == 0 ? 'NEIN' : 'JA' }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>


          <v-card :loading="loading">
            <v-card-title primary-title> Tracking Verlauf </v-card-title>
            <v-card-text v-if="tracking">
              <DHLTracking v-if="tracking.carrier == 'dhl'" :tracking="tracking" />
              <DPDTracking v-if="tracking.carrier == 'dpd'" :tracking="tracking" />
            </v-card-text>
            <v-alert v-else type="warning" :value="true">
              Kein Tracking verfügbar.
            </v-alert>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card>
            <v-card-title primary-title> Aktionen </v-card-title>
            <v-card-text>
              <v-alert
                v-if="isOldAmazonOrder"
                type="warning"
                dense
                text
              >
                Achtung: Diese Amazon-Bestellung ist älter als 40 Tage. Die Kundendaten wurden möglicherweise bereits anonymisiert.
              </v-alert>
              <v-btn block color="primary" dark @click="loadReklaDialog()">Reklamation einleiten</v-btn>
              <v-btn 
                block 
                color="primary" 
                dark 
                @click="openPrinterDialog(order.billbeeOrder.Data.ShippingIds ? order.billbeeOrder.Data.ShippingIds[0] : null)"
                v-if="order && order.billbeeOrder && order.billbeeOrder.Data"
              >
                Versandschein drucken
              </v-btn>
            </v-card-text>
          </v-card>
          <v-card v-if="order.claims">
            <v-card-title primary-title> Bestehende Reklamationen </v-card-title>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">ERP ID</th>
                      <th class="text-left">Bestellnummer</th>
                      <th class="text-left">Datum</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="claim of order.claims" :key="claim.id">
                      <td>{{ claim.managedId ? claim.managedId : claim.plentyId }}</td>
                      <td>{{ claim.orderId }}</td>
                      <td>{{ new Date(claim.date).toLocaleDateString() }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>


        </v-col>
      </v-row>


      <v-row v-if="orders">

        <v-col cols="12" v-for="o of orders" :key="o._id">

          <v-card>

            <v-card-title primary-title> {{ o.orderId }} | {{ o.managedId }} </v-card-title>
            <v-card-subtitle>Vom {{ o.createDate | moment("DD.MM.yyyy HH:mm") }} </v-card-subtitle>

            <v-card-text>
              <v-alert type="warning" v-if="o.printDate">Achtung! Das Label wurde bereits gedruckt. Erstelle ein neues Label, um ggf. etwas Nachzusenden. Nicht das Label 2x Verwenden!</v-alert>
              <v-row>
                <v-col v-if="o.production && o.production.laserEnd">
                  <h3>Graviert</h3>
                  <p> Am {{ o.production.laserEnd | moment("DD.MM.yyyy HH:mm") }}</p>
                  <p>Von <strong>{{ o.production.userLaser }}</strong></p>
                </v-col>
                <v-col v-if="o.production && o.production.packEnd">
                  <h3>Gepackt</h3>
                  <p> Am {{ o.production.packEnd | moment("DD.MM.yyyy HH:mm") }}</p>
                  <p>Von <strong>{{ o.production.userPacking }}</strong></p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <h2>Paket Inhalt</h2>
                </v-col>
                <v-col cols="12">

                  <v-data-table :headers="tableHeaders" :items="o.products" :items-per-page="150" class="elevation-1">
                    <template v-slot:item.attributes="{ item }">
                      <ul>
                        <li v-for="t of Object.keys(item.attributes)" :v-key="t"> {{ t }}: <strong>{{ item.attributes[t] }}</strong></li>
                      </ul>
                    </template>


                  </v-data-table>

                </v-col>
              </v-row>
            </v-card-text>

          </v-card>


        </v-col>

      </v-row>
    </v-container>
    <!-- Reklamations Dialog -->
    <v-dialog v-model="reklaDialog" persistent max-width="1000">
      <v-card :loading="loading">
        <v-card-title class="headline">Reklamation erstellen</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field v-model="reklaData.note" label="Was soll reklamiert werden?" required></v-text-field>
            <v-autocomplete v-model="reklaData.reason" :items="reasons" label="Reklamationsgrund" required></v-autocomplete>
            <v-select v-model="reklaData.type" :items="['WIR', 'DPD', 'POST']" label="Schuld" required></v-select>

            <p>Billbee Artikel:</p>
            <v-data-table v-if="order && order.billbeeOrder && order.billbeeOrder.Data.OrderItems" show-select :headers="reklaTableHeaders" :items="order.billbeeOrder.Data.OrderItems" item-key="BillbeeId" v-model="reklaItems">
              <template v-slot:item.Product.Title="{ item }">
                {{ item.Product.Title }}
              </template>
              <template v-slot:item.Attributes="{ item }">
                <ul v-if="item.Attributes">
                  <li v-for="attr in item.Attributes" :key="attr.Id">
                    {{ attr.Name }}: <strong>{{ attr.Value }}</strong>
                  </li>
                </ul>
              </template>
              <template v-slot:item.Quantity="{ item }">
                {{ item.Quantity }}
              </template>
              <template v-slot:item.TotalPrice="{ item }">
                {{ item.TotalPrice }} €
              </template>
            </v-data-table>
            <p>Unsere Artikel:</p>
            <v-data-table v-if="order && order.products" show-select :headers="tableHeaders" :items="order.products" item-key="orderItemId" v-model="reklaData.mongoItems">


              <template v-slot:item.attributes="{ item }">
                <ul v-if="item.attributes">
                  <li v-for="attr in Object.keys(item.attributes)" :key="attr">
                    {{ attr }}: <strong>{{ item.attributes[attr] }}</strong>
                  </li>
                </ul>
              </template>


            </v-data-table>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="reklaDialog = false">
            Abbrechen
          </v-btn>
          <v-btn color="primary" text @click="createRekla()">
            Erzeugen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Warnungs Dialog für alte Amazon Bestellungen -->
    <v-dialog v-model="warningDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Achtung!</v-card-title>
        <v-card-text>
          Diese Amazon-Bestellung ist älter als 40 Tage. Die Kundendaten wurden möglicherweise bereits anonymisiert. 
          Möchten Sie trotzdem fortfahren?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="warningDialog = false">
            Abbrechen
          </v-btn>
          <v-btn 
            color="primary" 
            text 
            @click="() => {
              warningDialog = false;
              reklaData.managedId = order.managedId;
              reklaData.orderId = order.orderId;
              reklaData.reason = '';
              reklaData.note = '';
              reklaData.type = '';
              reklaDialog = true;
            }"
          >
            Fortfahren
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Nach dem bestehenden rekla-Dialog -->
    <v-dialog v-model="bulkReklaDialog" persistent max-width="1000">
      <v-card :loading="processingOrders">
        <v-card-title class="headline">Massen-Reklamationen erstellen</v-card-title>
        <v-card-text>
          <v-form ref="bulkForm">
            <v-textarea
              v-model="bulkOrderIds"
              label="Bestellnummern (eine pro Zeile)"
              required
              rows="5"
            ></v-textarea>
            <v-text-field 
              v-model="bulkReklaData.note" 
              label="Was soll reklamiert werden?" 
              required
            ></v-text-field>
            <v-autocomplete
              v-model="bulkReklaData.reason"
              :items="reasons"
              label="Reklamationsgrund"
              required
            ></v-autocomplete>
            <v-select
              v-model="bulkReklaData.type"
              :items="['WIR', 'DPD', 'POST']"
              label="Schuld"
              required
            ></v-select>

            <v-progress-linear
              v-if="processingOrders"
              :value="(progressValue / progressTotal) * 100"
              color="primary"
              class="mb-4"
            >
              {{ progressValue }} / {{ progressTotal }}
            </v-progress-linear>

            <v-alert
              v-for="result in processedResults"
              :key="result.orderId"
              :type="result.success ? 'success' : 'error'"
              dense
              outlined
            >
              {{ result.orderId }}: {{ result.message }}
            </v-alert>

            <v-textarea
              v-if="failedOrderIds"
              v-model="failedOrderIds"
              label="Fehlgeschlagene Bestellnummern"
              readonly
              rows="3"
              class="mt-4"
              outlined
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-1"
            text
            @click="bulkReklaDialog = false"
            :disabled="processingOrders"
          >
            Abbrechen
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="processBulkReklamationen"
            :disabled="processingOrders || !bulkOrderIds || !bulkReklaData.reason || !bulkReklaData.type"
          >
            Reklamationen erstellen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <LoadingDialog />
    <v-dialog v-model="printerDialog" max-width="500px">
      <v-card>
        <v-card-title>
          Drucker auswählen
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedPrinter"
            :items="printers"
            label="Drucker"
            item-text="name"
            item-value="id"
            required
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="printerDialog = false">
            Abbrechen
          </v-btn>
          <v-btn 
            color="primary" 
            text 
            @click="printShippingLabel"
            :disabled="!selectedPrinter"
          >
            Drucken
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Header from "./header";
import service from "@/services/ProductionService";
import router from "@/router";

import DHLTracking from "@/components/DHLTracking";
import DPDTracking from "@/components/DPDTracking";

import LoadingDialog from "./production/components/LoadingDialog";

import fileDownload from "js-file-download";
import moment from "moment";

import EventBus from "@/event-bus";

import { OrderBuilder } from '@/js/BillbeeOrderBuilder.js';
import { ref } from 'vue';

export default {
  name: "dashboard",
  components: {
    Header,
    DHLTracking,
    DPDTracking,
    LoadingDialog,
  },
  data: () => ({
    timer: null,
    loading: false,
    search: null,
    order: null,
    tracking: null,
    searchCustom: null,
    orders: null,
    reklaDialog: false,
    reklaItems: [],
    reasons: [
      "Beschädigte Ware",
      "Beschädigte Verpackung",
      "Fehlender Bestandteil",
      "Funktioniert Nicht",
      "Falsche Farbe",
      "Falsche Gravur",
      "Falscher Druck",
      "Verpäteter Versand",
      "Verlust der Sendung",
      "Verspaetete Lieferung",
      "Angebliche Zustellung"
    ],
    reklaData: {
      orderId: "",
      managedId: "",
      reason: "",
      note: "",
      type: "",
      mongoItems: []
    },
    tableHeaders: [
      { text: "SKU", value: "sku" },
      { text: "Menge", value: "qty" },
      { text: "Gravur", value: "attributes" },
    ],

    reklaTableHeaders: [
      { text: "Artikel", value: "Product.Title" },
      { text: "Attribute", value: "Attributes" },
      { text: "Menge", value: "Quantity" },
      { text: "Preis", value: "TotalPrice" }
    ],
    warningDialog: false,
    bulkReklaDialog: false,
    bulkOrderIds: '',
    bulkReklaData: {
      reason: '',
      note: '',
      type: ''
    },
    processingOrders: false,
    processedResults: [],
    progressValue: 0,
    progressTotal: 0,
    failedOrderIds: '',
    printerDialog: false,
    selectedPrinter: null,
    printers: [],
    currentShippingId: null,
  }),
  create() { },
  async mounted() {
    this.loadPrinters();
  },
  methods: {
    async doSearch() {
      try {
        EventBus.$emit("LOADING_START");
        this.tracking = null;
        let order = (await service.getMongoOrder(this.search)).data;

        //set orderItemIds
        for (let item of order.products) {
          item.orderItemId = order.products.indexOf(item);
        }

        if (!order.managedId && order.billbeeOrder) {
          order.managedId = order.billbeeOrder.Data.BillBeeOrderId;
        }

        this.order = order;
        EventBus.$emit("LOADING_END");
        if (this.order) {
          this.getTracking();

          //this.loadClaimCreationInformations();

        }
      } catch (err) {
        this.error = "Bestellung konnte nicht gefunden werden!";
        EventBus.$emit("LOADING_END");
        setTimeout(function () {
          this.error = null;
        }, 3500);
      }
    },
    async doSearchCustom() {
      try {
        EventBus.$emit("LOADING_START");
        this.order = null;
        this.orders = [];

        this.orders = (await service.searchCustom(this.searchCustom)).data;

        EventBus.$emit("LOADING_END");
      } catch (err) {
        this.error = "Bestellung konnte nicht gefunden werden!";
        EventBus.$emit("LOADING_END");
        setTimeout(function () {
          this.error = null;
        }, 3500);
      }
    },

    async getTracking() {
      this.loading = true;
      try {
        if (this.order.tracking) {
          this.tracking = (
            await service.getTrackingState(this.order.tracking)
          ).data;
        }
        this.loading = false;
      } catch (err) { }
    },



    loadReklaDialog() {
      if (this.order.billbeeOrder && 
          this.order.billbeeOrder.Data.ApiAccountName.toLowerCase().includes('amazon')) {
        
        const orderDate = new Date(this.order.billbeeOrder.Data.CreatedAt);
        const daysDiff = Math.floor((new Date() - orderDate) / (1000 * 60 * 60 * 24));
        
        if (daysDiff > 40) {
          this.warningDialog = true;
          return;
        }
      }

      this.reklaData.managedId = this.order.managedId;
      this.reklaData.orderId = this.order.orderId;
      this.reklaData.reason = "";
      this.reklaData.note = "";
      this.reklaData.type = "";
      this.reklaDialog = true;
    },


    async createRekla() {
      this.loading = true;
      try {
        // Erstelle neue Billbee Order aus den ausgewählten Items
        const newOrder = this.createBillbeeOrderFromItems();


        console.log(newOrder);

        const response = await service.createRekla({
          originalOrder: this.order.billbeeOrder.Data,
          warrantyOrder: newOrder,
          mongoItems: this.reklaData.mongoItems,
          note: this.reklaData.note,
          reason: this.reklaData.reason,
          type: this.reklaData.type
        });

        this.reklaDialog = false;
        this.reklaItems = [];
        this.reklaData = {
          note: "",
          reason: "",
          type: ""
        };
      } catch (err) {
        console.error(err);
        this.error = "Es konnte keine Reklamation erzeugt werden!";
      } finally {
        this.loading = false;
      }
    },

    createBillbeeOrderFromItems(prefix = "RK-") {
      // Nutze den BillbeeOrderBuilder um eine neue Order zu erstellen
      const builder = new OrderBuilder();

      const originalOrder = this.order.billbeeOrder.Data ? this.order.billbeeOrder.Data : this.order.billbeeOrder;

      return builder
        .setOrderNumber(`${prefix}${originalOrder.OrderNumber}`)
        .setState(0) // Neu
        .setVatMode(0)
        .setCreatedAt(new Date().toISOString())
        .setPaymentMethod(0) // Keine Zahlung
        .setShippingCost(0)
        .setTotalCost(0)
        .setCurrency(originalOrder.Currency)
        .setCustomer(originalOrder.Customer)
        .setInvoiceAddress(originalOrder.InvoiceAddress)
        .setShippingAddress(originalOrder.ShippingAddress)
        .setBillbeeShopId(originalOrder.BillbeeShopId)
        .setApiAccountId(originalOrder.ApiAccountId)
        .setApiAccountName(originalOrder.ApiAccountName)
        // Füge ausgewählte Items hinzu mit 0€ Preis
        .setOrderItems(this.reklaItems.map(item => ({
          ...item,
          TotalPrice: 0,
          UnrebatedTotalPrice: 0
        })))
        // Füge Kommentar hinzu
        .addComment({
          FromCustomer: false,
          Text: `Reklamation zu ${originalOrder.OrderNumber}\nGrund: ${this.reklaData.reason}\nNotiz: ${this.reklaData.note}\nTyp: ${this.reklaData.type}`,
          Created: new Date().toISOString()
        })
        .build();
    },


    async createDHLLabel() {
      this.loading = true;

      let payload = this.dhlData;
      try {
        let response = (await service.createShipment(payload)).data;

        console.log(response);

        let file = atob(response.file);
        const byteNumbers = new Array(file.length);
        for (let i = 0; i < file.length; i++) {
          byteNumbers[i] = file.charCodeAt(i);
        }
        let fileArray = new Uint8Array(byteNumbers);
        let blobfile = new Blob([fileArray], { type: "buffer" });

        console.log(fileArray);
        console.log(blobfile);

        fileDownload(blobfile, "DHLLabel.pdf", "application/pdf");

        this.dhlDialog = false;
      } catch (err) {
        console.error(err);
        this.error = "Es konnte kein Label für diese Adresse erzeugt werden!";
      } finally {
        this.loading = false;
        this.dhlData = {
          orderId: "",
          name: "",
          name2: "",
          street: "",
          streetNumber: "",
          addition: "",
          zip: "",
          city: "",
        };
      }
    },
    async loadDHLDialog(orderId) {
      this.loading = true;

      try {
        let response = (await service.loadOrderData(orderId)).data;
        this.dhlDialog = true;
        this.dhlData.orderId = orderId;
        this.dhlData.name = response.name1;
        this.dhlData.name2 = response.name2 + " " + response.name3;
        this.dhlData.street = response.address1;
        this.dhlData.streetNumber = response.address2;
        this.dhlData.addition = response.address3;
        this.dhlData.zip = response.postalCode;
        this.dhlData.city = response.town;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async processBulkReklamationen() {
      this.processingOrders = true;
      this.processedResults = [];
      this.failedOrderIds = '';
      
      const orderIds = this.bulkOrderIds.split('\n').map(id => id.trim()).filter(id => id);
      this.progressTotal = orderIds.length;
      this.progressValue = 0;
      
      const failedOrders = [];
      
      for (const orderId of orderIds) {
        try {
          // Bestellung laden
          const orderResponse = await service.getMongoOrder(orderId);
          const order = orderResponse.data;

          if (!order) {
            failedOrders.push(orderId);
            this.processedResults.push({
              orderId,
              success: false,
              message: 'Bestellung nicht gefunden'
            });
            continue;
          }


          const originalOrder = order.billbeeOrder.Data ? order.billbeeOrder.Data : order.billbeeOrder;

          // Reklamation erstellen
          const newOrder = new OrderBuilder()
            .setOrderNumber(`RKCUT-${originalOrder.OrderNumber}`)
            .setState(0)
            .setVatMode(0)
            .setCreatedAt(new Date().toISOString())
            .setPaymentMethod(0)
            .setShippingCost(0)
            .setTotalCost(0)
            .setCurrency(originalOrder.Currency)
            .setCustomer(originalOrder.Customer)
            .setInvoiceAddress(originalOrder.InvoiceAddress)
            .setShippingAddress(originalOrder.ShippingAddress)
            .setBillbeeShopId(originalOrder.BillbeeShopId)
            .setApiAccountId(originalOrder.ApiAccountId)
            .setApiAccountName(originalOrder.ApiAccountName)
            // Filtere den Holzsockel aus den OrderItems
            .setOrderItems(originalOrder.OrderItems
              .filter(item => item.Product.SKU !== 'A10146')
              .map(item => ({
                ...item,
                TotalPrice: 0,
                UnrebatedTotalPrice: 0
              })))
            .addComment({
              FromCustomer: false,
              Text: `Reklamation zu ${originalOrder.OrderNumber}\nGrund: ${this.bulkReklaData.reason}\nNotiz: ${this.bulkReklaData.note}\nTyp: ${this.bulkReklaData.type}`,
              Created: new Date().toISOString()
            })
            .build();

          // Filtere auch die mongoItems
          const filteredMongoItems = order.products.filter(item => item.sku !== 'A10146');

          await service.createRekla({
            originalOrder: originalOrder,
            warrantyOrder: newOrder,
            mongoItems: filteredMongoItems,
            note: this.bulkReklaData.note,
            reason: this.bulkReklaData.reason,
            type: this.bulkReklaData.type
          });

          this.processedResults.push({
            orderId,
            success: true,
            message: 'Reklamation erfolgreich erstellt'
          });

        } catch (error) {
          failedOrders.push(orderId);
          console.error(`Fehler bei Bestellung ${orderId}:`, error);
          this.processedResults.push({
            orderId,
            success: false,
            message: error.message || 'Unbekannter Fehler'
          });
        } finally {
          this.progressValue++;
        }
      }
      
      this.failedOrderIds = failedOrders.join('\n');
      this.processingOrders = false;
    },
    async loadPrinters() {
      try {
        const response = await service.getPrinters();
        this.printers = response.data;
      } catch (error) {
        console.error('Fehler beim Laden der Drucker:', error);
      }
    },
    async openPrinterDialog(shippingId) {
      this.currentShippingId = shippingId;
      await this.loadPrinters();
      this.printerDialog = true;
    },
    async printShippingLabel() {
      if (!this.selectedPrinter || !this.currentShippingId) return;
      
      try {
        await service.printShippingLabel({
          shippingId: this.currentShippingId,
          printer: this.selectedPrinter
        });
        this.printerDialog = false;
        this.$toast.success('Label wurde gedruckt');
      } catch (error) {
        console.error('Fehler beim Drucken:', error);
        this.$toast.error('Fehler beim Drucken des Labels');
      }
    }
  },
  breforeDestroy() { },
  computed: {
    isOldAmazonOrder() {
      if (this.order?.billbeeOrder?.Data) {
        const isAmazon = this.order.billbeeOrder.Data.ApiAccountName.toLowerCase().includes('amazon');
        const orderDate = new Date(this.order.billbeeOrder.Data.CreatedAt);
        const daysDiff = Math.floor((new Date() - orderDate) / (1000 * 60 * 60 * 24));
        return isAmazon && daysDiff > 40;
      }
      return false;
    }
  },
};
</script>

<style scoped>
.v-card,
.v-btn {
  margin-bottom: 10px;
}
</style>