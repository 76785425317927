<template>
  <div>
    <Header />
    
    <!-- Error/Success Alerts -->
    <v-alert v-model="alerts.error" type="error" dismissible>
      {{ alerts.error }}
    </v-alert>
    <v-alert v-model="alerts.success" type="success" dismissible>
      {{ alerts.success }}
    </v-alert>

    <v-container>
      <!-- Header -->
      <v-row>
        <v-col cols="12">
          <h1 class="text-center">Muckelmeister Importer</h1>
        </v-col>
      </v-row>

      <!-- System Status -->
      <v-row v-if="health">
        <v-col cols="12" md="4">
          <v-card>
            <v-card-title>System Status</v-card-title>
            <v-card-text>
              <v-chip :color="health.status === 'ready' ? 'success' : 'error'" label>
                {{ health.status }}
              </v-chip>
              <div class="mt-2">
                <div>Verarbeitete Bestellungen: {{ health.metrics?.ordersProcessed || 0 }}</div>
                <div>Fehler: {{ health.metrics?.errors || 0 }}</div>
                <div>Durchschnittliche Verarbeitungszeit: {{ formatTime(health.metrics?.avgProcessingTime) }}</div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        
        <v-col cols="12" md="8">
          <v-card>
            <v-card-title>Verbindungsstatus</v-card-title>
            <v-card-text>
              <v-row>
                <v-col v-for="(status, service) in health.connections" :key="service">
                  <v-chip :color="status ? 'success' : 'error'" label>
                    {{ service }}: {{ status ? 'Verbunden' : 'Getrennt' }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Main Content -->
      <v-row>
        <v-col cols="12">
          <!-- Search and Filters -->
          <v-card class="mb-4">
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="filters.search"
                    append-icon="mdi-magnify"
                    label="Suche"
                    single-line
                    hide-details
                  />
                </v-col>
              </v-row>
              
              <v-row>
                <v-col cols="12" md="4">
                  <v-checkbox
                    v-model="filters.showImported"
                    label="Nur importierte anzeigen"
                  />
                </v-col>
                <v-col cols="12" md="8">
                  <v-autocomplete
                    v-model="filters.selectedSkus"
                    :items="availableSkus"
                    label="Filter SKU"
                    multiple
                    chips
                    clearable
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- Bulk Actions -->
          <v-card class="mb-4" v-if="selectedOrders.length">
            <v-card-text>
              <v-row align="center">
                <v-col>
                  <div class="text-body-1">
                    {{ selectedOrders.length }} Bestellung(en) ausgewählt
                  </div>
                  <v-progress-linear
                    :value="(selectedOrders.filter(x=>x.imported).length / selectedOrders.length) * 100"
                    color="primary"
                    class="mt-2"
                  />
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    @click="importSelectedOrders"
                    :loading="importing"
                    :disabled="!canImport"
                    color="primary"
                  >
                    Importieren
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- Orders Table -->
          <v-data-table
            :headers="tableHeaders"
            :items="filteredOrders"
            :items-per-page="50"
            :loading="loading"
            :search="filters.search"
            item-key="orderId"
            show-select
            v-model="selectedOrders"
            class="elevation-1"
          >
            <!-- Custom Column Slots -->
            <template v-slot:item.imported="{ item }">
              <v-chip :color="item.imported ? 'success' : 'error'" small label>
                {{ item.imported ? 'Importiert' : 'Nicht importiert' }}
              </v-chip>
            </template>

            <template v-slot:item.products="{ item }">
              <v-chip
                v-for="product in item.products"
                :key="product.sku"
                class="mr-1"
                small
                label
              >
                {{ product.qty }}x {{ product.sku }}
              </v-chip>
            </template>

            <template v-slot:item.createDate="{ item }">
              {{ formatDate(item.createDate) }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn
                icon
                small
                @click="importSingleOrder(item)"
                :disabled="item.imported || importing"
                :loading="importingOrderId === item.orderId"
              >
                <v-icon>mdi-import</v-icon>
              </v-btn>
              <v-btn
                icon
                small
                @click="showOrderDetails(item)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <!-- Loading Dialog -->
    <v-dialog v-model="loading" persistent max-width="300">
      <v-card>
        <v-card-title class="headline">Einen Moment bitte</v-card-title>
        <v-card-text class="text-center">
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Order Details Dialog -->
    <v-dialog v-model="dialogs.orderDetails" max-width="600">
      <v-card v-if="selectedOrder">
        <v-card-title>
          Bestelldetails #{{ selectedOrder.orderId }}
          <v-spacer />
          <v-btn icon @click="dialogs.orderDetails = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Bestelldatum</td>
                  <td>{{ formatDate(selectedOrder.createDate) }}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{{ selectedOrder.state }}</td>
                </tr>
                <tr>
                  <td>Kunde</td>
                  <td>
                    {{ selectedOrder.customer.firstName }} 
                    {{ selectedOrder.customer.lastName }}<br>
                    {{ selectedOrder.customer.adress }}<br>
                    {{ selectedOrder.customer.zip }} {{ selectedOrder.customer.city }}<br>
                    {{ selectedOrder.customer.country }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Header from "../header";
import ImporterService from "../../../services/ImporterService";
import { format } from 'date-fns';
import { de } from 'date-fns/locale';

export default {
  name: "MuckelmeisterImporter",
  
  components: {
    Header
  },

  data: () => ({
    loading: false,
    importing: false,
    importingOrderId: null,
    orders: [],
    selectedOrders: [],
    selectedOrder: null,
    health: null,
    
    alerts: {
      error: null,
      success: null
    },

    filters: {
      search: '',
      showImported: false,
      selectedSkus: []
    },

    dialogs: {
      orderDetails: false
    },

    tableHeaders: [
      { text: "Bestellnummer", value: "orderId" },
      { text: "Bestelldatum", value: "createDate" },
      { text: "Status", value: "state" },
      { text: "Produkte", value: "products" },
      { text: "Importiert", value: "imported" },
      { text: "Aktionen", value: "actions", sortable: false }
    ]
  }),

  computed: {
    filteredOrders() {
      let filtered = this.orders;

      if (this.filters.showImported) {
        filtered = filtered.filter(order => order.imported);
      }

      if (this.filters.selectedSkus.length) {
        filtered = filtered.filter(order => 
          order.products.some(product => 
            this.filters.selectedSkus.includes(product.sku)
          )
        );
      }

      return filtered;
    },

    availableSkus() {
      const skus = new Set();
      this.orders.forEach(order => {
        order.products.forEach(product => {
          skus.add(product.sku);
        });
      });
      return Array.from(skus).sort();
    },

    canImport() {
      return this.selectedOrders.length > 0 && 
             !this.importing &&
             this.selectedOrders.some(order => !order.imported);
    }
  },

  async mounted() {
    await this.initialize();
    this.startHealthCheck();
  },

  beforeDestroy() {
    if (this.healthCheckInterval) {
      clearInterval(this.healthCheckInterval);
    }
  },

  methods: {
    async initialize() {
      this.loading = true;
      try {
        await Promise.all([
          this.loadOrders(),
          this.checkHealth()
        ]);
      } catch (error) {
        this.showError("Fehler beim Laden der Daten: " + error.message);
      } finally {
        this.loading = false;
      }
    },

    startHealthCheck() {
      this.healthCheckInterval = setInterval(() => {
        this.checkHealth();
      }, 30000); // Alle 30 Sekunden
    },

    async loadOrders() {
      const response = await ImporterService.getOpenOrders();
      this.orders = response.data;
    },

    async checkHealth() {
      try {
        const response = await ImporterService.getHealth();
        this.health = response.data;
      } catch (error) {
        console.error("Health check failed:", error);
      }
    },

    async importSelectedOrders() {
      this.importing = true;
      const ordersToImport = this.selectedOrders.filter(order => !order.imported);
      
      for (const order of ordersToImport) {
        try {
          this.importingOrderId = order.orderId;
          await this.importOrder(order);
        } catch (error) {
          this.showError(`Fehler beim Import von Bestellung ${order.orderId}: ${error.message}`);
          break;
        }
      }
      
      this.importing = false;
      this.importingOrderId = null;
      await this.loadOrders();
    },

    async importSingleOrder(order) {
      this.importingOrderId = order.orderId;
      try {
        await this.importOrder(order);
        this.showSuccess(`Bestellung ${order.orderId} erfolgreich importiert`);
      } catch (error) {
        this.showError(`Fehler beim Import: ${error.message}`);
      }
      this.importingOrderId = null;
      await this.loadOrders();
    },

    async importOrder(order) {
      const response = await ImporterService.importOrder(order.orderId);
      if (!response.data.success) {
        throw new Error(response.data.message || 'Import fehlgeschlagen');
      }
    },

    showOrderDetails(order) {
      this.selectedOrder = order;
      this.dialogs.orderDetails = true;
    },

    formatDate(date) {
      return format(new Date(date), 'dd.MM.yyyy HH:mm', { locale: de });
    },

    formatTime(ms) {
      if (!ms) return '0ms';
      return `${Math.round(ms)}ms`;
    },

    showError(message) {
      this.alerts.error = message;
      setTimeout(() => {
        this.alerts.error = null;
      }, 5000);
    },

    showSuccess(message) {
      this.alerts.success = message;
      setTimeout(() => {
        this.alerts.success = null;
      }, 3000);
    }
  }
};
</script>

<style scoped>
.v-data-table ::v-deep .v-data-table__wrapper {
  overflow-x: auto;
}

.v-chip {
  margin: 2px;
}
</style>