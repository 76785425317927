// billbeeClient.js
export class OrderBuilder {
    constructor() {
      this.orderData = {
        ShippingIds: [],
        Comments: [],
        OrderItems: [],
        Tags: [],
        ShippingServices: [],
        History: [],
        Payments: [],
        Customer: {},
        Buyer: {},
        Seller: {},
        InvoiceAddress: {},
        ShippingAddress: {},
        BillbeeShopId: null,
      };
    }
  
    setOrderNumber(orderNumber) {
      this.orderData.OrderNumber = orderNumber;
      return this;
    }
  
    setState(state) {
      this.orderData.State = state;
      return this;
    }
  
    setVatMode(vatMode) {
      this.orderData.VatMode = vatMode;
      return this;
    }
  
    setCreatedAt(date) {
      this.orderData.CreatedAt = date;
      return this;
    }
  
    setPaymentMethod(method) {
      this.orderData.PaymentMethod = method;
      return this;
    }
  
    setShippingCost(cost) {
      this.orderData.ShippingCost = cost;
      return this;
    }
  
    setTotalCost(cost) {
      this.orderData.TotalCost = cost;
      return this;
    }
  
    setCurrency(currency) {
      this.orderData.Currency = currency;
      return this;
    }
  
    setCustomer(customer) {
      this.orderData.Customer = customer;
      return this;
    }
  
    setInvoiceAddress(address) {
      this.orderData.InvoiceAddress = address;
      return this;
    }
  
    setShippingAddress(address) {
      this.orderData.ShippingAddress = address;
      return this;
    }
  
    setBillbeeShopId(shopId) {
      this.orderData.BillbeeShopId = shopId;
      return this;
    }
  
    setApiAccountId(id) {
      this.orderData.ApiAccountId = id;
      return this;
    }
  
    setApiAccountName(name) {
      this.orderData.ApiAccountName = name;
      return this;
    }
  
    setOrderItems(items) {
      this.orderData.OrderItems = items;
      return this;
    }
  
    addComment(comment) {
      this.orderData.Comments.push(comment);
      return this;
    }
  
    build() {
      // Validierungen
      if (!this.orderData.OrderNumber) {
        throw new Error('OrderNumber ist erforderlich.');
      }
      if (!this.orderData.Customer) {
        throw new Error('Customer ist erforderlich.');
      }
      if (!this.orderData.ShippingAddress) {
        throw new Error('ShippingAddress ist erforderlich.');
      }
      if (!this.orderData.InvoiceAddress) {
        throw new Error('InvoiceAddress ist erforderlich.');
      }
  
      return this.orderData;
    }
  }