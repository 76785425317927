<template>
  <v-timeline
      dense
      clipped
      v-if="tracking"
    >
     

      <v-timeline-item
        class="mb-4"
        color="grey"
        icon-color="grey lighten-2"
        small
        v-for="event of tracking.events.filter(x => x.statusHasBeenReached)" :key="event.date"
      >
        <v-row justify="space-between">
          <v-col cols="4">
            <v-chip v-if="event.label == 'Delivered'" color="success">{{event.label}}</v-chip>
            <v-chip v-else>{{event.label}}</v-chip>
            <br>
            {{event.location}}
          </v-col>
          <v-col cols="6">
            {{event.description.content[0]}}
          </v-col>
          <v-col
            class="text-right"
            cols="2"
          >
            {{event.date}}
          </v-col>
        </v-row>
      </v-timeline-item>

    </v-timeline>
</template>

<script>

export default {
  name: "DHLTracking",
  components: {},
  props: ["tracking"],
  data: () => ({
  }),
  methods: {
   
  },
  created() {}
};
</script>

<style>
</style>