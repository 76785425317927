
<template>
  <div>
    <Header />
    <v-container style="max-width: 100%">
      <!-- OrderID Search Bar -->

      <v-row>
        <v-col lg="6" md="12" sm="12">
          <v-card :loading="loading">
            <v-card-title primary-title> Zeiterfassung </v-card-title>
            <v-card-text>
              <div class="timer">{{ timer }}</div>

              <v-alert
                v-if="error"
                type="error"
                icon="alert"></v-alert>

              <v-btn
                style="width: 100%"
                @click="trigger"
                large
                class="primary"
                >{{ state }}</v-btn
              >
            </v-card-text>
          </v-card>
          <v-card v-if="timeTrackingData && user && user.role == 'minijob'">
            <v-card-title primary-title> Aktueller Monat </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <v-card>
                    <v-card-title primary-title>
                      <div>
                        <h3 class="kpiHeadline">Überstunden</h3>
                      </div>
                    </v-card-title>
                    <v-card-text>
                      <div class="kpi">
                        {{ timeTrackingData.overpaceHours }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card>
                    <v-card-title primary-title>
                      <div>
                        <h3 class="kpiHeadline">Stundenkonto</h3>
                      </div>
                    </v-card-title>
                    <v-card-text>
                      <div class="kpi">{{ timeTrackingData.currentHours }}</div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card>
                    <v-card-title primary-title>
                      <div>
                        <h3 class="kpiHeadline">Guthaben</h3>
                      </div>
                    </v-card-title>
                    <v-card-text>
                      <div class="kpi">{{ timeTrackingData.credit }} €</div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card v-if="timeTrackingData.overpaceHours && timeTrackingData.overpaceHours instanceof Array == true">
            <v-card-title primary-title>
                      <div>
                        <h3 class="kpiHeadline">Überstundenkonto (BETA)</h3>
                      </div>
                    </v-card-title>
                    <v-card-text>
                      <div class="kpi">
                        {{ calculateOverpaceHours(timeTrackingData.overpaceHours) }}
                      </div>
                      <div style="text-align: center">
                        <p>Pausenregelung: Pro Arbeitstag über 6 Stunden wird die<br>gesetzliche Pausenzeit von 30 Minuten abgezogen.<br>
                        Ab 9 Stunden Arbeitszeit werden 45 Minuten abgezogen.<br>Das Überstundenkonto berücksichtigt diese Regelung.</p>
                      </div>
                    </v-card-text>
          </v-card>
        </v-col>

        <v-col lg="6" md="12" sm="12">
          <v-card :loading="loading">
            <v-card-title primary-title> Historie</v-card-title>
            <v-card-text>
              <v-data-table
                v-if="timeTrackingData && timeTrackingData.history"
                :headers="workingTimeHeader"
                :items="timeTrackingData.history"
                :items-per-page="50"
                class="elevation-1"
              >
                <template v-slot:item.date="{ item }">
                  {{ item.startTime | moment("DD.MM.yyyy") }}
                </template>
                <template v-slot:item.startTime="{ item }">
                  {{ item.startTime | moment("HH:mm:ss") }}
                </template>
                <template  v-slot:item.endTime="{ item }">
                  <div v-if="item.endTime">{{ item.endTime | moment("HH:mm:ss") }}</div>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
          <v-col cols="12" v-if="showCalendar">
              <kalendar :configuration="calendar_settings" :events.sync="events" />
            </v-col>  
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Header from "./header";
import service from "@/services/TimeTrackingService";
import AuthService from "@/services/AuthService";
import moment from "moment";
import EventBus from "@/event-bus";

import { Kalendar } from 'kalendar-vue';



export default {
  name: "timetracking",
  components: {
    Header,
    Kalendar
  },
  data: () => ({
    timer: null,
    loading: false,
    state: "Start",
    timer: "00:00:00",
    timeTrackingData: null,
    intervaltimer: null,
    user: null,
    error: null,
    showCalendar: false,
    calendar_settings: {
      style: 'material_design',
      view_type: 'week',
      cell_height: 10,
      scrollToNow: false,
      start_day: new Date().toISOString(),
      read_only: true,
      day_starts_at: 0,
      day_ends_at: 24,
      overlap: true,
      past_event_creation: true
    },
    events: [
    ],
    workingTimeHeader: [
      {
        text: "Datum",
        align: "start",
        sortable: true,
        value: "date",
      },
      { text: "von", value: "startTime" },
      { text: "bis", value: "endTime" },
      { text: "Stunden", value: "hours" },
    ],
  }),
  create() { },
  async mounted() {
    this.intervaltimer = setInterval(this.updateTimer, 1000);
    this.reload();
  },
  methods: {
    updateTimer() {
      if (this.timeTrackingData && this.timeTrackingData.startTime) {
        let startTime = this.timeTrackingData.startTime.startTime;
        let now = new Date();
        let ms = moment(now).diff(startTime);
        let d = moment.duration(ms);
        this.timer =
          Math.floor(d.asHours()).toString().length == 1
            ? "0" + Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss")
            : Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");

        console.log(startTime);
      } else {
        this.timer = "00:00:00";
      }
    },
    async reload() {
      this.loading = true;
      let data = (await service.get()).data;

      this.user = (await AuthService.getUser()).data;

      this.loading = false;
      this.showCalendar = false;
      this.events = [];

      for (let h of data.history) {
        if (h.startTime && h.endTime) {
          let start = moment(h.startTime)
          let end = moment(h.endTime)
          let diff = end.diff(start)
          h.hours = moment.utc(diff).format("HH:mm:ss")

          //need iso format string
          this.events.push({
            data: "Arbeitszeit",
            from: new Date(h.startTime).toISOString(),
            to: new Date(h.endTime).toISOString()
          })
        } else {

          this.events.push({
            data: "Aktuelle Arbeitszeit",
            from: new Date(h.startTime).toISOString(),
            to: new Date().toISOString()
          })

          h.hours = "-"
        }
      }

      console.log(this.events);

      data.currentHours = Math.round(data.currentHours * 100) / 100;
      data.credit = Math.round(data.credit * 100) / 100;

      this.showCalendar = true;

      this.timeTrackingData = data;

      if (this.timeTrackingData.startTime != null) {
        this.state = "Stop";
      } else {
        this.state = "Start";
      }

      EventBus.$emit("timetracking");
    },

    async trigger() {

      let response = await service.tiggerTracking();

      console.log(response);
      //check if 401
      if (response.response && response.response.status == 401) {
        this.error = "Sie sind nicht im Firmen-Netzwerk angemeldet. Bitte verbinden Sie sich mit dem Accelery GmbH WLAN."
      } else {
        this.error = null;
      }


      this.reload();
    },

    calculateOverpaceHours() {

      let overpace = 0; //Seconds
      for (let day of this.timeTrackingData.overpaceHours) {
        overpace += day.overtimeOrMinus;
      } 

      console.log("overpace", overpace);

      const hours = Math.floor(overpace / 3600); // Convert seconds to hours
      const minutes = Math.floor((overpace % 3600) / 60); // Convert remaining seconds to minutes

      return (overpace < 0 ? "-" : "") + `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
      
    }
  },
  breforeDestroy() {
    clearInterval(this.intervaltimer);
  },
};
</script>

<style scoped>
.timer {
  width: 100%;
  font-size: 4em;
  font-weight: bold;
  text-align: center;
  margin: 50px 0px;
}

.v-card,
.v-btn {
  margin-bottom: 10px;
  color: black;
}

.v-card__title {
  width: 100%;
  text-align: center;
  display: block !important;
}

.kpiHeadline,
.kpi {
  color: black;
  font-size: 0.8em;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
}

.kpi {
  font-size: 2em !important;
}
</style>