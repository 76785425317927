import Vue from 'vue'
import VueCookies from 'vue-cookies'
import vuetify from './plugins/vuetify';

import App from './App'
import router from './router'


import VueClipboard from 'vue-clipboard2'

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

import PortalVue from "portal-vue";
Vue.use(PortalVue);



const moment = require('moment')
require('moment/locale/de');


Vue.use(require('vue-moment'), {
  moment
})

Vue.config.devtools = true
Vue.config.productionTip = false

Vue.use(VueCookies)
VueCookies.config('7d');

Vue.use(VueClipboard)


export const serverBus = new Vue();

import AuthService from './services/AuthService';

Vue.prototype.$authService = AuthService;

new Vue({
  router,
  vuetify,
  template: '<App/>',
  components: { App }
}).$mount('#app')

