
<template>
  <div>
    <Header />
    <v-alert :value="error" type="error">{{ error }}</v-alert>
    <v-container>
      <h2>Packlisten</h2>
      <v-row dense="true">
        <v-text-field v-model="search" label="Suche" outlined dense></v-text-field>
      </v-row>
      <v-row dense="true">
        <v-col v-for="i in filterItems()" :key="i.name" cols="auto">
          <v-card
            style="text-align:center; padding: 0px; width: 250px; height: 283px; display: inline-block; margin: 10px;"
            @click="open(i.name)">
            <v-img :src="i.image ? i.image : 'https://files.accelery.de/packages.png'" width="100%" contain
              height="100px"></v-img>

            <span
              style="font-size: 14px; font-weight: bold; text-align: center; display:block; margin-top: 10px">{{i.name}}</span>
            <span
              style="font-size: 18px; font-weight: bold; text-align: center; display:block; margin: 10px">{{i.items}}</span>
              <span style="font-size: 10px; font-weight: normal; text-align: center; display:block;">Letze Gravur</span>
            <span style="font-size: 11px; font-weight: bold; text-align: center; display:block;">{{
            getTimeSinceCreated(i.updated) }}</span>
            <v-row style="border-top: 1px solid #ccc;">
              <v-col :cols="i.laserUser ?'6':'12'" style="border-right: 1px solid #ccc;" v-if="i.packUser">
                <span style="font-size: 10px; font-weight: normal; text-align: center; display:block;">Wird gepackt von</span>
                <v-chip style="font-size: 10px" color="green" text-color="white" label>
                  {{i.packUser}}
                </v-chip>

              </v-col>
              <v-col :cols="i.packUser ?'6':'12'" v-if="i.laserUser">
                <span style="font-size: 10px; font-weight: normal; text-align: center; display:block;">Graviert von</span>
                <v-chip style="font-size: 10px" color="purple" text-color="white" label>
                  {{i.laserUser}}
                </v-chip>

              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Header from "../header";
import moment from "moment";
import PacklistService from "@/services/PacklistService";
import router from "@/router";
import AuthService from "@/services/AuthService";
export default {
  name: "production",
  components: {
    Header,
  },
  data: () => ({
    loading: true,
    items: [],
    show: false,
    error: null,
    interval: null,
    search: "",
  }),
  create() { },
  async mounted() {

    try {
      this.user = (await AuthService.getUser()).data;
      if(this.user.role == "support") {
        return router.replace("/support");
      }
    } catch (err) {
      console.error(err);
      if (err.response.status == 401) {
        router.replace("/auth/login");
      }
    }

    this.refresh();
    this.interval = setInterval(() => this.refresh(), 1000);
  },
  async beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    async refresh() {
      this.loading = true;
      this.itemNames = null;
      try {
        this.items = (await PacklistService.getPacklists()).data;
        this.loading = false;
      } catch (err) {
        console.error(err);
        if (err.response.status == 401) {
          router.replace("/auth/login");
        }
      }
    },

    filterItems() {
      if(this.items.length == 0) return [];
      return this.items.filter((i) => {
        return i.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },

    getTimeSinceCreated(date) {
      return moment(date).fromNow();
    },

    getDate(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },

    open(id) {
      clearInterval(this.interval);
      router.push({
        path: "/packlistV3/" + id,
      });
    },
  },
};
</script>

<style scoped>
img {
  max-width: 400px !important;
}

.v-card__title,
.v-card__subtitle {
  padding: 6px !important;
  font-size: 0.8rem !important;
}
</style>
