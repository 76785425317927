<template>
  <div>
    <Header />

    <v-container grid-list-xs style="max-width: 100%">
      <v-layout row wrap justify-center fill-height>
        <v-flex sm12 md3>
          <v-card light height="100%">
            <v-card-text>
              <div><img src="@/assets/amazon.png" height="20px" /></div>
              <div class="font-weight-medium display-1">
                {{ openOrders.filter((x) => x.shop == "amazon").length }}
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex sm12 md3>
          <v-card light height="100%">
            <v-card-text>
              <div><img src="@/assets/shopify.png" height="20px" /></div>
              <div class="font-weight-medium display-1">
                {{ openOrders.filter((x) => x.shop == "shopify").length }}
              </div>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex sm12 md3>
          <v-card light height="100%">
            <v-card-text>
              <div><img src="@/assets/etsy.png" height="20px" /></div>
              <div class="font-weight-medium display-1">
                {{ openOrders.filter((x) => x.shop == "etsy").length }}
              </div>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex sm12 md3>
          <v-card light height="100%">
            <v-card-text>
              <div><img src="@/assets/acs.png" height="20px" /></div>
              <div class="font-weight-medium display-1">
                {{ openOrders.filter((x) => x.shop == "acs").length }}
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>

      <v-layout row wrap justify-center fill-height>
        <v-flex sm12 md3>
          <v-card dark height="100%">
            <v-card-text>
              <div>Anzahl offener Bestellungen (Off-Prime)</div>
              <div class="font-weight-medium display-2">
                {{
                  openOrders.filter((x) => x.shipping.type != "PRIME").length
                }}
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex sm12 md3>
          <v-card dark height="100%">
            <v-card-text>
              <div>Offene Bestellungen (Prime)</div>
              <div class="font-weight-medium display-2">
                {{
                    openOrders.filter((x) => x.shipping.type == "PRIME").length
                  }}
              </div>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex sm12 md3>
          <v-card color="red" dark height="100%">
            <v-card-text>
              <div>Bestellungen die heute raus müssen</div>
              <div class="font-weight-medium display-2">
                {{ packagesToDay.length }}
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex sm12 md3>
          <v-card dark color="blue" height="100%">
            <v-card-text>
              <div>Bestellungen die heute raus müssen (Prime)</div>
              <div class="font-weight-medium display-2">
                {{
                  packagesToDay.filter((x) => x.shipping.type == "PRIME").length
                }}
              </div>
              <div v-if="packagesToDay.filter((x) => x.shipping.type == 'EXPRESS').length > 0">EXPRESS: {{ packagesToDay.filter((x) => x.shipping.type == "EXPRESS").length }}</div>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex sm12 md3>
          <v-card>
            <v-card-text>
              <OrderList
              :packlist="packlist"
              :orders="nonPrimeOrders"
              ></OrderList>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex sm12 md3>
          <v-card>
            <v-card-text>
              <OrderList
              :packlist="packlist"  
              :orders="primeOrders"
              ></OrderList>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex sm12 md3>
          <v-card>
            <v-card-text>
              <OrderList
                v-if="packagesToDay.length > 0"
                :packlist="packlist"
                :orders="packagesToDay"
              ></OrderList>
              <h2 v-else>Keine Bestellungen</h2>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex sm12 md3>
          <v-card>
            <v-card-text>
              <OrderList
                v-if="packagesToDay.length > 0"
                :packlist="packlist"
                :orders="primeTodayOrders"
              ></OrderList>
              <h2 v-else>Keine Bestellungen</h2>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import Header from "./header";
import OrderList from "./OrderList";
import dashboardService from "@/services/DashboardService";
import router from "@/router";
export default {
  name: "dashboard",
  components: {
    Header,
    OrderList,
  },
  data: () => ({
    timer: null,
    loading: true,
    openOrders: [],
    packlist: [],
    shippedOrders: [],
  }),
  create() { },
  async mounted() {
    this.refresh();
    this.timer = setInterval(this.refresh, 1000 * 15); //five minues refreshments
  },
  methods: {
    async refresh() {
      this.loading = true;
      try {
        let dashboardData = ((await dashboardService.get()).data);
        this.packlist = dashboardData.packlist;
        let data = dashboardData.openOrders.filter(
          (x) => x.shipping != null
        );
        this.openOrders = data.sort((a, b) =>
          new Date(a.createDate).getTime() > new Date(b.createDate).getTime()
            ? -1
            : 1
        );

        this.loading = false;
      } catch (err) {
        console.error(err);
        if (err.response.status == 401) {
          router.replace("/auth/login");
        }
      }
    },
  },
  computed: {
    packagesToDay: function () {
      if (!this.openOrders.length) return [];
      
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      
      return this.openOrders.filter(o => {
        const date = o.shipping.lastShippmentDate || o.shipping.shippmentDate;
        const shipDate = new Date(date);
        shipDate.setHours(0, 0, 0, 0);
        return shipDate.getTime() <= now.getTime();
      });
    },
    
    primeOrders() {
      return this.openOrders.filter(x => x.shipping.type === 'PRIME');
    },
    
    nonPrimeOrders() {
      return this.openOrders.filter(x => x.shipping.type !== 'PRIME');
    },
    
    primeTodayOrders() {
      return this.packagesToDay.filter(x => x.shipping.type === 'PRIME');
    }
  },
  breforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
