
<template>
  <div>
    <Header />
    <v-container>
      <v-layout row wrap justify-center fill-height>
        <v-flex md12 mb-2 v-if="skus.length > -1">
          <v-card>
            <v-card-title>SKU Verteilung</v-card-title>
            <v-card-text>
              <div style="block" v-for="sku in skus" :key="sku">
                <h3>{{ sku.name }}: {{ sku.count }}</h3>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex md12>
          <v-card class="elevation-5">
            <v-card-text primary-title>
              <h1 class="display-1">
                <v-icon @click="$router.go(-1)" large class="mr-2"
                  >arrow_back</v-icon
                >Bestellungen
              </h1>

              <v-btn class="secondary" small @click="packlist.dialog = true"
                >Packliste anlegen</v-btn
              >

              <v-btn class="secondary" small @click="shipping.dialog = true"
                >Versandvorbereitung</v-btn
              >

              <v-data-table
                hide-default-footer
                :must-sort="true"
                :loading="loading"
                :items-per-page="-1"
                :sort-by="['sku', 'font']"
                :sort-desc="[true, true]"
                multi-sort
                :headers="tableHeaders"
                :items="items"
              >
                <template v-slot:body="{ items, headers }">
                  <tbody>
                    <tr
                      v-for="(item, idx) in items"
                      :key="idx"
                      :class="itemClass(item)"
                    >
                      <td v-for="(header, key) in headers" :key="key">
                        <v-edit-dialog
                          :return-value.sync="item[header.value]"
                          v-if="editBlacklist.indexOf(header.value) == -1"
                          @save="save(item)"
                          @cancel="cancel"
                          @open="open"
                          @close="close"
                          large
                        >
                          {{ item[header.value] }}
                          <template v-slot:input>
                            <v-text-field
                              v-model="item[header.value]"
                              label="Bearbeiten"
                              single-line
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>

                        <div v-else-if="header.value == 'id'">
                          {{ items.indexOf(item) }}
                        </div>

                        <div v-else-if="header.value == 'reproduction'">
                          <v-btn
                            v-if="item.reproduction == true"
                            primary
                            @click="loadDHLDialog(item.orderId)"
                            >Versenden</v-btn
                          >
                        </div>

                        <div v-else-if="header.value == 'reusable'">
                          <v-btn
                            v-if="item.reusable"
                            icon
                            @click="loadReueseDialog(item.reusable)"
                          >
                            Verwenden <v-icon>check</v-icon></v-btn
                          >
                        </div>

                        <div v-else>
                          {{ item[header.value] }}
                        </div>
                      </td>
                      <td>
                        <v-icon small @click="askDeleteItem(item)">
                          delete
                        </v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-row>
      <v-dialog v-model="dhlDialog" persistent max-width="500">
        <v-card :loading="loading">
          <v-card-title class="headline"> DHL Label erzeugen </v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-text-field v-model="dhlData.name1" label="Name1" />
              <v-text-field v-model="dhlData.name2" label="Name2" />
              <v-text-field v-model="dhlData.street" label="Strasse" />
              <v-text-field v-model="dhlData.streetNumber" label="Hausnummer" />
              <v-text-field
                v-model="dhlData.addition"
                label="Zusätzliche Infos"
              />
              <v-text-field v-model="dhlData.zip" label="PLZ" />
              <v-text-field v-model="dhlData.city" label="Stadt" />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="green darken-1" text @click="markAsShipped()">
              Ohne Label versenden
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dhlDialog = false">
              Abbrechen
            </v-btn>
            <v-btn color="green darken-1" text @click="createDHLLabel()">
              Erzeugen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="success" dark>
        <v-card-text>
          Daten werden geladen
          <v-progress-linear
            v-model="percent"
            :indeterminate="percent == null"
            :query="true"
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteItemDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Eintrag löschen? </v-card-title>
        <v-card-text>Möchtest du den Eintrag wirklich löschen?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="
              deleteItem = null;
              deleteItemDialog = false;
            "
          >
            Abbrechen
          </v-btn>
          <v-btn color="green darken-1" text @click="executeDeleteItem()">
            Ja, Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="shipping.success" max-width="400">
      <v-card color="success" dark>
        <v-card-title class="headline"
          >Versandvorbereitung erfolgreich!</v-card-title
        >

        <v-card-text
          >Deine Bestellungen wurde in den Versandstatus übertragen</v-card-text
        >

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="shipping.success = false">Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="packlist.dialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Packliste erstellen?</v-card-title>

        <v-card-text v-if="packlist.dialog">
          Möchtest du wirkliche eine Packliste für folgende Artikel erstellen?
          <br />
          <h1>
            <strong>{{ packlist.name }}</strong>
          </h1>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="success" text @click="packlist.dialog = false"
            >Abbrechen</v-btn
          >

          <v-btn
            color="success"
            text
            @click="
              packlist.dialog = false;
              createPacklist(packlist.name);
              packlist.name = null;
            "
            >Anlegen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="shipping.dialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Versandvorbereitung?</v-card-title>

        <v-card-text v-if="shipping.dialog">
          Möchtest du wirkliche alle Bestellungen in dieser Tabelle in den
          Versandvorbereitungsstatus übertragen?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="success" text @click="shipping.dialog = false"
            >Abbrechen</v-btn
          >

          <v-btn
            color="success"
            text
            @click="
              shipping.dialog = false;
              createShipping();
            "
            >Anlegen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Header from "../header";
import service from "@/services/ProductionService";
import router from "@/router";

import fileDownload from "js-file-download";

export default {
  name: "productionItem",
  components: {
    Header,
  },
  data: () => ({
    loading: true,
    percent: 0,
    itemNames: [],
    editBlacklist: [
      "id",
      "orderId",
      "reproduction",
      "reusable",
      "sku",
      "delete",
    ],
    items: [],
    packlist: {
      dialog: false,
      success: false,
      name: "",
    },
    deleteItemDialog: false,
    deleteItem: null,
    tableHeaders: [],
    dhlDialog: false,
    dhlData: {
      orderId: "",
      name: "",
      name2: "",
      street: "",
      streetNumber: "",
      addition: "",
      zip: "",
      city: "",
    },
    skus: [],
    shipping: {
      dialog: false,
      success: false,
      name: "",
    },
  }),
  create() {},
  async mounted() {
    this.refresh();
  },
  methods: {
    itemClass(item) {
      if (item.reproduction) {
        return "reproduction";
      } else if (item.reusable) {
        return "reusable";
      }
    },
    async loadDHLDialog(orderId) {
      this.loading = true;

      try {
        let response = (await service.loadOrderData(orderId)).data;
        this.dhlDialog = true;
        this.dhlData.orderId = orderId;
        this.dhlData.name = response.name1;
        this.dhlData.name2 = response.name2 + " " + response.name3;
        this.dhlData.street = response.address1;
        this.dhlData.streetNumber = response.address2;
        this.dhlData.addition = response.address3;
        this.dhlData.zip = response.postalCode;
        this.dhlData.city = response.town;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async createDHLLabel() {
      this.loading = true;

      let payload = this.dhlData;
      try {
        let response = (await service.createShipment(payload)).data;

        let file = atob(response.file);
        const byteNumbers = new Array(file.length);
        for (let i = 0; i < file.length; i++) {
          byteNumbers[i] = file.charCodeAt(i);
        }
        let fileArray = new Uint8Array(byteNumbers);
        let blobfile = new Blob([fileArray], { type: "buffer" });

        fileDownload(blobfile, "DHLLabel.pdf", "application/pdf");

        await this.markAsShipped(payload.orderId, response.tracking);

        this.dhlDialog = false;
      } catch (err) {
        this.error = "Es konnte kein Label für diese Adresse erzeugt werden!";
      } finally {
        this.loading = false;
        this.dhlData = {
          orderId: "",
          name: "",
          name2: "",
          street: "",
          streetNumber: "",
          addition: "",
          zip: "",
          city: "",
        };
      }
    },

    async markAsShipped(orderId) {
      this.dhlDialog = false;
      this.loading = true;
      await service.markAsShipped(this.dhlData.orderId, "victorinox");
      this.refresh();
    },
    async save(item) {
      console.log(item);
      this.loading = true;
      try {
        await service.updateEntry("victorinox", item);
      } catch (e) {
        alert(
          "Der Server kann den Vorgang nicht abschließen. Bitte versuche es erneut."
        );
      } finally {
        await this.refresh();
      }
    },

    askDeleteItem(item) {
      this.deleteItem = item;
      this.deleteItemDialog = true;
    },

    async executeDeleteItem() {
      this.loading = true;
      try {
        this.deleteItemDialog = false;
        await service.deleteEntry("victorinox", this.deleteItem.id);
      } catch (e) {
        alert("Der Eintrag konnte nicht gelöscht werden.");
      } finally {
        await this.refresh();
      }
    },

    async createShipping() {
      try {
        this.loading = true;
        let data = (await service.createShipping("victorinox")).data;
        this.shipping.success = true;
      } catch (e) {
        this.error = e.message;
      } finally {
        this.loading = false;
      }
    },

     async createPacklist(name) {
      try {
        let data = (await service.createPacklist(name)).data;
        this.packlist.success = true;
      } catch (e) {
        this.error = e.message;
      }
    },

    async refresh() {
      this.loading = true;
      this.itemNames = null;
      this.items = [];
      try {

        this.packlist.name = "victorinox";
        
        let data = (await service.getItem("victorinox")).data;
        this.itemNames = Object.keys(data[0]);

        this.tableHeaders = [];
        for (let key of this.itemNames) {
          this.tableHeaders.push({
            text: key,
            value: key,
            align: "left",
            sortable: false,
          });
        }
        this.items = data;

        for (let item of this.items) {
          let sku = item.sku;
          if (!sku) continue;

          let isAdded = false;
          for (let s of this.skus) {
            if (s.name == sku) {
              isAdded = true;
              s.count++;
            }
          }

          if (!isAdded) this.skus.push({ name: sku, count: 1 });
        }

        this.loading = false;
      } catch (err) {
        console.log(err);
        if (err.response.status == 401) {
          router.replace("/auth/login");
        } else {
          console.log(err);
        }
      }
    },
  },
  breforeDestroy() {},
};
</script>

<style>
.reproduction {
  background-color: cyan;
}
.reusable {
  background-color: magenta;
  color: white;
}
</style>
